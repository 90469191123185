const my = {
  salone_four_d: "စလုံး 4D",

  profile: "Profile",
  avatar: "Avatar",
  buy_ticket: "Buy Ticket",

  error: "Error",
  success: "Success",

  login_title: "အကောင့်ဝင်ရန်",
  login_btn: "၀င်မည်",
  forgot_password: "လျှို့ဝှက်နံပါတ်မေ့သွားသည်",
  click_here: "ဒီမှာနှိပ်ပါ",
  create_account_btn: "အကောင့်ဖွင့်ရန်",

  download_app: "Download App",

  register_title: "အကောင့်ဖွင့်ရန်",
  full_name_placeholder: "အမည်",
  phone_number_placeholder: "ဖုန်းနံပါတ်",
  password_placeholder: "လျှို့ဝှက်နံပါတ်",
  password_confirmation_placeholder: "အတည်ပြု လျှို့ဝှက်နံပါတ်",
  referral_code_placeholder: "ရည်ညွှန်းကုဒ် (မရှိလျှင်ချန်ခဲ့ပါ)",
  terms_and_conditions_btn: "စည်းကမ်းနှင့်သတ်မှတ်ချက်များ",
  register_btn: "ဖွင့်မည်",

  home_tab_label: "Home",
  results_tab_label: "Results",
  buy_tab_label: "Buy",
  notifications_tab_label: "Noti",
  cash_tab_label: "Cash",

  //dashboard
  this_week_winner: "ယခုအပတ် အနိုင်ရသူများ",
  view_all_winner: "အနိုင်ရသူအားလုံး",
  first_prize: "ပထမဆု",
  second_prize: "ဒုတိယဆု",
  third_prize: "တတိယဆု",
  view_all_result: "ဆုအားလုံးကြည့်ရန်",
  next_lottery_time: "နောက်တစ်ကြိမ်ထွက်ရန်",
  cash_in: "ငွေအဝင်",
  cash_out: "ငွေအထွက်",
  top_up: "ငွေသွင်း",
  withdraw: "ငွေထုတ်",
  ks: "ကျပ်",
  available_balance: "လက်ကျန်ငွေ",

  welcome_back: "ပြန်လည်ကြိုဆိုပါတယ်",
  change_language: "ဘာသာစကားပြောင်းရန်",
  change_password: "လျှို့ဝှက်နံပါတ်ပြောင်းရန်",
  your_lucky_draw_history: "ဝယ်ယူခဲ့သော လက်မှတ်များ",
  your_balance_history: "ငွေသွင်း၊ ငွေထုတ်စာရင်း",
  contact_us: "ကျွန်ုပ်တို့ကို ဆက်သွယ်ရန်",
  about_us: "ကျွန်ုပ်တို့အကြောင်း",
  referral_code: "သင့်ရည်ညွှန်းကုဒ်",
  logout: "ထွက်ရန်",

  english: "အင်္ဂလိပ်",
  burmese: "ဗမာ",
  cancel: "ပယ်ဖျက်မည်",
  next: "ရှေ့သို့",
  back: "နောက်သို့",
  bet_add: "ထည့်မည်",
  bet_add_more_number: "နံပါတ်ထည့်မည်",
  choose_four_numbers: "ဂဏန်း ၄ လုံးရွေးပါ",
  selected_numbers: "ထိုးသော နံပါတ်(များ)",
  buy: "ဝယ်မည်",
  clear_all: "အားလုံးကိုဖယ်ရှားမည်",
  total_cost: "စုစုပေါင်းကုန်ကျစရိတ်",
  close: "ပိတ်မည်",
  confirm: "အတည်ပြုမည်",
  bet_confirm_title: "အတည်ပြုချက်",
  bet_confirm_message:
    "ဤနံပါတ်ကို သင်ဝယ်လိုပါသလား၊ သင်သည် ထိုနံပါတ်များကို အမှန်တကယ် အတည်ပြုပါသလား?",
  bet_success_all_done: "အားလုံးပြီးပြီ!",
  bet_success_check_order_history: "အော်ဒါကို စစ်ဆေးပါ",

  viber_dialog_title: "Viber ဖွင့်လို့မရပါ!",
  viber_dialog_description: "ကျေးဇူးပြု၍ %{phone_number} ကို ဆက်သွယ်ပါ။",

  minimum_top_up_amount_help_text: "အနည်းဆုံး ၁၀၀၀ မှစလွဲပေးပါ",
  top_up_form_button: "ဖြည့်မည်",
  top_up_success_message: "ငွေသွင်းစစ်ဆေးပေးနေပါသည်\n(ငွေစစ်ချိန် မနက် ၉ မှ ည ၉ ထိ)",

  withdraw_title: "ငွေထုတ်",
  withdraw_amount_placeholder: "ထုတ်မည့် ငွေပမာဏထည့်သွင်းပါ",
  withdraw_account_name_placeholder: "လက်ခံမည့် ဖုန်းနံပတ် ထည့်သွင်းပါ",
  withdraw_payment_info_placeholder: "လက်ခံမည့် Kpay/wave အမည်ထည့်သွင်းပါ",
  withdraw_form_button: "ထုတ်မည်",
  withdraw_success_message: "ငွေထုတ်စစ်ဆေးပေးနေပါသည်\n(ငွေထုတ်ချိန် မနက်၉ မှ ည၉ ထိ)",

  top_up_title: "ငွေသွင်း",
  top_up_amount_placeholder: "ငွေပမာဏ ထည့်သွင်းပါ",
  top_up_phone_number_placeholder: "ငွေလွဲလိုက်သည့် ဖုန်းနံပတ် ထည့်သွင်းပါ",
  select_screenshot_btn: "ငွေလွဲမှတ်တမ်းပြေစာပုံထည့်ပါ",

  terms_and_conditions_page_title: "စည်းကမ်းနှင့်သတ်မှတ်ချက်များ",
  accept_btn: "လက်ခံမည်",

  change_password_form_button: "ပြောင်းမည်",
  change_password_success_message:
    "လျှို့ဝှက်နံပါတ်ကို အောင်မြင်စွာ ပြောင်းလဲပြီးပါပြီ။",
  x100: "အဆ ၁၀၀",
  x30: "အဆ ၃၀",
  top_up_amount_required: "ငွေပမာဏ ထည့်သွင်းပါ",
  top_up_amount_invalid: "ငွေပမာဏ ထည့်သွင်းပါ",
  top_up_phone_number_required: "ငွေလွဲလိုက်သည့် ဖုန်းနံပတ် ထည့်သွင်းပါ",
  top_up_screenshot_key_required: "ငွေလွဲမှတ်တမ်းပြေစာပုံထည့်ပါ",
  withdraw_amount_required: "ထုတ်မည့် ငွေပမာဏထည့်သွင်းပါ",
  withdraw_amount_invalid: "ထုတ်မည့် ငွေပမာဏထည့်သွင်းပါ",
  withdraw_account_name_required: "လက်ခံမည့် ဖုန်းနံပတ် ထည့်သွင်းပါ",
  withdraw_payment_info_required: "လက်ခံမည့် Kpay/wave အမည်ထည့်သွင်းပါ",

  no_result: "ထီပေါက်စဉ်မထွက်ရှိသေးပါ",
};

export default my;
