import moment from "moment";

export function formatMoney(amount: number) {
  // const locale = i18n.locale == "my" ? "my-MM" : "en-US";
  const locale = "en-US";
  const moneyFormatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return moneyFormatter.format(amount);
}

export function formatDate(date: string) {
  return moment(date).format("DD MMM (ddd)");
}

export function formatDateTime(date: string) {
  return moment(date).format("MMMM D, h:mm A");
}
