import { accentColor, linearColorPurple } from "@/constants/ColorConstants";
import {
  selectMaxAmountPerBet,
  selectMinAmountPerBet,
  setCurrentBetAmount,
} from "@/store/bet";
import { RootState } from "@/store/store";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

export default function BetAmountInput() {
  const dispatch = useDispatch();
  const currentBetAmount = useSelector(
    (state: RootState) => state.bet.currentBetAmount
  );
  const stepAmount = useSelector(
    (state: RootState) =>
      state.bet.setting?.bet_limitation.step_bet_amount || 50
  );
  const minAmount = useSelector(selectMinAmountPerBet);
  const maxAmount = useSelector(selectMaxAmountPerBet);

  function onAmountChange(text: string) {
    const newAmount = parseInt(text);

    if (isNaN(newAmount)) {
      dispatch(setCurrentBetAmount(""));
      return;
    }
    if (newAmount <= maxAmount) {
      dispatch(setCurrentBetAmount(newAmount.toString()));
    }
  }

  function onAmountBlur(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    const newAmount = parseInt(currentBetAmount);

    if (isNaN(newAmount) || newAmount < minAmount) {
      dispatch(setCurrentBetAmount(minAmount.toString()));
      return;
    }
  }

  const onButtonPress = (number: number) => {
    const newAmount = parseInt(currentBetAmount) + number * stepAmount;

    if (newAmount >= minAmount && newAmount <= maxAmount) {
      dispatch(setCurrentBetAmount(newAmount.toString()));
    }
  };

  return (
    <View style={[styles.itemContainer]}>
      <View
        style={[
          styles.itemInputContainer,
          // isTextAmountError && styles.errorBorder,
        ]}
      >
        <TouchableOpacity
          onPress={() => onButtonPress(-1)}
          style={styles.iconButton}
        >
          <Ionicons name="remove-outline" size={24} color={linearColorPurple} />
        </TouchableOpacity>
        <TextInput
          style={styles.input}
          value={currentBetAmount.toString()}
          onChangeText={onAmountChange}
          onBlur={onAmountBlur}
          keyboardType="numeric"
        />
        <TouchableOpacity
          onPress={() => onButtonPress(1)}
          style={styles.iconButton}
        >
          <Ionicons name="add-outline" size={24} color={linearColorPurple} />
        </TouchableOpacity>
      </View>
      {/* {isTextAmountError && <Text style={styles.errorText}>Unavailable amount</Text>} */}
    </View>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
    // borderWidth: 2,
    borderRadius: 8,
    marginVertical: 3,
    flexDirection: "column",
  },
  itemInputContainer: {
    // flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    backgroundColor: accentColor,
    borderRadius: 8,
    marginVertical: 3,
    flexDirection: "row",
  },
  errorBorder: {
    borderColor: "red",
  },
  input: {
    minWidth: 80,
    flexGrow: 1,
    paddingVertical: 12,
    fontSize: 16,
    color: "white",
    backgroundColor: "transparent",
    marginRight: 8,
    textAlign: "center",
  },
  iconButton: {
    paddingHorizontal: 21,
    paddingVertical: 15,
  },
});
