import { setNetworkStatus } from "@/store/network";
import NetInfo from "@react-native-community/netinfo";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function useNetworkListener() {
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      dispatch(setNetworkStatus(state.isConnected));
    });

    return () => {
      unsubscribe();
    };
  }, []);
}
