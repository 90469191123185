import * as React from "react";
import Svg, {
  Path,
  Circle,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";
const TimeBgWithProgress = (props : any) => (
  <Svg
    width={352}
    height={99}
    viewBox="0 0 352 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M2 83C2 87.4183 5.58172 91 10 91H344C348.418 91 352 87.4183 352 83V10C352 5.58172 348.418 2 344 2H10C5.58172 2 2 5.58172 2 10V83Z"
      fill="url(#paint0_linear_768_1582)"
    />
    <Path
      d="M99.2103 91H10C5.58172 91 2 87.4183 2 83V10C2 5.58172 5.58172 2 10 2H173"
      stroke="#E54780"
      strokeWidth={3}
    />
    <Circle
      cx={103}
      cy={91}
      r={7}
      fill="#E54780"
      stroke="#0E0815"
      strokeWidth={2}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_768_1582"
        x1={177}
        y1={2}
        x2={177}
        y2={91}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#423A4D" />
        <Stop offset={1} stopColor="#423A4D" stopOpacity={0} />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default TimeBgWithProgress;
