import * as React from "react";
import Svg, { Path } from "react-native-svg";
const CallLogo = (props:any) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M18.1668 14.3802C17.4951 14.2357 16.9637 14.5475 16.4932 14.8199C16.0114 15.1006 15.0951 15.8438 14.57 15.6536C11.8811 14.5465 9.35213 12.193 8.25739 9.49335C8.06444 8.95695 8.80424 8.03489 9.08283 7.54735C9.35316 7.0754 9.65858 6.53901 9.51928 5.86227C9.3934 5.25415 7.76522 3.18236 7.18947 2.61582C6.80977 2.24159 6.42078 2.03576 6.02147 2.0025C4.5202 1.93805 2.84352 3.94122 2.54945 4.42044C1.81274 5.4423 1.81687 6.80201 2.56183 8.4507C4.35717 12.8791 11.1475 19.5622 15.5925 21.4251C16.4128 21.8087 17.1629 22.001 17.8366 22.001C18.496 22.001 19.0831 21.817 19.5876 21.4521C19.9684 21.2328 22.0536 19.4728 21.9989 17.9312C21.9659 17.5383 21.7606 17.1453 21.3912 16.7649C20.8289 16.1838 18.7704 14.507 18.1668 14.3802Z"
      fill="#8852EB"
    />
  </Svg>
);
export default CallLogo;
