import PrimaryGradient from "@/components/PrimaryGradient";
import ThemeText from "@/components/ThemeText";
import { activeGameBorder } from "@/constants/ColorConstants";
import { clearBetData, selectGameById, setSelectedGame } from "@/store/bet";
import { RootState } from "@/store/store";
import moment from "moment";
import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";

export default function ActiveGameListItem({ gameId }: { gameId: number }) {
  const dispatch = useDispatch();
  const game = useSelector((state: RootState) => selectGameById(state, gameId));

  if (!game) return <></>;

  const formattedDate = moment(game.result_date).format("DD MMM (ddd)");

  if (game.selected) {
    return (
      <PrimaryGradient style={styles.itemSelectedActiveGame}>
        <Text style={styles.itemActiveGameText}>{formattedDate}</Text>
      </PrimaryGradient>
    );
  }
  return (
    <TouchableOpacity
      onPress={() => {
        dispatch(setSelectedGame(gameId));
        dispatch(clearBetData());
      }}
      style={[
        styles.itemActiveGame,
        // selectedActiveGameItemIndex === index && styles.itemActiveGame,
      ]}
    >
      <ThemeText weight="medium" style={styles.itemActiveGameText}>
        {formattedDate}
      </ThemeText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  itemSelectedActiveGame: {
    borderRadius: 6,
    paddingHorizontal: 8,
    height: 32,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  itemActiveGameText: {
    textAlign: "center",
    color: "white",
    fontSize: 11,
  },
  itemActiveGame: {
    borderWidth: 1,
    borderColor: activeGameBorder,
    borderRadius: 6,
    paddingHorizontal: 8,
    height: 32,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
});
