import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ThirdWinnerStar = (props : any) => (
  <Svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M15.2681 23.0736C14.4855 22.613 13.5146 22.613 12.732 23.0736L8.89192 25.3337C7.78132 25.9874 6.41787 25.0288 6.65716 23.7625L7.57175 18.9227C7.72752 18.0984 7.45948 17.2509 6.85805 16.6661L3.33443 13.2399C2.43236 12.3628 2.94601 10.8317 4.19455 10.676L8.78109 10.1042C9.65515 9.99524 10.4074 9.43435 10.7612 8.62773L12.6264 4.37593C13.1513 3.17938 14.8488 3.17939 15.3737 4.37594L17.2388 8.62771C17.5927 9.43434 18.3449 9.99524 19.219 10.1042L23.8055 10.676C25.054 10.8317 25.5677 12.3628 24.6656 13.2399L21.142 16.6661C20.5406 17.2509 20.2726 18.0984 20.4284 18.9227L21.3429 23.7625C21.5822 25.0288 20.2188 25.9874 19.1082 25.3337L15.2681 23.0736Z"
      fill="#E54780"
      stroke="white"
    />
    <Path
      d="M13.862 19.1C13.382 19.1 12.922 19.03 12.482 18.89C12.0486 18.7433 11.6886 18.5467 11.402 18.3L11.822 17.32C12.422 17.7867 13.0886 18.02 13.822 18.02C14.722 18.02 15.172 17.6667 15.172 16.96C15.172 16.6267 15.0586 16.38 14.832 16.22C14.612 16.0533 14.2886 15.97 13.862 15.97H12.732V14.91H13.742C14.1153 14.91 14.412 14.82 14.632 14.64C14.852 14.4533 14.962 14.1967 14.962 13.87C14.962 13.5633 14.8586 13.33 14.652 13.17C14.452 13.01 14.1586 12.93 13.772 12.93C13.0786 12.93 12.4686 13.17 11.942 13.65L11.522 12.69C11.802 12.4233 12.152 12.2167 12.572 12.07C12.992 11.9233 13.4286 11.85 13.882 11.85C14.6153 11.85 15.1853 12.0167 15.592 12.35C15.9986 12.6767 16.202 13.1333 16.202 13.72C16.202 14.1 16.1053 14.4333 15.912 14.72C15.7186 15.0067 15.4553 15.2133 15.122 15.34C15.5286 15.4533 15.8453 15.6633 16.072 15.97C16.2986 16.27 16.412 16.6367 16.412 17.07C16.412 17.6967 16.1853 18.1933 15.732 18.56C15.2786 18.92 14.6553 19.1 13.862 19.1Z"
      fill="white"
    />
  </Svg>
);
export default ThirdWinnerStar;
