import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SecondWinnerStar = (props: any) => (
  <Svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M12.732 23.0736L8.89192 25.3337C7.78132 25.9874 6.41787 25.0288 6.65716 23.7625L7.57175 18.9227C7.72752 18.0984 7.45948 17.2509 6.85805 16.6661L3.33443 13.2399C2.43236 12.3628 2.94601 10.8317 4.19455 10.676L8.78109 10.1042C9.65515 9.99524 10.4074 9.43435 10.7612 8.62773L12.6264 4.37593C13.1513 3.17938 14.8488 3.17939 15.3737 4.37594L17.2388 8.62771C17.5927 9.43434 18.3449 9.99524 19.219 10.1042L23.8055 10.676C25.054 10.8317 25.5677 12.3628 24.6656 13.2399L21.142 16.6661C20.5406 17.2509 20.2726 18.0984 20.4284 18.9227L21.3429 23.7625C21.5822 25.0288 20.2188 25.9874 19.1082 25.3337L15.2681 23.0736C14.4855 22.613 13.5146 22.613 12.732 23.0736Z"
      fill="#E54780"
      stroke="white"
    />
    <Path
      d="M11.802 19V18.05L14.082 15.62C14.3886 15.2867 14.6086 14.9933 14.742 14.74C14.8753 14.48 14.942 14.2167 14.942 13.95C14.942 13.27 14.532 12.93 13.712 12.93C13.072 12.93 12.482 13.17 11.942 13.65L11.512 12.69C11.792 12.4367 12.142 12.2333 12.562 12.08C12.982 11.9267 13.4186 11.85 13.872 11.85C14.6253 11.85 15.202 12.02 15.602 12.36C16.0086 12.7 16.212 13.1867 16.212 13.82C16.212 14.2467 16.1086 14.6567 15.902 15.05C15.702 15.4433 15.3853 15.8667 14.952 16.32L13.402 17.94H16.482V19H11.802Z"
      fill="white"
    />
  </Svg>
);
export default SecondWinnerStar;
