import { View, Text } from "react-native";
import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

export default function PasswordIcon({
  color = "#E54780",
  ...props
}: Omit<SvgProps, "fill">) {
  return (
    <Svg width={24} height={24} {...props} viewBox="0 0 24 24" fill={color}>
      <Path
        fillRule="evenodd"
        d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z"
        clipRule="evenodd"
      />
    </Svg>
  );
}
