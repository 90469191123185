import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const WinnerHeaderSvg = (props:any) => (
  <Svg
    width={317}
    height={48}
    viewBox="0 0 317 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M317 48L274 5L277.772 48H317Z"
      fill="url(#paint0_linear_465_921)"
    />
    <Path d="M0 48L43 5L39.2281 48H0Z" fill="url(#paint1_linear_465_921)" />
    <Path
      d="M42.3072 7.16207C43.5479 3.19824 47.2199 0.5 51.3734 0.5H265.627C269.78 0.5 273.452 3.19824 274.693 7.16207L287.32 47.5H29.6804L42.3072 7.16207Z"
      fill="#0E0815"
      stroke="url(#paint2_linear_465_921)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_465_921"
        x1={283.706}
        y1={-19.1875}
        x2={304.849}
        y2={-16.1042}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_465_921"
        x1={33.2943}
        y1={-19.1875}
        x2={12.1515}
        y2={-16.1042}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_465_921"
        x1={84.7309}
        y1={-27}
        x2={165.713}
        y2={39.1724}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default WinnerHeaderSvg;
