import { I18n, TranslateOptions } from "i18n-js";
import en from "./en";
import my from "./my";

const translations = {
  en,
  my,
};
const i18n = new I18n(translations);

i18n.locale = "my";

i18n.enableFallback = true;

type TranslationScope = keyof typeof my & keyof typeof en;

export function t(scope: TranslationScope, options?: TranslateOptions) {
  return i18n.t(scope, options);
}

export default i18n;
