import { linearColorPurple } from "@/constants/ColorConstants";
import { BUY_SAVE_SCREEN } from "@/routes/Routes";
import {
  addBets,
  appendCurrentInputNumber,
  selectCanInput,
  selectCanInputR,
  selectCanInputS,
  selectCurrentBetAmount,
  selectCurrentInputNumber,
  setCurrentInputNumber,
} from "@/store/bet";
import { RootState } from "@/store/store";
import {
  generateRandomFourDNumbers,
  generateRoundFromNumber,
} from "@/utils/number";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { memo } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import uuid from "react-native-uuid";
import { useDispatch, useSelector } from "react-redux";

const BaseNumberPad = memo(
  ({
    children,
    disabled,
    onPress,
  }: {
    children: string | JSX.Element;
    disabled: boolean;
    onPress: () => void;
  }) => {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={[styles.item, disabled && styles.backspaceItemDisabled]}
          disabled={disabled}
          onPress={() => onPress()}
        >
          {typeof children === "string" ? (
            <Text
              style={[
                styles.itemText,
                disabled
                  ? styles.specialItemTextDisabled
                  : styles.specialItemText,
              ]}
            >
              {children}
            </Text>
          ) : (
            children
          )}
        </TouchableOpacity>
      </View>
    );
  }
);

export function NumberPad({ number }: { number: string }) {
  const dispatch = useDispatch();
  const canInput = useSelector(selectCanInput);
  return (
    <BaseNumberPad
      children={number}
      disabled={!canInput}
      onPress={() => {
        dispatch(appendCurrentInputNumber(number));
      }}
    />
  );
}

export function SNumberPad() {
  const dispatch = useDispatch();
  const canInput = useSelector(selectCanInputS);
  return (
    <BaseNumberPad
      children={"S"}
      disabled={!canInput}
      onPress={() => dispatch(appendCurrentInputNumber("S"))}
    />
  );
}

export function RNumberPad() {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const currentInputNumber = useSelector(selectCurrentInputNumber);
  const currentBetAmount = useSelector(selectCurrentBetAmount);
  const canInput = useSelector(selectCanInputR);

  function onPress() {
    const uniqueKey = uuid.v4().toString();
    dispatch(
      addBets(
        generateRoundFromNumber(currentInputNumber).map((number) => ({
          bet_number: number,
          bet_amount: parseInt(currentBetAmount),
          input_number: currentInputNumber + "R",
          unique_key: uniqueKey,
        }))
      )
    );
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(BUY_SAVE_SCREEN);
  }

  return (
    <BaseNumberPad children={"R"} disabled={!canInput} onPress={onPress} />
  );
}

export function QNumberPad() {
  const dispatch = useDispatch();
  return (
    <BaseNumberPad
      children={"Q"}
      disabled={false}
      onPress={() =>
        dispatch(setCurrentInputNumber(generateRandomFourDNumbers()))
      }
    />
  );
}

export function BackNumberPad() {
  const dispatch = useDispatch();

  const inputNumber = useSelector(
    (state: RootState) => state.bet.currentInputNumber
  );
  const disabled = inputNumber.length === 0;

  return (
    <BaseNumberPad
      disabled={disabled}
      onPress={() => {
        dispatch(setCurrentInputNumber(inputNumber.slice(0, -1) || ""));
      }}
    >
      <Ionicons
        name={"arrow-back"}
        size={24}
        color={disabled ? "grey" : "white"}
      />
    </BaseNumberPad>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "20%",
  },
  item: {
    flex: 1,
    paddingHorizontal: 18,
    paddingVertical: 14,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderColor: linearColorPurple,
    borderRadius: 8,
    marginHorizontal: 3,
    marginVertical: 3,
  },
  backspaceItemDisabled: {
    borderColor: "#ffffff33",
    backgroundColor: "#ffffff33",
  },
  itemText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
  specialItemText: {
    color: "white",
  },
  specialItemTextDisabled: {
    color: "grey",
  },
});
