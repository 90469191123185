export const appPrimaryBackgroundColor = "#FFFFFF";
export const primaryColor = "#242424";

export const tabIconSelectColor = "white";
export const tabIconUnselectColor = "rgba(255, 255, 255, 0.6)";
export const winnerCardBackgroundColor = "#F9F9F9";
export const winnerCardBorderColor = "#DFDFDF";
export const winnerImageContainerColor = "#E4E4E4";
export const viewAllBtnBorderColor = "#C3C3C3";
export const winNumberContainerColor = "#E0E0E0";
export const winNumberPrizeContainerColor = "#E0E0E0";
export const numberBgPurple = "#51206B";
export const numberBorderPurple = "#613396";
export const numberBgRed = "#6B2020";
export const numberBorderRed = "#963333";
export const numberBgGreen = "#206b20";
export const numberBorderGreen = "#339633";

export const buttonDisableBorder = "#4F4A56";

export const linearColorPurple = "#8352EB";
export const linearColorPink = "#C352EB";
export const viberFloatingBgPink = "#E54780";
export const primaryBlackColor = "#0E0815";
export const primaryWhiteColor = "#FFFFFF";
export const accentColor = "#282031";
export const activeGameBorder = "#423A4D";
export const purpleText = "#8E59FF";
export const dateText = "#9F9CA1";

export const iconColor = "#89858E";
