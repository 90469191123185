import * as React from "react";
import Svg, { Path, G } from "react-native-svg";
const BuyTicketSvg = (props:any) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5996 8.171C13.1856 8.171 12.8496 7.835 12.8496 7.421V5C12.8496 4.586 13.1856 4.25 13.5996 4.25C14.0136 4.25 14.3496 4.586 14.3496 5V7.421C14.3496 7.835 14.0136 8.171 13.5996 8.171Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5996 21.2837C13.1856 21.2837 12.8496 20.9477 12.8496 20.5337V18.5107C12.8496 18.0957 13.1856 17.7607 13.5996 17.7607C14.0136 17.7607 14.3496 18.0957 14.3496 18.5107V20.5337C14.3496 20.9477 14.0136 21.2837 13.5996 21.2837Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5996 15.8249C13.1856 15.8249 12.8496 15.4889 12.8496 15.0749V10.2539C12.8496 9.83991 13.1856 9.50391 13.5996 9.50391C14.0136 9.50391 14.3496 9.83991 14.3496 10.2539V15.0749C14.3496 15.4889 14.0136 15.8249 13.5996 15.8249Z"
      fill="white"
    />
    <G mask="url(#mask0_455_582)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 15.554V17.493C2.5 18.875 3.643 20 5.048 20H18.452C19.857 20 21 18.875 21 17.493V15.554C19.749 15.225 18.823 14.093 18.823 12.751C18.823 11.408 19.748 10.277 21 9.948L20.999 8.007C20.999 6.625 19.856 5.5 18.451 5.5H5.049C3.644 5.5 2.501 6.625 2.501 8.007L2.5 10.025C3.767 10.336 4.677 11.422 4.677 12.751C4.677 14.093 3.751 15.225 2.5 15.554ZM18.452 21.5H5.048C2.816 21.5 1 19.702 1 17.493V14.901C1 14.487 1.336 14.151 1.75 14.151C2.537 14.151 3.177 13.523 3.177 12.751C3.177 12.001 2.563 11.435 1.75 11.435C1.551 11.435 1.36 11.356 1.22 11.215C1.079 11.075 1 10.883 1 10.685L1.001 8.007C1.001 5.798 2.817 4 5.049 4H18.451C20.683 4 22.499 5.798 22.499 8.007L22.5 10.601C22.5 10.799 22.421 10.991 22.28 11.131C22.14 11.272 21.949 11.351 21.75 11.351C20.963 11.351 20.323 11.979 20.323 12.751C20.323 13.523 20.963 14.151 21.75 14.151C22.164 14.151 22.5 14.487 22.5 14.901V17.493C22.5 19.702 20.684 21.5 18.452 21.5Z"
        fill="white"
      />
    </G>
  </Svg>
);
export default BuyTicketSvg;