import { accentColor, iconColor } from "@/constants/ColorConstants";
import { PROFILE } from "@/routes/Routes";
import { selectProfileImage } from "@/store/auth/auth_reducer";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

export default function AppHeaderProfileImage({
  navigation,
}: {
  navigation: any;
}) {
  const profileImage = useSelector(selectProfileImage);

  return (
    <TouchableOpacity
      style={styles.headerContainer}
      onPress={() => {
        navigation.navigate(PROFILE);
      }}
    >
      <View style={styles.userIconContainer}>
        <View style={styles.userIconBackground}>
          {profileImage ? (
            <Image source={{ uri: profileImage }} style={styles.avatarImage} />
          ) : (
            <Ionicons name="person" style={styles.userIcon} />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    height: 75,
  },
  userIconContainer: {
    flex: 1,
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  userIconBackground: {
    backgroundColor: accentColor,
    borderRadius: 25,
    width: 36,
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  userIcon: {
    fontSize: 20,
    color: iconColor,
  },
  avatarImage: {
    width: 30,
    height: 30,
    borderRadius: 100,
  },
});
