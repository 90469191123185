import ThemeText from "@/components/ThemeText";
import ConfirmProcessSvg from "@/components/svgs/buyflow/ConfirmProcessSvg";
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

const BuyConfirmProcessingScreen: React.FC = () => {
  return (
    <View style={styles.container}>
      <ConfirmProcessSvg />
      <View style={styles.textContainer}>
        <ThemeText weight="extra-bold" style={styles.titleText}>
          Sit Tight!
        </ThemeText>
      </View>
      <ThemeText style={styles.briefText}>
        We are processing your purchase.
      </ThemeText>
    </View>
  );
};

const styles = StyleSheet.create({
  safeareaContainer: {
    flex: 1,
  },

  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    margin: 5,
  },
  titleText: {
    color: "white",
    fontSize: 20,
  },
  briefText: {
    color: "white",
    fontSize: 14,
  },
});

export default BuyConfirmProcessingScreen;
