import PrimaryButton from "@/components/PrimaryButton";
import ThemeText from "@/components/ThemeText";
import i18n, { t } from "@/lang/i18n";
import { BUY_SAVE_SCREEN } from "@/routes/Routes";
import { selectSelectedGame, selectTotalBetItems } from "@/store/bet";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

export default function BetNextButton() {
  const navigation = useNavigation();

  const selectedGame = useSelector(selectSelectedGame);
  const totalBetItems = useSelector(selectTotalBetItems);

  function onNextPress() {
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(BUY_SAVE_SCREEN);
  }

  return (
    <View>
      <PrimaryButton
        disabled={!selectedGame}
        title={i18n.t("next")}
        onPress={onNextPress}
      />
      <View style={styles.countContainer}>
        <ThemeText style={styles.countText}>{totalBetItems}</ThemeText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  countContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    marginRight: 16,
  },
  countText: {
    fontSize: 14,
  },
});
