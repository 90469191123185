import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const NextLotteryTimeSvg = (props : any) => (
  <Svg
    width={181}
    height={32}
    viewBox="0 0 181 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.24569 5.6138C9.28938 2.274 12.3824 0 15.8815 0H165.118C168.618 0 171.711 2.274 172.754 5.6138L181 32H0L8.24569 5.6138Z"
      fill="url(#paint0_linear_459_116)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_459_116"
        x1={0}
        y1={0}
        x2={20.8819}
        y2={-43.9964}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#E54780" />
        <Stop offset={1} stopColor="#8656ED" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default NextLotteryTimeSvg;
