import {
  Nunito_200ExtraLight,
  Nunito_300Light,
  Nunito_400Regular,
  Nunito_500Medium,
  Nunito_600SemiBold,
  Nunito_700Bold,
  Nunito_800ExtraBold,
  Nunito_900Black,
} from "@/constants/fonts";
import React, { PropsWithChildren } from "react";
import { StyleSheet, Text, TextProps } from "react-native";

type ThemeTextProps = {
  style?: TextProps["style"];
  weight?:
    | "regular"
    | "bold"
    | "semi-bold"
    | "extra-bold"
    | "light"
    | "extra-light"
    | "medium"
    | "semi-bold"
    // | "thin"
    | "black";
} & PropsWithChildren;

export default function ThemeText({ children, weight, style }: ThemeTextProps) {
  // @ts-ignore
  const format = weight && weight in styles ? styles[weight] : null;

  return <Text style={[styles.default, format, style]}>{children}</Text>;
}

// "Nunito-Sans-Black": require("@assets/fonts/NunitoSans_7pt-Black.ttf"),
// "Nunito-Sans-Bold": require("@assets/fonts/NunitoSans_7pt-Bold.ttf"),
// "Nunito-Sans-ExtraBold": require("@assets/fonts/NunitoSans_7pt-ExtraBold.ttf"),
// "Nunito-Sans-ExtraLight": require("@assets/fonts/NunitoSans_7pt-ExtraLight.ttf"),
// "Nunito-Sans-Light": require("@assets/fonts/NunitoSans_7pt-Light.ttf"),
// "Nunito-Sans-Medium": require("@assets/fonts/NunitoSans_7pt-Medium.ttf"),
// "Nunito-Sans-Regular": require("@assets/fonts/NunitoSans_7pt-Regular.ttf"),
// "Nunito-Sans_SemiBold": require("@assets/fonts/NunitoSans_7pt-SemiBold.ttf"),

const styles = StyleSheet.create({
  default: {
    color: "white",
    fontFamily: Nunito_400Regular,
    fontSize: 16,
  },
  //   thin: {
  //     fontFamily: "Nunito-Sans-Thin",
  //     fontWeight: "100",
  //   },
  "extra-light": {
    fontFamily: Nunito_200ExtraLight,
    fontWeight: "200",
  },
  light: {
    fontFamily: Nunito_300Light,
    fontWeight: "300",
  },
  regular: {
    fontFamily: Nunito_400Regular,
    fontWeight: "400",
  },
  medium: {
    fontFamily: Nunito_500Medium,
    fontWeight: "500",
  },
  "semi-bold": {
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
  },
  bold: {
    fontFamily: Nunito_700Bold,
    fontWeight: "700",
  },
  "extra-bold": {
    fontFamily: Nunito_800ExtraBold,
    fontWeight: "800",
  },
  black: {
    fontFamily: Nunito_900Black,
    fontWeight: "900",
  },
});
