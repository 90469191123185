export function generateRandomFourDNumbers() {
  const newSeries: string[] = ["", "", "", ""];
  for (let i = 0; i < 4; i++) {
    const randomNumber = Math.floor(Math.random() * 10).toString();
    newSeries[i] = randomNumber;
  }

  return newSeries.join("");
}

export function getBetNumbers(text: string) {
  const items: string[] = [];
  if (text.indexOf("S") > -1) {
    for (let s = 0; s <= 9; s++) {
      items.push(text.replace("S", s.toString()));
    }
  } else {
    items.push(text);
  }

  return items;
}

export function generateSeriesFromNumber(number: string) {
  const newSeries: string[] = ["", "", "", ""];
  for (let i = 0; i < 4; i++) {
    newSeries[i] = number[i];
  }

  return newSeries.join("");
}

// permute
export function generateRoundFromNumber(numbers: string): string[] {
  let result: string[] = [];

  function backtrack(curr: string, remaining: string): void {
    if (curr.length === 4) {
      result.push(curr);
      return;
    }

    for (let i = 0; i < remaining.length; i++) {
      let num: string = remaining[i];
      let newRemaining: string = remaining.slice(0, i) + remaining.slice(i + 1);
      backtrack(curr + num, newRemaining);
    }
  }

  backtrack("", numbers);

  const uniqueArr: string[] = [...new Set(result)];
  return uniqueArr.sort();
}
