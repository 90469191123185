import apiSlice from "@/api/apiSlice";
import { User } from "@/model/response/me_model";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveAuthToken } from "@/utils/storage";
import { RootState } from "../store";
import CryptoES from "crypto-es";

export interface AuthState {
  token: string | null;
  profile: User | null;
}

const initialState: AuthState = {
  token: null,
  profile: null,
};

// const persistedToken = localStorage.getItem("token");

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      saveAuthToken(action.payload);
      // localStorage.setItem("token", action.payload);
    },
    clearToken(state) {
      state.token = null;
      saveAuthToken(null);
      // localStorage.removeItem("token");
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      apiSlice.endpoints.getMe.matchFulfilled,
      (state, action) => {
        state.profile = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.updateAvatar.matchFulfilled,
      (state, action) => {
        if (state.profile) {
          state.profile.profile_image = action.payload.data.profile_image;
        }
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getBalance.matchFulfilled,
      (state, action) => {
        if (state.profile) {
          state.profile.amount = action.payload;
        }
      }
    );
  },
});

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectIsLogin = (state: RootState) => !!state.auth.token;

export const selectProfile = (state: RootState) => state.auth.profile;
export const selectProfileImage = (state: RootState) =>
  state.auth.profile?.profile_image;
export const selectProfileBalance = (state: RootState) =>
  state.auth.profile?.amount;

export const selectIsFeature = createSelector(
  selectProfile,
  selectAuthToken,
  (profile, token) => {
    if (!profile || !token) return true;
    const hash = CryptoES.HmacSHA256(
      token + "|FOUR_D",
      profile.username
    ).toString();
    return hash != profile.checksum;
  }
);

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;
