import {
  useActiveGamesQuery,
  useGetBetSettingByGameQuery,
} from "@/api/apiSlice";
import { accentColor, primaryBlackColor } from "@/constants/ColorConstants";
import { clearBetData, selectSelectedGame } from "@/store/bet";
import { useNavigation } from "@react-navigation/native";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as React from "react";
import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ActiveGameList from "./components/ActiveGameList";
import BetAddButton from "./components/BetAddButton";
import BetAmountInput from "./components/BetAmountInput";
import BetNextButton from "./components/BetNextButton";
import BetNumberInput from "./components/BetNumberInput";
import BuyFlowAlertMessage from "./components/BuyFlowAlertMessage";

export default function BuyFlowScreen() {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const selectedGame = useSelector(selectSelectedGame);

  const { refetch: refetchActiveGame } = useActiveGamesQuery();

  useGetBetSettingByGameQuery(
    selectedGame ? { gameId: selectedGame.id.toString() } : skipToken
  );

  React.useEffect(() => {
    dispatch(clearBetData());
    refetchActiveGame();
  }, []);

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
      >
        <ActiveGameList />

        <BetNumberInput />

        <View
          style={{
            height: 1,
            backgroundColor: accentColor,
            marginTop: 24,
            marginBottom: 24,
            marginHorizontal: 20,
          }}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginHorizontal: 20,
            marginBottom: 24,
          }}
        >
          <BetAmountInput />
          <BetAddButton />
        </View>

        <View
          style={{
            marginTop: "auto",
            marginBottom: 24,
            marginHorizontal: 20,
          }}
        >
          <BetNextButton />
        </View>
      </ScrollView>
      <BuyFlowAlertMessage />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    // flex: 1,
    flexGrow: 1,
    backgroundColor: primaryBlackColor,
  },
});
