import { View } from "react-native";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUpdateProgress } from "@/store/update";
import { Bar as ProgressBar } from "react-native-progress";
import ThemeText from "./ThemeText";
import {
  linearColorPurple,
  primaryBlackColor,
} from "@/constants/ColorConstants";
import LogoSvg from "./svgs/auth/LogoSvg";
import * as SplashScreen from "expo-splash-screen";

export default function AppUpdate() {
  const progress = useSelector(selectUpdateProgress);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: primaryBlackColor,
      }}
    >
      <View style={{ marginVertical: 48 }}>
        <LogoSvg width={180} />
      </View>
      <View style={{}}>
        {progress !== undefined && (
          <>
            <ThemeText style={{ marginBottom: 16, textAlign: "center" }}>
              App is Updating...
            </ThemeText>
            <ProgressBar
              progress={progress / 100}
              borderWidth={1}
              borderRadius={12}
              height={6}
              width={250}
              color={linearColorPurple}
              borderColor={linearColorPurple}
            />
          </>
        )}
      </View>
    </View>
  );
}
