import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const ProfileBackground = (props: any) => (
  <Svg
    width={341}
    height={103}
    viewBox="0 0 341 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M0 95C0 99.4183 3.58172 103 8 103H333C337.418 103 341 99.4183 341 95V8C341 3.58172 337.418 0 333 0H7.99999C3.58171 0 0 3.58172 0 8V95Z"
      fill="url(#paint0_linear_585_2279)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_585_2279"
        x1={-183.949}
        y1={-63.1733}
        x2={-44.3024}
        y2={-278.121}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#A852EB" />
        <Stop offset={1} stopColor="#5261EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default ProfileBackground;
