import ThemeText from "@/components/ThemeText";
import i18n, { t } from "@/lang/i18n";
import { selectTotalBetAmount } from "@/store/bet";
import { formatMoney } from "@/utils/format";
import React from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";

export default function TotalBetAmount() {
  const totalBetAmount = useSelector(selectTotalBetAmount);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <ThemeText
          style={{
            fontSize: 14,
            // marginBottom: 6,
            color: "white",
          }}
        >
          {i18n.t("total_cost")}
        </ThemeText>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <ThemeText
          weight="extra-bold"
          style={{
            fontSize: 16,
            // marginBottom: 6,
            color: "white",
            textAlignVertical: "bottom",
          }}
        >
          {formatMoney(totalBetAmount)} {i18n.t("ks")}
        </ThemeText>
      </View>
    </View>
  );
}
