import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TermsState {
  firstLogin: boolean | null;
}

const initialState: TermsState = {
  firstLogin: false,
};

// const persistedToken = localStorage.getItem("token");

const termAndConditionSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    setFirstLogin(state, action: PayloadAction<boolean>) {
      state.firstLogin = action.payload;

      // localStorage.setItem("token", action.payload);
    },
    clearFirstLogin(state) {
      state.firstLogin = false;

      // localStorage.removeItem("token");
    },
  },
});

export const { setFirstLogin, clearFirstLogin } = termAndConditionSlice.actions;

export default termAndConditionSlice.reducer;
