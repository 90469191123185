import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
const CashInActive = (props:any) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G opacity={0.5}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8884 15.8957H17.8404C15.9434 15.8957 14.3994 14.3527 14.3984 12.4567C14.3984 10.5587 15.9424 9.01467 17.8404 9.01367H21.8884C22.3024 9.01367 22.6384 9.34967 22.6384 9.76367C22.6384 10.1777 22.3024 10.5137 21.8884 10.5137H17.8404C16.7694 10.5147 15.8984 11.3857 15.8984 12.4557C15.8984 13.5247 16.7704 14.3957 17.8404 14.3957H21.8884C22.3024 14.3957 22.6384 14.7317 22.6384 15.1457C22.6384 15.5597 22.3024 15.8957 21.8884 15.8957Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2983 13.1436H17.9863C17.5723 13.1436 17.2363 12.8076 17.2363 12.3936C17.2363 11.9796 17.5723 11.6436 17.9863 11.6436H18.2983C18.7123 11.6436 19.0483 11.9796 19.0483 12.3936C19.0483 12.8076 18.7123 13.1436 18.2983 13.1436Z"
        fill="white"
      />
      <G mask="url(#mask0_455_599)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.998 4.5C5.518 4.5 3.5 6.518 3.5 8.998V16.175C3.5 18.655 5.518 20.673 7.998 20.673H16.642C19.122 20.673 21.139 18.655 21.139 16.175V8.998C21.139 6.518 19.122 4.5 16.642 4.5H7.998ZM16.642 22.173H7.998C4.691 22.173 2 19.482 2 16.175V8.998C2 5.69 4.691 3 7.998 3H16.642C19.949 3 22.639 5.69 22.639 8.998V16.175C22.639 19.482 19.949 22.173 16.642 22.173Z"
          fill="white"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6842 9.03809H7.28516C6.87116 9.03809 6.53516 8.70209 6.53516 8.28809C6.53516 7.87409 6.87116 7.53809 7.28516 7.53809H12.6842C13.0982 7.53809 13.4342 7.87409 13.4342 8.28809C13.4342 8.70209 13.0982 9.03809 12.6842 9.03809Z"
        fill="white"
      />
    </G>
  </Svg>
);
export default CashInActive;
