import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import uuid from "react-native-uuid";
import apiSlice from "../api/apiSlice";
import { SaveBetModel } from "../model/request/game_request_model";
import { BetSettingGameApiData } from "../model/response/bet_setting_game_model";
import { GameModel } from "../model/response/game_model";
import { RootState } from "./store";

type SelectableGameModel = { selected: boolean } & GameModel;

type BetError = {
  game_id?: string[];
  bets?: {
    id: string;
    fixed: boolean;
    bet_number: string[];
    bet_amount: string[];
  }[];
};

type BetSaveResult =
  | {
      type: "success";
      visible: boolean;
      id: number;
    }
  | {
      type: "error";
      confirm: boolean;
      message: string;
      visible: boolean;
      errors?: BetError;
    };

type UniqueSaveBetModel = {
  id: string;
} & SaveBetModel;

type BetState = {
  gameIds: number[];
  games: SelectableGameModel[];
  currentInputNumber: string;
  currentBetAmount: string;
  setting: BetSettingGameApiData | null;
  betAmountPerNumber: { [key: string]: number };
  bets: UniqueSaveBetModel[];
  alertMessage: {
    title: string;
    message: string;
  } | null;
  betSaveResult: BetSaveResult | null;
};

function internalClearBetData(state: BetState) {
  state.bets = [];
  state.currentInputNumber = "";
  state.currentBetAmount = "100";
  state.betAmountPerNumber = {};
  state.alertMessage = null;
  state.betSaveResult = null;
}

const initialState: BetState = {
  gameIds: [],
  games: [],
  currentInputNumber: "",
  currentBetAmount: "100",
  setting: null,
  betAmountPerNumber: {},
  bets: [],
  alertMessage: null,
  betSaveResult: null,
};

const betSlice = createSlice({
  name: "bet",
  initialState,
  reducers: {
    setSelectedGame(state, action: PayloadAction<number>) {
      state.games.forEach((game) => {
        if (game.id === action.payload) {
          game.selected = true;
        }
        if (game.id !== action.payload && game.selected) {
          game.selected = false;
        }
      });
      state.setting = null;
      internalClearBetData(state);
    },
    setCurrentInputNumber(state, action: PayloadAction<string>) {
      state.currentInputNumber = action.payload;
    },
    appendCurrentInputNumber(state, action: PayloadAction<string>) {
      if (state.currentInputNumber.length === 4) return;

      state.currentInputNumber += action.payload;
    },
    setCurrentBetAmount(state, action: PayloadAction<string>) {
      state.currentBetAmount = action.payload;
    },
    addBets(state, action: PayloadAction<SaveBetModel[]>) {
      let isSeries = false;
      const closedNumbers = state.setting?.closed_numbers || [];
      const maxAmountPerNumber =
        state.setting?.bet_limitation.max_amount_per_number || 0;

      for (let bet of action.payload) {
        const placedBetAmount =
          state.setting?.placed_bet_amount?.[bet.bet_number] || 0;
        const betAmountPerNumber =
          state.betAmountPerNumber[bet.bet_number] || 0;

        if (closedNumbers.includes(bet.bet_number)) {
          state.alertMessage = {
            title: "Error",
            message: `Number ${bet.bet_number} is closed`,
          };
          return;
        }

        if (
          betAmountPerNumber + bet.bet_amount + placedBetAmount >
          maxAmountPerNumber
        ) {
          state.alertMessage = {
            title: "Error",
            message: `Number ${bet.bet_number} had reached maximum bet amount`,
          };
          return;
        }

        if (!isSeries) {
          isSeries = bet.bet_number.includes("S");
        }
      }

      action.payload.forEach((bet) => {
        state.bets.push({
          ...bet,
          id: uuid.v4().toString(),
        });
        if (!state.betAmountPerNumber[bet.bet_number]) {
          state.betAmountPerNumber[bet.bet_number] = 0;
        }
        state.betAmountPerNumber[bet.bet_number] += bet.bet_amount;
      });

      state.currentInputNumber = "";
      state.currentBetAmount =
        state.setting?.bet_limitation.default_bet_amount.toString() || "100";

      if (isSeries) {
        state.alertMessage = {
          title: "Success",
          message: "Series Completed.",
        };
      }
    },
    updateBetItemBetAmount(
      state,
      action: PayloadAction<{ id: string; amount: number }>
    ) {
      const bet = state.bets.find((bet) => bet.id === action.payload.id);

      if (!bet) return;

      state.betAmountPerNumber[bet.bet_number] -= bet.bet_amount;
      bet.bet_amount = action.payload.amount;
      state.betAmountPerNumber[bet.bet_number] += bet.bet_amount;

      if (state.betSaveResult?.type == "error") {
        const error = state.betSaveResult?.errors?.bets?.find(
          (bet) => bet.id === action.payload.id
        );
        if (error) {
          error.fixed = true;
        }
        console.log(error);
      }
    },
    removeBetItem(state, action: PayloadAction<string>) {
      const betIndex = state.bets.findIndex((bet) => bet.id === action.payload);
      if (betIndex === -1) return;

      const bet = state.bets[betIndex];
      state.bets.splice(betIndex, 1);
      state.betAmountPerNumber[bet.bet_number] -= bet.bet_amount;

      if (state.betSaveResult?.type == "error") {
        const error = state.betSaveResult?.errors?.bets?.find(
          (bet) => bet.id === action.payload
        );
        if (error) {
          error.fixed = true;
        }
      }
    },
    setBetResultVisible(state, action: PayloadAction<boolean>) {
      if (state.betSaveResult) {
        state.betSaveResult.visible = action.payload;
      }
    },
    clearAlertMessage(state) {
      state.alertMessage = null;
    },
    clearSaveBetResult(state) {
      state.betSaveResult = null;
    },
    clearBetData(state) {
      internalClearBetData(state);
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      apiSlice.endpoints.activeGames.matchFulfilled,
      (state, action) => {
        const previousSelectedGameId = state.games.find(
          (game) => game.selected
        )?.id;
        const hasPreviousGame = action.payload.find(
          (game) => game.id == previousSelectedGameId
        );
        state.gameIds = action.payload.map((game) => game.id);
        state.games = action.payload.map((game, index) => {
          return {
            ...game,
            selected: hasPreviousGame
              ? hasPreviousGame.id === game.id
              : index === 0,
          };
        });

        if (previousSelectedGameId && !hasPreviousGame) {
          internalClearBetData(state);
        }
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getBetSettingByGame.matchFulfilled,
      (state, action) => {
        state.setting = action.payload;
        state.currentBetAmount =
          action.payload.bet_limitation.default_bet_amount.toString() || '100';
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.betSave.matchRejected,
      (state, action) => {
        const betErrors: BetError = {
          bets: [],
        };

        let message = "Unknown Error";

        if (action.payload?.data && typeof action.payload.data === "object") {
          if (
            "message" in action.payload.data &&
            action.payload.data.message &&
            typeof action.payload.data.message === "string"
          ) {
            message = action.payload.data.message;
          }
          if (
            "errors" in action.payload.data &&
            action.payload.data.errors &&
            typeof action.payload.data.errors === "object"
          ) {
            const errors = action.payload.data.errors as Record<
              string,
              string[]
            >;
            if ("game_id" in action.payload.data.errors) {
              betErrors.game_id = action.payload.data.errors
                .game_id as string[];
            }

            state.bets.forEach((bet, index) => {
              const betNumberKey = `bets.${index}.bet_number`;
              const betAmountKey = `bets.${index}.bet_amount`;
              if (betNumberKey in errors || betAmountKey in errors) {
                betErrors.bets?.push({
                  id: bet.id,
                  fixed: false,
                  bet_number: errors[betNumberKey] || [],
                  bet_amount: errors[betAmountKey] || [],
                });
              }
            });
          }
        }

        state.betSaveResult = {
          type: "error",
          confirm: false,
          message: message,
          visible: true,
          errors: betErrors,
        };
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.betSave.matchRejected,
      (state, action) => {
        let message = "Unknown Error";

        if (action.payload?.data && typeof action.payload.data === "object") {
          if (
            "message" in action.payload.data &&
            action.payload.data.message &&
            typeof action.payload.data.message === "string"
          ) {
            message = action.payload.data.message;
          }
        }

        state.betSaveResult = {
          type: "error",
          confirm: true,
          message: message,
          visible: true,
        };
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.betSave.matchFulfilled,
      (state, action) => {
        state.betSaveResult = {
          type: "success",
          visible: true,
          id: action.payload.data.id,
        };
      }
    );
  },
});

export const selectGameIds = (state: RootState) => state.bet.gameIds;

export const selectSelectedGame = (state: RootState) => {
  return state.bet.games.find((game) => game.selected);
};

export const selectCurrentInputNumber = (state: RootState) =>
  state.bet.currentInputNumber;

export const selectCurrentBetAmount = (state: RootState) =>
  state.bet.currentBetAmount;

export const selectCanInput = (state: RootState) =>
  state.bet.currentInputNumber.length < 4;

export const selectCanInputS = (state: RootState) =>
  state.bet.currentInputNumber.length < 4 &&
  !state.bet.currentInputNumber.includes("S");

export const selectCanInputR = (state: RootState) =>
  state.bet.currentInputNumber.length === 4 &&
  !state.bet.currentInputNumber.includes("S");

export const selectCanAddBet = createSelector(
  selectSelectedGame,
  selectCanInput,
  (game, canInput) => {
    return game && !canInput;
  }
);

export const selectBetAmountPerNumber = (state: RootState) =>
  state.bet.betAmountPerNumber;

export const selectMinAmountPerBet = (state: RootState) =>
  state.bet.setting?.bet_limitation.min_amount_per_bet || 0;

export const selectMaxAmountPerBet = (state: RootState) =>
  state.bet.setting?.bet_limitation.max_amount_per_bet || 0;

export const selectClosedNumber = (state: RootState) =>
  state.bet.setting?.closed_numbers || [];

export const selectPlacedBetAmount = (state: RootState) =>
  state.bet.setting?.placed_bet_amount;

export const selectBetItemIds = createSelector(
  (state: RootState) => state.bet.bets,
  (bets) => bets.map((bet) => bet.id)
);

export const selectTotalBetItems = (state: RootState) => state.bet.bets.length;

export const selectTotalBetAmount = (state: RootState) => {
  let total = 0;
  for (let key in state.bet.betAmountPerNumber) {
    total += state.bet.betAmountPerNumber[key];
  }
  return total;
};

export const selectBetErrorById = createSelector(
  (state: RootState) => state.bet.betSaveResult,
  (_: RootState, id: string) => id,
  (result, id) => {
    if (result?.type !== "error") return null;

    return result.errors?.bets?.find((bet) => bet.id === id && !bet.fixed);
  }
);

export const selectGameById = createSelector(
  (state: RootState) => state.bet.games,
  (_: RootState, id: number) => id,
  (games, id) => {
    return games.find((game) => game.id === id);
  }
);

export const {
  setSelectedGame,
  setCurrentInputNumber,
  appendCurrentInputNumber,
  setCurrentBetAmount,
  addBets,
  clearAlertMessage,
  clearBetData,
  updateBetItemBetAmount,
  removeBetItem,
  setBetResultVisible,
  clearSaveBetResult,
} = betSlice.actions;

export default betSlice;
