import React, { useEffect } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { useWithdrawMutation } from "@/api/apiSlice";
import AlertDialog from "@/components/AlertDialog";
import EditText from "@/components/EditText";
import PrimaryButton from "@/components/PrimaryButton";
import ThemeText from "@/components/ThemeText";
import { accentColor, primaryBlackColor } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { WithdrawRequestModel } from "@/model/request/withdraw_request_model";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import { WITHDRAW_SCREEN } from "@/routes/Routes";
import { setLoading } from "@/store/loading";
import { AppDispatch } from "@/store/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { Picker } from "@react-native-picker/picker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { Nunito_600SemiBold } from "@/constants/fonts";

type PageProps = IRootStackScreenProps<typeof WITHDRAW_SCREEN>;

const scheme = z.object({
  amount: z.coerce
    .number({
      required_error: i18n.t("withdraw_amount_required"),
      invalid_type_error: i18n.t("withdraw_amount_invalid"),
    })
    .positive("Amount must be at least 1000")
    .min(1000, "Amount must be at least 1000")
    .transform((x) => (x ? x : undefined)),
  account_name: z
    .string({
      required_error: i18n.t("withdraw_account_name_required"),
    })
    .trim(),
  payment_info: z
    .string({
      required_error: i18n.t("withdraw_payment_info_required"),
    })
    .trim(),
  payment_method: z.union([
    z.literal("kpay", {
      invalid_type_error: "Please select correct payment method",
    }),
    z.literal("wave", {
      invalid_type_error: "Please select correct payment method",
    }),
  ]),
});

export default function WithdrawScreen({ navigation }: PageProps) {
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<WithdrawRequestModel>({
    shouldFocusError: true,
    mode: "onChange",
    defaultValues: {
      payment_method: "kpay",
    },
    resolver: zodResolver(scheme),
  });

  //api
  const [withdraw, { isLoading, isError, error, isSuccess, reset }] =
    useWithdrawMutation();

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
      >
        <EditText
          control={control}
          name="amount"
          placeholder={i18n.t("withdraw_amount_placeholder")}
          keyboardType="numeric"
          autoCapitalize="none"
          autoCorrect={false}
        />

        <EditText
          control={control}
          name="account_name"
          placeholder={i18n.t("withdraw_account_name_placeholder")}
          keyboardType="phone-pad"
          autoCapitalize="none"
          autoCorrect={false}
        />

        <EditText
          control={control}
          name="payment_info"
          placeholder={i18n.t("withdraw_payment_info_placeholder")}
          autoCapitalize="none"
          autoCorrect={false}
        />

        <Controller
          control={control}
          name="payment_method"
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => {
            return (
              <View style={styles.inputContainer}>
                <View style={{ borderRadius: 8, backgroundColor: accentColor }}>
                  <Picker
                    ref={ref}
                    style={[
                      styles.input,
                      !value && { color: "rgba(255, 255, 255, 0.5)" },
                      Platform.OS === "web" && {
                        borderRadius: 8,
                        backgroundColor: accentColor,
                        borderWidth: 0,
                        height: 48.5,
                      },
                    ]}
                    selectedValue={value}
                    dropdownIconColor="white"
                    onValueChange={onChange}
                  >
                    <Picker.Item label="K Pay" value="kpay" />
                    <Picker.Item label="WAVE Pay" value="wave" />
                  </Picker>
                </View>
                {error?.message && (
                  <ThemeText style={styles.error}>{error.message}</ThemeText>
                )}
              </View>
            );
          }}
        />

        <View style={{ marginTop: "auto", paddingBottom: 32 }}>
          <PrimaryButton
            onPress={handleSubmit((data) => withdraw(data))}
            title={i18n.t("withdraw_form_button")}
          />
        </View>

        <AlertDialog
          visible={isSuccess}
          title={i18n.t("success")}
          description={i18n.t("withdraw_success_message")}
          actions={[
            {
              title: "OK",
              onPress: () => {
                navigation.goBack();
                reset();
              },
            },
          ]}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    paddingHorizontal: 20,
    marginTop: 24,
    // paddingBottom: 32,
  },
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingTop: 24,
    paddingHorizontal: 20,
    backgroundColor: primaryBlackColor,
  },
  inputContainer: {
    marginBottom: 16,
  },
  input: {
    height: 58,
    paddingHorizontal: 16,
    // paddingVertical: 15,
    alignItems: "center",
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
    color: "white",
    fontSize: 16,
  },
  error: {
    color: "#ff0000",
    fontSize: 14,
    marginTop: 5,
  },
});
