import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

type UpdateState = {
  progress: number | undefined;
};

const initialState: UpdateState = {
  progress: undefined,
};

export const updateSlice = createSlice({
  name: "update",
  initialState: initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<number | undefined>) => {
      state.progress = action.payload;
    },
  },
});

export const selectUpdateProgress = (state: RootState) => state.update.progress;
export const selectIsUpdating = (state: RootState) =>
  state.update.progress !== undefined;

export const { setProgress } = updateSlice.actions;
