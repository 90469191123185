import {
  useGenerateFileUrlMutation,
  useTopUpInfoQuery,
  useTopUpMutation,
} from "@/api/apiSlice";
import AlertDialog from "@/components/AlertDialog";
import EditText from "@/components/EditText";
import GradientOutlineButton from "@/components/GradientOutlineButton";
import PrimaryButton from "@/components/PrimaryButton";
import PrimaryGradient from "@/components/PrimaryGradient";
import ThemeText from "@/components/ThemeText";
import { accentColor, primaryBlackColor } from "@/constants/ColorConstants";
import { Nunito_600SemiBold } from "@/constants/fonts";
import useDisableGlobalError from "@/hooks/useDisableGlobalError";
import i18n, { t } from "@/lang/i18n";
import { TopUpRequestModel } from "@/model/request/topup_request_model";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import { TOP_UP_SCREEN } from "@/routes/Routes";
import { setLoading } from "@/store/loading";
import { AppDispatch } from "@/store/store";
import upload from "@/utils/upload";
import { Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import Clipboard from "@react-native-clipboard/clipboard";
import { Picker } from "@react-native-picker/picker";
import * as ImagePicker from "expo-image-picker";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  Image,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import { useDispatch } from "react-redux";
import { z } from "zod";
import PaymentMethodButton from "./PaymentMethodButton";

type PageProps = IRootStackScreenProps<typeof TOP_UP_SCREEN>;

const scheme = z.object({
  amount: z.coerce
    .number({
      required_error: i18n.t("top_up_amount_required"),
      invalid_type_error: i18n.t("top_up_amount_invalid"),
    })
    .positive("Amount must be at least 1000")
    .min(1000, "Amount must be at least 1000")
    .transform((x) => (x ? x : undefined)),
  phone_number: z
    .string({
      required_error: i18n.t("top_up_phone_number_required"),
    })
    .trim(),
  screenshot_key: z.string({
    required_error: i18n.t("top_up_screenshot_key_required"),
  }),
  payment_method: z.union([
    z.literal("kpay", {
      invalid_type_error: "Please select correct payment method",
    }),
    z.literal("wave", {
      invalid_type_error: "Please select correct payment method",
    }),
  ]),
});

export default function TopUpScreen({ navigation }: PageProps) {
  useDisableGlobalError();

  const { width } = useWindowDimensions();

  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    formState: { isValid, errors: formErrors },
    handleSubmit,
    setValue,
    reset,
    resetField,
    watch,
  } = useForm<TopUpRequestModel>({
    shouldFocusError: true,
    resolver: zodResolver(scheme),
    defaultValues: {
      payment_method: "kpay",
    },
    mode: "onChange",
  });

  const watchScreenShot = watch("screenshot_key");
  const watchPaymentMethod = watch("payment_method");

  const [error, setError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [generateFileUrl, { reset: resetGenerateFileUrl }] =
    useGenerateFileUrlMutation();

  const { data: info, isFetching: isInfoFetching } = useTopUpInfoQuery();

  const [topUp, { isSuccess: isTopUpSuccess, reset: resetTopUp }] =
    useTopUpMutation();

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  const submit = async (data: TopUpRequestModel) => {
    setIsLoading(true);
    try {
      const { url, key } = await generateFileUrl().unwrap();
      await upload(url, data.screenshot_key);
      await topUp({
        ...data,
        screenshot_key: key,
      }).unwrap();
    } catch (error) {
      if (
        typeof error === "object" &&
        error !== null &&
        "data" in error &&
        typeof error.data === "object" &&
        error.data !== null &&
        "message" in error.data &&
        typeof error.data.message === "string"
      ) {
        return setError(error.data.message);
      }
      setError("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access camera roll is required!");
      return;
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
    });

    if (result.canceled) {
      return;
    }

    const image = result.assets[0];

    if (image.uri == null) return;

    setValue("screenshot_key", image.uri);
  };

  if (isInfoFetching) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  const paymentMethodInfo = info?.data
    ? watchPaymentMethod == "kpay"
      ? info.data.kpay
      : watchPaymentMethod == "wave"
      ? info?.data.wave_pay
      : null
    : null;

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
      >
        <View style={{ flexDirection: "row", gap: 16, marginBottom: 16 }}>
          <PaymentMethodButton
            style={{ flex: 1 }}
            onPress={() => {
              setValue("payment_method", "kpay");
            }}
            selected={watchPaymentMethod === "kpay"}
            title="K Pay"
          />

          <PaymentMethodButton
            style={{ flex: 1 }}
            onPress={() => {
              setValue("payment_method", "wave");
            }}
            selected={watchPaymentMethod === "wave"}
            title="Wave Pay"
          />
        </View>

        <ThemeText style={styles.paymentInfoHint}>
          အောက်ပါအကောင့်သိုငွေအရင်လွဲပါ အနည်းဆုံး ၁၀၀၀ ကျပ်
        </ThemeText>

        {paymentMethodInfo && (
          <View style={styles.inputContainer}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View>
                <ThemeText>
                  Account : {paymentMethodInfo.phone_number}
                </ThemeText>
                <ThemeText>{paymentMethodInfo.description}</ThemeText>
              </View>
              <Pressable
                style={({ pressed }) => [
                  pressed && {
                    opacity: 0.65,
                  },
                ]}
                onPress={() => {
                  Clipboard.setString(paymentMethodInfo.phone_number);
                }}
              >
                <ThemeText
                  style={{
                    marginLeft: 8,
                    fontSize: 12,
                    // opacity: 0.5,
                    color: "#A9A6AD",
                    paddingVertical: 8,
                    paddingHorizontal: 16,
                    borderRadius: 8,
                    backgroundColor: "#3a3a3a",
                  }}
                >
                  Copy
                </ThemeText>
              </Pressable>
            </View>
            {paymentMethodInfo.image && (
              <Image
                style={{
                  width: width - 40,
                  height: 250,
                  resizeMode: "center",
                  marginTop: 8,
                }}
                source={{ uri: paymentMethodInfo.image }}
              />
            )}
          </View>
        )}

        <EditText
          control={control}
          placeholder={i18n.t("top_up_amount_placeholder")}
          name="amount"
          keyboardType="numeric"
          autoCapitalize="none"
          autoCorrect={false}
        >
          {/* <ThemeText style={{ marginTop: 4, marginBottom: 2 }}>
            {i18n.t("minimum_top_up_amount_help_text")}
          </ThemeText> */}
        </EditText>

        <EditText
          control={control}
          placeholder={i18n.t("top_up_phone_number_placeholder")}
          name="phone_number"
          keyboardType="numeric"
          autoCapitalize="none"
          autoCorrect={false}
        />

        <View style={styles.inputContainer}>
          {watchScreenShot ? (
            <View style={styles.imageContainer}>
              <Image source={{ uri: watchScreenShot }} style={styles.image} />
              <Pressable
                style={({ pressed }) => [
                  styles.closeIconContainer,
                  pressed && { opacity: 0.65 },
                ]}
                onPress={() => {
                  setValue("screenshot_key", "");
                }}
              >
                <View style={styles.closeIconCircle}>
                  <Ionicons name="close-outline" size={24} color="black" />
                </View>
              </Pressable>
            </View>
          ) : (
            <Pressable
              style={({ pressed }) => [
                styles.imagePickerButton,
                pressed && { opacity: 0.65 },
              ]}
              onPress={handlePickImage}
            >
              <ThemeText
                style={{
                  color: "#A9A6AD",
                  textAlign: "center",
                  paddingHorizontal: 12,
                }}
              >
                {i18n.t("select_screenshot_btn")}
              </ThemeText>
            </Pressable>
          )}
          {formErrors.screenshot_key?.message && (
            <ThemeText style={styles.error}>
              {formErrors.screenshot_key.message}
            </ThemeText>
          )}
        </View>

        <View style={{ marginTop: "auto", paddingBottom: 32 }}>
          <PrimaryButton
            // disabled={!isValid}
            onPress={handleSubmit(
              (data) => submit(data),
              (e) => console.log(e)
            )}
            title={i18n.t("top_up_form_button")}
          />
        </View>

        <AlertDialog
          visible={!!error || isTopUpSuccess}
          title={error ? i18n.t("error") : i18n.t("success")}
          description={error ? error : i18n.t("top_up_success_message")}
          actions={[
            isTopUpSuccess
              ? {
                  title: "OK",
                  onPress: () => {
                    navigation.goBack();
                    resetGenerateFileUrl();
                    resetTopUp();
                    reset();
                  },
                }
              : {
                  title: "Close",
                  onPress: () => {
                    resetGenerateFileUrl();
                    resetTopUp();
                    setError(null);
                  },
                },
          ]}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  paymentInfoHint: {
    fontSize: 12,
    opacity: 0.45,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingTop: 24,
    paddingHorizontal: 20,
    backgroundColor: primaryBlackColor,
  },
  inputContainer: {
    marginBottom: 16,
  },
  input: {
    height: 58,
    paddingHorizontal: 16,
    // paddingVertical: 15,
    alignItems: "center",
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
    color: "white",
    fontSize: 16,
  },
  error: {
    color: "#ff0000",
    fontSize: 14,
    marginTop: 5,
  },
  imagePickerButton: {
    width: 200,
    height: 200,
    backgroundColor: "#3a3a3a",
    borderRadius: 10,
    marginBottom: 13,
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    width: 200,
    height: 200,
    position: "relative",
    borderRadius: 10,
  },
  image: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
  },
  closeIconContainer: {
    position: "absolute",
    top: 10,
    right: 15,
    zIndex: 1,
  },
  closeIconCircle: {
    backgroundColor: "white",
    width: 30,
    height: 30,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});

// const styles = StyleSheet.create({
//   scrollView: {
//     width: "100%",
//     height: "100%",
//   },
//   scrollContainer: {
//     justifyContent: "flex-start",
//   },
//   imageContainer: {
//     width: 200,
//     height: 200,

//     position: "relative",
//   },

//   image: {
//     width: 200,
//     height: 200,
//     marginBottom: 5,
//     marginTop: 10,
//   },
//   closeIconContainer: {
//     position: "absolute",
//     top: 20,
//     right: 20,
//     zIndex: 1,
//   },
//   closeIconCircle: {
//     backgroundColor: linearColorPurple,
//     width: 40,
//     height: 40,
//     borderRadius: 20,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   loginTextContainer: {
//     borderRadius: 8,
//     alignItems: "flex-start",
//     justifyContent: "center",
//     marginBottom: 20,
//     alignSelf: "flex-start",
//   },
//   loginText: {
//     color: "white",
//     fontWeight: "700",
//     fontSize: 28,
//   },
//   container: {
//     flex: 1,
//     paddingHorizontal: 20,
//     paddingVertical: 30,
//   },
//   inputContainer: {
//     marginBottom: 20,
//   },
//   label: {
//     color: "#fff",
//     fontSize: 18,
//     marginBottom: 10,
//   },
//   input: {
//     color: "white",
//     backgroundColor: "#282031",
//     padding: 10,
//     borderRadius: 5,
//     fontSize: 16,
//   },
//   error: {
//     color: "#ff0000",
//     fontSize: 14,
//     marginTop: 5,
//   },
//   button: {
//     backgroundColor: "#8352EB",
//     padding: 15,
//     borderRadius: 5,
//     alignItems: "center",
//   },
//   disabledButton: {
//     backgroundColor: "#4F4A56",
//     padding: 15,
//     borderRadius: 5,
//     alignItems: "center",
//     opacity: 0.5,
//   },
//   buttonText: {
//     color: "#fff",
//     fontSize: 18,
//     fontWeight: "bold",
//   },
//   disabledButtonText: {
//     color: "#ccc",
//     fontSize: 18,
//     fontWeight: "bold",
//   },
//   forgotPassword: {
//     color: "#fff",
//     fontSize: 13,
//     textAlign: "center",
//     marginTop: 6,
//     marginBottom: 10,
//   },
//   clickHere: {
//     fontWeight: "bold",
//     textDecorationLine: "underline",
//   },
//   createAccount: {
//     textAlign: "center",
//     marginTop: 20,
//     fontWeight: "bold",
//     color: "#fff",
//   },
// });
