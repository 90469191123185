import React from "react";
import { StyleSheet, Text, TextProps, View, ViewProps } from "react-native";
import {
  numberBgGreen,
  numberBgPurple,
  numberBgRed,
  numberBorderGreen,
  numberBorderPurple,
  numberBorderRed,
} from "../constants/ColorConstants";

type Props = {
  paddingVertical?: number;
  paddingHorizontal?: number;
  number: number;
  error?: boolean;
  success?: boolean;
  containerStyle?: ViewProps["style"];
  textStyle?: TextProps["style"];
};

const RectangleNumber: React.FC<Props> = ({
  // paddingVertical,
  // paddingHorizontal,
  containerStyle,
  textStyle,
  error,
  number,
  success,
}) => {
  return (
    <View
      style={[
        styles.container,
        // paddingHorizontal ? { paddingHorizontal } : null,
        // paddingVertical ? { paddingVertical } : null,
        error && styles.error,
        success && styles.success,
        containerStyle,
      ]}
    >
      <Text style={[styles.text, textStyle]}>{number}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: numberBgPurple,
    borderColor: numberBorderPurple,
    borderRadius: 4,
    margin: 2,
    width: 24,
    // height: "100%",
    // paddingHorizontal: 6,
    paddingVertical: 8,
  },
  text: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: 20,
    color: "white",
  },
  error: { backgroundColor: numberBgRed, borderColor: numberBorderRed },
  success: { backgroundColor: numberBgGreen, borderColor: numberBorderGreen },
});

export default RectangleNumber;
