import * as React from "react";
import Svg, {
  Defs,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from "react-native-svg";

const DateRec = (props: SvgProps) => (
  <Svg
    height={32}
    viewBox="0 0 201 32"
    fill="none"
    {...props}
    preserveAspectRatio="none"
  >
    <Path
      d="M8.28435 7.16639C9.52373 3.20037 13.1968 0.5 17.3519 0.5H183.648C187.803 0.5 191.476 3.20037 192.716 7.16639L200.32 31.5H0.680095L8.28435 7.16639Z"
      stroke="url(#paint0_linear_455_950)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_455_950"
        x1={45.3686}
        y1={-18}
        x2={100.25}
        y2={32.2726}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default DateRec;
