import React from "react";
import { StatusBar, StyleSheet, useWindowDimensions } from "react-native";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { viberFloatingBgPink } from "../constants/ColorConstants";
import { HEADER_HEIGHT, TAB_BAR_HEIGHT } from "../constants/size";
import ViberSvg from "./svgs/home/ViberSvg";

export type DraggableFloatingButtonProps = {
  onPress: () => void;
  text: string;
};

const DraggableFloatingButton = ({
  onPress,
  text,
}: DraggableFloatingButtonProps) => {
  const insets = useSafeAreaInsets();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const buttonWidth = 60;
  const buttonHeight = 60;

  const offsetHeight =
    buttonHeight +
    TAB_BAR_HEIGHT +
    HEADER_HEIGHT +
    insets.top +
    insets.bottom +
    (StatusBar.currentHeight ?? 0);

  const start = useSharedValue({
    x: screenWidth - buttonWidth,
    y: screenHeight - offsetHeight - 20,
  });
  const offsetX = useSharedValue(screenWidth - buttonWidth);
  const offsetY = useSharedValue(screenHeight - offsetHeight - 20);
  const opacity = useSharedValue(1);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offsetX.value }, { translateY: offsetY.value }],
      opacity: opacity.value,
    };
  });

  const panGesture = Gesture.Pan()
    .onUpdate((e) => {
      const newX = start.value.x + e.translationX;
      const newY = start.value.y + e.translationY;
      const maxPosX = screenWidth - buttonWidth; // Button width
      const maxPosY = screenHeight - offsetHeight; //

      offsetX.value = Math.max(0, Math.min(maxPosX, newX));
      offsetY.value = Math.max(0, Math.min(maxPosY, newY));
    })
    .onEnd(() => {
      const halfScreenWidth = screenWidth / 2;

      const maxPosY = screenHeight - offsetHeight - 20;

      const tmp = {
        x: offsetX.value > halfScreenWidth ? screenWidth - buttonWidth : 0,
        y:
          offsetY.value < 0
            ? 0
            : offsetY.value > maxPosY
            ? maxPosY
            : offsetY.value,
      };

      start.value = { ...tmp };
      offsetX.value = withSpring(tmp.x, {
        mass: 0.2,
      });
      offsetY.value = withSpring(tmp.y, {
        mass: 0.2,
      });
    });

  const tapGesture = Gesture.Tap()
    .onBegin(() => {
      opacity.value = 0.75;
    })
    .onEnd((_event, success) => {
      if (success) {
        onPress();
      }
      opacity.value = withSpring(1);
    })
    .onTouchesCancelled(() => {
      opacity.value = withSpring(1);
    })
    .runOnJS(true);

  const composed = Gesture.Exclusive(tapGesture, panGesture);

  return (
    <GestureDetector gesture={composed}>
      <Animated.View style={[styles.buttonContainer, animatedStyles]}>
        <ViberSvg />
      </Animated.View>
    </GestureDetector>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: viberFloatingBgPink,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
});

export default DraggableFloatingButton;
