import { useBuyOrderHistoryQuery } from "@/api/apiSlice";
import { primaryBlackColor } from "@/constants/ColorConstants";
import React, { useCallback, useState } from "react";
import { ActivityIndicator, FlatList, Text, View } from "react-native";

import { Ticket } from "@/model/response/order_model";
import SlipItem from "@/screens/home/buy-history/components/SlipItem";
import { useFocusEffect } from "@react-navigation/native";

function renderItem({ item }: { item: Ticket }) {
  return <SlipItem slip={item} />;
}

const BuyOrderHistory: React.FC = () => {
  const [page, setPage] = useState(1);

  //api
  const {
    data: orders,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
  } = useBuyOrderHistoryQuery({
    page,
  });

  useFocusEffect(
    useCallback(() => {
      setPage(1);
    }, [])
  );

  if (isOrderLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return orders ? (
    <FlatList
      data={orders.data}
      contentContainerStyle={{ paddingBottom: 24 }}
      keyExtractor={(item) => item.id.toString()}
      renderItem={renderItem}
      onEndReached={() => {
        if (page < orders.meta.last_page && !isOrderFetching) {
          setPage((prevPage) => prevPage + 1);
        }
      }}
      onEndReachedThreshold={0.1}
      ListFooterComponent={
        isOrderFetching ? <ActivityIndicator size="large" /> : null
      }
    />
  ) : (
    <Text>No Bet History</Text>
  );
};

export default BuyOrderHistory;
