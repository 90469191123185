import { buttonDisableBorder } from "@/constants/ColorConstants";
import React from "react";
import type { TextProps, TouchableOpacityProps, ViewProps } from "react-native";
import { Pressable, Text } from "react-native";

type OutlineButtonProps = {
  title: string;
  titleStyle?: TextProps["style"];
  buttonStyle?: ViewProps["style"];
} & Omit<TouchableOpacityProps, "style">;

export default function OutlineButton({
  title,
  titleStyle,
  buttonStyle,
  ...props
}: OutlineButtonProps) {
  return (
    <Pressable
      style={({ pressed }) => [
        {
          borderColor: buttonDisableBorder,
          borderRadius: 8,
          borderWidth: 2,
          height: 52,
          alignItems: "center",
          justifyContent: "center",
          paddingHorizontal: 8,
          // backgroundColor: "black",
        },
        pressed && { opacity: 0.65 },
        buttonStyle,
      ]}
      {...props}
    >
      <Text
        style={[
          {
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
            color: "white",
          },
          titleStyle,
        ]}
      >
        {title}
      </Text>
    </Pressable>
  );
}
