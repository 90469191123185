import AlertDialog from "@/components/AlertDialog";
import { clearAlertMessage } from "@/store/bet";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function BuyFlowAlertMessage() {
  const dispatch = useDispatch();
  const alertMessage = useSelector(
    (state: RootState) => state.bet.alertMessage
  );

  function onClose() {
    dispatch(clearAlertMessage());
  }

  return (
    <AlertDialog
      visible={!!alertMessage}
      onDismiss={onClose}
      title={alertMessage?.title || ""}
      description={alertMessage?.message || ""}
      actions={[
        {
          title: "OK",
          onPress: onClose,
        },
      ]}
    />
  );
}
