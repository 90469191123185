import PrimaryGradient from "@/components/PrimaryGradient";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  SectionList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  useGetLatestResultWeekQuery,
  useGetWeekResultWinnersQuery,
} from "@/api/apiSlice";
import {
  activeGameBorder,
  primaryBlackColor,
} from "@/constants/ColorConstants";
import ThemeText from "@/components/ThemeText";
import WinnerHeaderSvg from "@/components/svgs/winner/WInnerHeaderSvg";
import WinNumberInHeader from "./components/WinnerNumberInHeader";
import WinnerListItem from "./components/WinnerListItem";

const WinnerScreen: React.FC = () => {
  const navigation = useNavigation();

  const [selectedGameDate, setSelectedGameText] = useState<string | null>(null);
  const [selectedWeekId, setSelectedWeekId] = useState("");

  const {
    data: resultGamesWeeks,
    error: resultGamesWeeksError,
    isLoading: isResultGameWeeksLoading,
    isSuccess: isResultGameWeeksSuccess,
    refetch: fetchGameWeek,
  } = useGetLatestResultWeekQuery();

  const {
    data: gameResults,
    error: gameResultsError,
    isLoading: isGameResultsLoading,
    isSuccess: isGameResultSuccess,
    refetch: fetchGameResultByWeek,
  } = useGetWeekResultWinnersQuery(
    { gameId: selectedWeekId },
    {
      skip: !selectedWeekId,
    }
  );

  const flatListRefWinner = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGameWeek();
        if (resultGamesWeeks && isResultGameWeeksSuccess) {
          setSelectedWeekId(resultGamesWeeks![0].id!.toString());
        }
      } catch (error) {
        console.log("Error fetching game data:", error);
      }
    };

    fetchData();
  }, [resultGamesWeeks]);

  // Call the game result API when a week ID is selected
  // useEffect(() => {
  //   if (selectedWeekId) {
  //     fetchGameResultByWeek();
  //   }
  // }, [selectedWeekId, fetchGameResultByWeek]);

  const handleWeekSelection = (weekId: string) => {
    setSelectedWeekId(weekId);
  };

  if (!isResultGameWeeksSuccess && !isGameResultSuccess) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  const data = gameResults?.map((item) => {
    return {
      key: item.result_number_type,
      result_number_type: item.result_number_type,
      result_number: item.result_number.split("").map((char) => parseInt(char)),
      data: item.winners,
    };
  });

  return (
    <View style={{ flex: 1, backgroundColor: primaryBlackColor }}>
      <View style={styles.activeGameContainer}>
        {resultGamesWeeks && isResultGameWeeksSuccess && (
          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={resultGamesWeeks}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item, index }) => {
              const formattedDate = moment(item.date).format("DD MMM (ddd)");

              if (selectedWeekId === resultGamesWeeks[index]?.id.toString()) {
                return (
                  <PrimaryGradient style={styles.itemSelectedActiveGame}>
                    <ThemeText
                      weight="medium"
                      style={styles.itemActiveGameText}
                    >
                      {formattedDate}
                    </ThemeText>
                  </PrimaryGradient>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() =>
                      handleWeekSelection(
                        resultGamesWeeks[index]?.id.toString()
                      )
                    }
                    style={[
                      styles.itemActiveGame,
                      selectedWeekId ===
                        resultGamesWeeks[index]?.id.toString() &&
                        styles.itemActiveGame,
                    ]}
                  >
                    <Text style={styles.itemActiveGameText}>
                      {formattedDate}
                    </Text>
                  </TouchableOpacity>
                );
              }
            }}
          />
        )}
      </View>

      {isGameResultSuccess && data ? (
        <SectionList
          contentContainerStyle={{ paddingBottom: 36 }}
          sections={data}
          renderItem={({ section: { data }, item, index }) => {
            return (
              <WinnerListItem
                winner={item}
                index={index}
                totalItem={data.length}
              />
            );
          }}
          renderSectionHeader={({
            section: { result_number_type, result_number },
          }) => {
            return (
              <View style={styles.container}>
                <WinnerHeaderSvg />
                <View style={styles.winNumberContainer}>
                  <WinNumberInHeader
                    rnPaddingHorizontal={4}
                    rnPaddingVertical={8}
                    text={result_number_type}
                    numbers={result_number}
                  />
                </View>
              </View>
            );
          }}
        />
      ) : (
        <Text>No Winner</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  safeareaContainer: {
    flex: 1,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
  },
  activeGameContainer: {
    height: 32,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
  },
  itemActiveGame: {
    borderWidth: 1,
    borderColor: activeGameBorder,
    borderRadius: 6,
    padding: 7,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  itemSelectedActiveGame: {
    borderRadius: 6,
    padding: 7,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  itemActiveGameText: {
    textAlign: "center",
    color: "white",
    fontSize: 11,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 36,
  },
  winNumberContainer: {
    position: "absolute",
    textAlign: "center",
  },
});

export default WinnerScreen;
