import { accentColor } from "@/constants/ColorConstants";
import { Nunito_600SemiBold } from "@/constants/fonts";
import React, { forwardRef } from "react";
import { StyleSheet, TextInput, TextInputProps } from "react-native";

export type InputProps = {} & TextInputProps;

const Input = forwardRef<TextInput, InputProps>(({ style, ...props }, ref) => {
  return (
    <TextInput
      style={[styles.input, style]}
      ref={ref}
      {...props}
      placeholderTextColor="rgba(255, 255, 255, 0.5)"
    />
  );
});

const styles = StyleSheet.create({
  input: {
    borderRadius: 8,
    backgroundColor: accentColor,
    paddingHorizontal: 16,
    paddingVertical: 15,
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
    color: "white",
    fontSize: 16,
    borderWidth: 1,
    borderColor: accentColor,
  },
});

export default Input;
