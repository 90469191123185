import ThemeText from "@/components/ThemeText";
import { linearColorPink, linearColorPurple } from "@/constants/ColorConstants";
import React from "react";
import type { TextProps, ViewProps } from "react-native";
import { Pressable, TouchableOpacity } from "react-native";
import LinearGradient from "react-native-linear-gradient";

type PrimaryButtonProps = {
  buttonStyle?: ViewProps["style"];
  title: string;
  titleStyle?: TextProps["style"];
} & Omit<TouchableOpacity["props"], "style">;

export default function PrimaryButton({
  title,
  titleStyle,
  buttonStyle,
  disabled,
  ...props
}: PrimaryButtonProps) {
  return (
    <Pressable
      style={({ pressed }) => [
        {
          height: 52,
        },
        pressed && { opacity: 0.65 },
        buttonStyle,
      ]}
      disabled={disabled}
      {...props}
    >
      <LinearGradient
        colors={[
          disabled ? "#4F4A56" : linearColorPurple,
          disabled ? "#4F4A56" : linearColorPink,
        ]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        useAngle={true}
        angle={98.3}
        style={[
          {
            borderRadius: 8,
            flex: 1,
            // alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 8,
          },
        ]}
      >
        <ThemeText
          style={[
            {
              fontSize: 16,
              // fontWeight: "bold",
              textAlign: "center",
              color: disabled ? "rgba(255, 255, 255, 0.6)" : "white",
            },
            titleStyle,
          ]}
        >
          {title}
        </ThemeText>
      </LinearGradient>
    </Pressable>
  );
}
