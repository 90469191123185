import { ErrorState } from "@/store/error";
import { RootState } from "@/store/store";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function useOnErrorDismiss(
  callback: (error: ErrorState["error"]) => void
) {
  const error = useSelector((state: RootState) => state.error.error);
  const previousError = useRef<ErrorState["error"]>(null);

  useEffect(() => {
    if (previousError.current != null && error == null) {
      callback(previousError.current);
    }

    if (error != null) {
      previousError.current = {
        ...error,
      };
    } else {
      previousError.current = null;
    }
  }, [error]);
}
