import React from "react";
import { StyleSheet, Text, View } from "react-native";
import ThisWeekWInnerLabelSvg from "../../../../components/svgs/home/ThisWeekWinnerLabelSvg";
import ThemeText from "@/components/ThemeText";

interface Props {
  text: string;
}

const WinnerLabel: React.FC<Props> = ({ text }) => {
  return (
    <View style={styles.container}>
      <ThisWeekWInnerLabelSvg />
      <ThemeText weight="bold" style={styles.text}>
        {text}
      </ThemeText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },

  text: {
    color: "white",
    position: "absolute",
    textAlign: "center",
    fontSize: 13,
  },
});

export default WinnerLabel;
