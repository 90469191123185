import OutlineButton from "@/components/OutlineButton";
import i18n, { t } from "@/lang/i18n";
import { BUY_FLOW_SCREEN } from "@/routes/Routes";
import BetListItem from "@/screens/home/buy/components/BetListItem";
import { selectBetItemIds } from "@/store/bet";
import { useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import React from "react";
import { useSelector } from "react-redux";

function AddMoreBetButton() {
  const navigation = useNavigation();

  function handleAddNumber() {
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(BUY_FLOW_SCREEN);
  }

  return (
    <OutlineButton
      buttonStyle={{ height: 40 }}
      title={i18n.t("bet_add_more_number")}
      onPress={handleAddNumber}
    />
  );
}

export default function BetList() {
  const totalBetItems = useSelector(selectBetItemIds);

  const renderItem = ({ item }: { item: string }) => {
    return <BetListItem id={item} />;
  };

  return (
    <FlashList
      // removeClippedSubviews={false}
      keyboardShouldPersistTaps="handled"
      data={totalBetItems}
      renderItem={renderItem}
      keyExtractor={(item) => item.toString()}
      estimatedItemSize={70.5}
      ListFooterComponent={AddMoreBetButton}
    />
  );

  // return (
  //   <ScrollView>
  //     {totalBetItems.map((item) => {
  //       return <BetListItem id={item} key={item} />;
  //     })}
  //   </ScrollView>
  // );
}
