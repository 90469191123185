import { View, Text, StyleSheet, Image } from "react-native";
import React from "react";
import { Winner } from "@/model/response/result_winner_model";

export default function WinnerListItem({
  winner,
  index,
  totalItem,
}: {
  winner: Winner;
  index: number;
  totalItem: number;
}) {
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const isFirst = index === 0;
  const isLast = index === totalItem - 1;

  const borderRadius = 8;

  return (
    <View
      style={[
        styles.container,
        isFirst && {
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        },
        isLast && {
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
        },
      ]}
    >
      <View style={styles.rowContainer}>
        <View style={styles.userContainer}>
          <Image
            style={styles.profileImage}
            source={{ uri: winner.profile_image }}
          />
          <Text style={styles.userName}>{winner.full_name}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.amount}>
            {moneyFormatter.format(winner.win_amount)}
          </Text>
        </View>
      </View>
      {!isLast && <View style={styles.underline} />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    backgroundColor: "#282031",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  userContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  userName: {
    fontSize: 16,
    color: "white",
  },
  textContainer: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  amount: {
    fontSize: 16,
    color: "white",
  },
  underline: {
    borderBottomWidth: 1,
    borderBottomColor: "#534D5A",
    marginHorizontal: 20,
  },
});
