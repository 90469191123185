import Input, { InputProps } from "@/components/Input";
import ThemeText from "@/components/ThemeText";
import React, { ForwardedRef, PropsWithChildren } from "react";
import {
  Controller,
  ControllerProps,
  FieldValues,
  RefCallBack,
} from "react-hook-form";
import { StyleSheet, TextInputProps, View } from "react-native";
import { TextInput } from "react-native-gesture-handler";

type EditTextProps<T extends FieldValues> = {
  Component?: React.ComponentType<TextInputProps & { ref: ForwardedRef<any> }>;
} & Pick<ControllerProps<T>, "control" | "name"> &
  InputProps &
  PropsWithChildren;

export default function EditText<T extends FieldValues>({
  Component = Input,
  control,
  name,
  children,
  ...props
}: EditTextProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
        <View style={styles.container}>
          <Component
            ref={ref}
            value={value}
            onChangeText={onChange}
            {...props}
            style={[props.style, !!error && { borderColor: "#ff0000" }]}
           
          />
          {children}
          {error?.message && (
            <ThemeText style={styles.error}>{error.message}</ThemeText>
          )}
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  error: {
    color: "#ff0000",
    fontSize: 14,
    marginTop: 5,
  },
});
