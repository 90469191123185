import ThemeText from "@/components/ThemeText";
import * as React from "react";
import { StyleSheet, View } from "react-native";

function MaintenanceScreen() {
  return (
    <View style={styles.container}>
      <ThemeText style={{ fontSize: 26, textAlign: "center" }}>
        The App is under maintenance{"\n"}We will let you know when it is done
        {"\n\n"}Thanks For Your Patience
      </ThemeText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginVertical: 20,
    marginHorizontal: 20,
    flex: 1,
    alignItems: "center",
  },
});

export default MaintenanceScreen;
