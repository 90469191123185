import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const ThisWeekWInnerLabelSvg = (props : any) => (
  <Svg
    // width={235}
    width={235}
    height={32}
    viewBox="0 0 235 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M235 32L206 3L208.544 32H235Z"
      fill="url(#paint0_linear_455_1030)"
    />
    <Path
      d="M-1.90735e-06 32L29 3L26.4561 32H-1.90735e-06Z"
      fill="url(#paint1_linear_455_1030)"
    />
    <Path
      d="M35.3519 0.5H199.648C203.803 0.5 207.476 3.20037 208.716 7.16639L216.32 31.5H18.6801L26.2844 7.16639C27.5237 3.20037 31.1968 0.5 35.3519 0.5Z"
      fill="#0E0815"
      stroke="url(#paint2_linear_455_1030)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_455_1030"
        x1={212.546}
        y1={-13.3125}
        x2={226.805}
        y2={-11.233}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_455_1030"
        x1={22.4543}
        y1={-13.3125}
        x2={8.19518}
        y2={-11.233}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_455_1030"
        x1={68.4029}
        y1={-18}
        x2={122.373}
        y2={26.0264}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8352EB" />
        <Stop offset={1} stopColor="#C352EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default ThisWeekWInnerLabelSvg;
