import ThemeText from "@/components/ThemeText";
import AnnouncementLogo from "@/components/svgs/profile/AnnouncementLogo";
import LuckyDrawLogo from "@/components/svgs/profile/LuckdrawHistoryLogo";
import Trophy from "@/components/svgs/profile/Trophy";
import WalletLogo from "@/components/svgs/profile/WalletLogo";
import { primaryBlackColor } from "@/constants/ColorConstants";
import { tagsStyles } from "@/constants/html";
import { INotification } from "@/model/response/notification_model";
import { formatDateTime } from "@/utils/format";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import RenderHTML from "react-native-render-html";

export default function NotificationDetailModel({
  notification,
  onDismiss,
}: {
  notification: INotification | null | undefined;
  onDismiss: () => void;
}) {
  const { width } = useWindowDimensions();

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case "announcement":
        return <AnnouncementLogo />; // Replace with actual SVG component
      case "top_up":
        return <WalletLogo />; // Replace with actual SVG component
      case "withdraw":
        return <WalletLogo />; // Replace with actual SVG component
      case "game_result":
        return <LuckyDrawLogo />; // Replace with actual SVG component
      case "win":
        return <Trophy />; // Replace with actual SVG component
      default:
        return null;
    }
  };

  return (
    <Modal
      animationType="slide"
      visible={!!notification}
      onRequestClose={onDismiss}
    >
      <View style={styles.container}>
        {notification && (
          <View
            style={{
              flex: 1,
              paddingHorizontal: 20,
              marginTop: 16,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              {getCategoryIcon(notification.notification_category)}
              <View style={{ marginLeft: 8, marginRight: 20, flex: 1 }}>
                <ThemeText>{notification.title}</ThemeText>
                <ThemeText style={styles.date}>
                  {formatDateTime(notification.created_at)}
                </ThemeText>
              </View>

              <Pressable
                onPress={onDismiss}
                style={({ pressed }) => [
                  styles.closeButton,
                  pressed && { opacity: 0.65 },
                ]}
              >
                <Ionicons name="close-outline" size={22} color="white" />
              </Pressable>
            </View>

            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{ paddingBottom: 24 }}
            >
              {notification.body && (
                <RenderHTML
                  tagsStyles={tagsStyles}
                  contentWidth={width - 40}
                  source={{ html: notification.body }}
                />
              )}
            </ScrollView>
          </View>
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // flex: 1,
    flexDirection: "column",
    backgroundColor: primaryBlackColor,
  },
  closeButton: {
    padding: 8,
    marginLeft: "auto",
    alignSelf: "flex-start",
  },
  date: {
    fontSize: 11,
    color: "#9F9CA1",
    marginTop: 4,
  },
});
