import AsyncStorage from "@react-native-async-storage/async-storage";

const AUTH_TOKEN_KEY = "token";

const APP_LATEST_UPDATED_TIME = "@app_latest_updated_time";

export const saveAuthToken = async (token: string | null) => {
  if (!token) {
    return await AsyncStorage.removeItem(AUTH_TOKEN_KEY);
  }
  await AsyncStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const getAuthToken = async () => {
  const token = await AsyncStorage.getItem(AUTH_TOKEN_KEY);
  return token;
};

export const saveAppLatestUpdatedTime = async (time: number) => {
  AsyncStorage.setItem(APP_LATEST_UPDATED_TIME, time.toString());
};

export const getAppLatestUpdatedTime = async () => {
  const time = await AsyncStorage.getItem(APP_LATEST_UPDATED_TIME);
  return time ? parseInt(time) : 0;
};
