import GradientOutlineButton from "@/components/GradientOutlineButton";
import { linearColorPurple } from "@/constants/ColorConstants";
import useKeyboardShow from "@/hooks/useKeyboardShow";
import i18n, { t } from "@/lang/i18n";
import BetList from "@/screens/home/buy/components/BetList";
import BuyButton from "@/screens/home/buy/components/BuyButton";
import CartHeader from "@/screens/home/buy/components/CartHeader";
import TotalBetAmount from "@/screens/home/buy/components/TotalBetAmount";
import { clearBetData } from "@/store/bet";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";

export default function BuySaveScreen() {
  const dispatch = useDispatch();
  const { keyboardVisible } = useKeyboardShow();
  return (
    <>
      <View
        style={{
          height: 1.5,
          backgroundColor: "#3E3944",
          marginHorizontal: 20,
        }}
      />
      <View style={[styles.container, styles.margin, styles.box]}>
        {/* First container */}

        <CartHeader />
        {/* <View style={styles.row}>
          <GameInfo />
          <BetUserBalance />
        </View> */}

        <View
          style={{
            height: 1.5,
            backgroundColor: "#3E3944",
            marginTop: 4,
            // marginBottom: 16,
          }}
        />

        <View style={[styles.box, styles.middleBox]}>
          {keyboardVisible ? null : (
            <GradientOutlineButton
              buttonStyle={{
                alignSelf: "flex-start",
                flexShrink: 0,
                marginTop: 4,
                marginBottom: 16,
                height: 36,
              }}
              titleStyle={{
                fontSize: 12,
              }}
              title={i18n.t("clear_all")}
              onPress={() => {
                dispatch(clearBetData());
              }}
            />
          )}

          <BetList />
        </View>

        {keyboardVisible ? null : (
          <View style={[styles.bottomBox]}>
            {/* Third container */}
            <TotalBetAmount />
            <BuyButton style={{ marginTop: 8 }} />
          </View>
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  box: {
    marginVertical: 8,
  },
  margin: {
    marginHorizontal: 20,
  },
  middleBox: {
    flex: 2,
  },
  row: {
    flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "center",
  },
  bottomBox: {
    marginTop: 8,
    marginBottom: 16,
  },
  errorBorder: {
    borderColor: "red",
  },
  errorText: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  clearAllBtn: {
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderColor: linearColorPurple,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 6,
    flexShrink: 0,
    marginTop: 4,
    marginBottom: 16,
  },
  btnItemText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
  },
});
