import { useUpdateAvatarMutation } from "@/api/apiSlice";
import { accentColor } from "@/constants/ColorConstants";
import { AvatarModel } from "@/model/response/avatar_model";
import { setLoading } from "@/store/loading";
import { useNavigation } from "@react-navigation/native";
import React, { memo, useEffect } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";

interface Props {
  avatar: AvatarModel;
  level: number;
}

const AvatarCard: React.FC<Props> = memo(({ avatar, level }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [updateAvatar, { isLoading: isAvatarUpdating }] =
    useUpdateAvatarMutation();

  const handleSelectAvatar = async () => {
    try {
      await updateAvatar({ profile_image: avatar.id }).unwrap();
    } catch (err: any) {
      // console.log(JSON.stringify(err, null, 2));
    }
    // navigation.dispatch(StackActions.replace(PROFILE));
    navigation.goBack();
  };

  useEffect(() => {
    dispatch(setLoading(isAvatarUpdating));
  }, [isAvatarUpdating]);

  const disabled = avatar.level_id > level;

  return (
    <TouchableOpacity
      style={{ width: "33.33%" }}
      disabled={disabled}
      onPress={() => handleSelectAvatar()}
    >
      <View style={[styles.item]}>
        <Image
          source={{ uri: avatar.image_url }}
          style={[styles.image, disabled && styles.disabled]}
        />
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  item: {
    margin: 3,
    borderRadius: 8,
    borderColor: accentColor,
    borderWidth: 2,
    padding: 5,
    overflow: "hidden",
    aspectRatio: 1,
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    marginBottom: 16,
    alignItems: "center",
  },
  disabled: {
    opacity: 0.5,
  },
});

export default AvatarCard;
