import { selectGameIds } from "@/store/bet";
import React from "react";
import { FlatList, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import ActiveGameListItem from "./ActiveGameListItem";

export default function ActiveGameList() {
  const games = useSelector(selectGameIds);

  const renderItem = ({ item, index }: { item: number; index: number }) => {
    return <ActiveGameListItem gameId={item} />;
  };

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      horizontal
      data={games}
      keyExtractor={(item) => item.toString()}
      showsHorizontalScrollIndicator={false}
      renderItem={renderItem}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 0,
    // height: 32,
  },
  contentContainer: {
    marginHorizontal: 20,
    marginTop: 24,
    height: 32,
  },
});
