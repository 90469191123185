import { linearColorPink, linearColorPurple } from "@/constants/ColorConstants";
import React, { PropsWithChildren } from "react";
import LinearGradient from "react-native-linear-gradient";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";

export type PrimaryGradientProps = {
  style?: ViewProps["style"];
} & PropsWithChildren;

export default function PrimaryGradient({
  style,
  children,
}: PrimaryGradientProps) {
  return (
    <LinearGradient
      colors={[linearColorPurple, linearColorPink]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      useAngle={true}
      angle={98.3}
      style={style}
    >
      {children}
    </LinearGradient>
  );
}
