import React from "react";
import { View, Text, StyleSheet } from "react-native";
import RectangleNumber from "../../../components/RectangleNumber";
import { accentColor } from "../../../constants/ColorConstants";
import WinRectangleNumber from "../../../components/svgs/winner/WinRectangleNumber";
import ThemeText from "@/components/ThemeText";

interface WinNumberHeaderProps {
  rnPaddingHorizontal: number;
  rnPaddingVertical: number;
  text: string;
  numbers: number[];
}

const WinNumberInHeader: React.FC<WinNumberHeaderProps> = ({
  rnPaddingHorizontal,
  rnPaddingVertical,
  text,
  numbers,
}) => {
  let displayText = "";
  if (text === "first") {
    displayText = "1ST PRIZE";
  } else if (text === "second") {
    displayText = "2ND PRIZE";
  } else if (text === "third") {
    displayText = "3RD PRIZE";
  } else if (text === "round") {
    displayText = "ROUND PRIZE";
  } else if (text === "con") {
    displayText = "CONSOLATION PRIZE";
  } else if (text === "starter") {
    displayText = "STARTER PRIZE";
  }

  return (
    <View style={styles.rectContainer}>
      <View style={styles.textContainer}>
        <ThemeText
          weight="bold"
          style={[styles.text, numbers.length === 0 && { textAlign: "center" }]}
        >
          {displayText}
        </ThemeText>
      </View>
      {numbers.length > 0 && (
        <View style={styles.numberContainer}>
          <View style={styles.row}>
            {numbers.map((singleNumber, index) => {
              return (
                <WinRectangleNumber
                  key={index}
                  paddingVertical={rnPaddingVertical}
                  paddingHorizontal={rnPaddingVertical}
                  number={singleNumber}
                />
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  rectContainer: {
    width: 220,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 9,
  },
  textContainer: {
    flex: 1,
    marginLeft: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: "700",
    color: "white",
  },
  numberContainer: {
    flex: 1,
    marginRight: 4,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default WinNumberInHeader;
