import { useRegisterDeviceTokenMutation } from "@/api/apiSlice";
import { selectAuthToken } from "@/store/auth/auth_reducer";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { useEffect, useRef } from "react";
import { Platform } from "react-native";
import { useSelector } from "react-redux";

async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      return;
    }
    token = (await Notifications.getDevicePushTokenAsync()).data;
  } else {
  }

  return token;
}

export default function useRegisterNotification() {
  const authToken = useSelector(selectAuthToken);
  const notificationListener = useRef<Notifications.Subscription | null>(null);
  const [deviceRegister] = useRegisterDeviceTokenMutation();
  useEffect(() => {
    if (authToken) {
      registerForPushNotificationsAsync()
        .then((token) => {
          if (!token) return;
          return deviceRegister({
            token: token,
            token_type: "fcm",
            os_type: null,
            device_name: null,
            device_id: null,
          });
        })
        .then(() => {});

      notificationListener.current !=
        Notifications.addNotificationReceivedListener((notification) => {
          // setNotification(notification);
        });
    }

    // responseListener!.current! = Notifications.addNotificationResponseReceivedListener(response => {
    //   console.log(response);
    // });

    return () => {
      notificationListener!.current?.remove();
    };
  }, [authToken]);
}
