import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import ThemeText from "@/components/ThemeText";
import FirstWinnerStar from "@/components/svgs/home/FirstWinnerStar";
import SecondWinnerStar from "@/components/svgs/home/SecondWinnerStar";
import ThirdWinnerStar from "@/components/svgs/home/ThirdWinnerStar";
import {
  linearColorPurple,
  viberFloatingBgPink,
} from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { AllWinnerModel } from "@/model/response/winner_model";
import { ITabScreenProps } from "@/routes/AppRoute";
import { DASHBOARD_SCREEN, WINNER_SCREEN } from "@/routes/Routes";
import { formatMoney } from "@/utils/format";
import { useNavigation } from "@react-navigation/native";
import { Image } from "react-native";
import LinearGradient from "react-native-linear-gradient";

type WinnerProps = {
  topWinners: AllWinnerModel;
};

const WinnerCard: React.FC<WinnerProps> = ({ topWinners }) => {
  const navigation =
    useNavigation<ITabScreenProps<typeof DASHBOARD_SCREEN>["navigation"]>();
  return (
    <LinearGradient
      colors={[viberFloatingBgPink, linearColorPurple]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      locations={[0.2714, 0.7286]}
      useAngle={true}
      angle={69.57}
      style={styles.container}
    >
      <View
        style={{
          marginBottom: 24,
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View style={styles.winnerContainer}>
          <View style={styles.winnerAvatar}>
            {topWinners.data[1].profile_image && (
              <Image
                style={styles.avatarImage}
                source={{ uri: topWinners.data[1].profile_image }}
              />
            )}
          </View>
          <View style={styles.star}>
            <SecondWinnerStar />
          </View>
          <ThemeText style={styles.winAmount} weight="bold">
            {formatMoney(topWinners.data[1].win_amount)}
          </ThemeText>
        </View>

        <View style={[styles.winnerContainer, styles.firstWinner]}>
          <View style={styles.winnerAvatar}>
            {topWinners.data[0].profile_image && (
              <Image
                style={styles.avatarImage}
                source={{ uri: topWinners.data[0].profile_image }}
              />
            )}
          </View>
          <View style={[styles.star, styles.firstStar]}>
            <FirstWinnerStar />
          </View>
          <ThemeText
            style={[styles.winAmount, styles.firstWinAmount]}
            weight="bold"
          >
            {formatMoney(topWinners.data[0].win_amount)}
          </ThemeText>
        </View>

        <View style={styles.winnerContainer}>
          <View style={styles.winnerAvatar}>
            {topWinners.data[2].profile_image && (
              <Image
                style={styles.avatarImage}
                source={{ uri: topWinners.data[2].profile_image }}
              />
            )}
          </View>
          <View style={[styles.star]}>
            <ThirdWinnerStar />
          </View>
          <ThemeText style={styles.winAmount} weight="bold">
            {formatMoney(topWinners.data[2].win_amount)}
          </ThemeText>
        </View>
      </View>
      <Pressable
        onPress={() => {
          navigation.navigate(WINNER_SCREEN);
        }}
        style={({ pressed }) => [
          styles.viewAllButton,
          pressed && { opacity: 0.65 },
        ]}
      >
        <ThemeText style={{ color: "black", fontSize: 14 }} weight="bold">
          {i18n.t("view_all_winner")}
        </ThemeText>
      </Pressable>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    padding: 24,
    marginHorizontal: 20,
  },
  viewAllButton: {
    height: 52,
    backgroundColor: "white",
    borderRadius: 8,
    elevation: 3,
    shadowColor: "#000000",
    alignItems: "center",
    justifyContent: "center",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.25, // Adjust the opacity as needed
    shadowRadius: 12, // Adjust the radius as needed
  },
  winnerContainer: {
    width: 64,
    marginHorizontal: 16,
    alignItems: "center",
  },
  firstWinner: {
    width: 96,
  },
  winnerAvatar: {
    width: "100%",
    padding: 8,
    aspectRatio: 1,
    borderRadius: 999,
    borderWidth: 1,
    borderColor: "white",
    backgroundColor: "rgb(28,28,28)",
  },
  star: {
    marginTop: -14,
  },
  firstStar: {
    marginTop: -20.5,
  },
  winAmount: {
    fontSize: 14,
    marginTop: 4,
  },
  firstWinAmount: {
    fontSize: 16,
    marginTop: -4,
  },
  avatarImage: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: 999,
    resizeMode: "cover",
  },
});

export default WinnerCard;
