import * as React from "react";
import Svg, { Path } from "react-native-svg";
const ResultSvg = (props:any) => (
  <Svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M7.8717 10.2021V17.0623"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M12.5377 6.91895V17.0617"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.1285 13.8271V17.0622"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1857 2H7.81429C4.54762 2 2.5 4.31208 2.5 7.58516V16.4148C2.5 19.6879 4.5381 22 7.81429 22H17.1857C20.4619 22 22.5 19.6879 22.5 16.4148V7.58516C22.5 4.31208 20.4619 2 17.1857 2Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
export default ResultSvg;
