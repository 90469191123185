import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import uuid from "react-native-uuid";

export default async function upload(url: string, imageUrl: string) {
  console.log(Platform.OS);
  if (Platform.OS === "web") {
    await webUpload(url, imageUrl);
    return;
  }
  await nativeUpload(url, imageUrl);
}

async function webUpload(url: string, imageUrl: string) {
  const blob = await fetch(imageUrl).then((res) => res.blob());
  const fileName = uuid.v4().toString();
  const file = new File([blob], fileName, { type: blob.type });

  const response = await fetch(url, {
    method: "PUT",
    body: file,
  });

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }
}

async function nativeUpload(url: string, imageUrl: string) {
  await FileSystem.uploadAsync(url, imageUrl, {
    httpMethod: "PUT",
    uploadType: FileSystem.FileSystemUploadType.BINARY_CONTENT,
  });
}
