import { useRegisterMutation } from "@/api/apiSlice";
import AlertDialog from "@/components/AlertDialog";
import Cover from "@/components/Cover";
import EditText from "@/components/EditText";
import PhoneInput from "@/components/PhoneInput";
import PrimaryButton from "@/components/PrimaryButton";
import ThemeText from "@/components/ThemeText";
import { primaryBlackColor } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { RegisterRequestModel } from "@/model/request/rigister_request_model";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import {
  LOGIN_SCREEN,
  REGISTER_SCREEN,
  TERMS_AND_CONDITIONS,
} from "@/routes/Routes";
import { setLoading } from "@/store/loading";
import { Ionicons } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch } from "react-redux";

type PageProps = IRootStackScreenProps<typeof REGISTER_SCREEN>;

export default function RegisterScreen({ navigation }: PageProps) {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<RegisterRequestModel>({
    shouldFocusError: true,
  });

  //api
  const [register, { isLoading, isSuccess, data: registerResponse }] =
    useRegisterMutation();

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  const goToTermsAndCondition = async () => {
    navigation.navigate(TERMS_AND_CONDITIONS);
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
      >
        <View>
          <Cover>
            <View
              style={{
                marginHorizontal: 20,
                marginBottom: 16,
                flexDirection: "row",
              }}
            >
              <Pressable
                style={({ pressed }) => [
                  { paddingRight: 8, paddingVertical: 8 },
                  pressed && {
                    opacity: 0.65,
                  },
                ]}
              >
                <Ionicons
                  name="arrow-back"
                  size={24}
                  color="white"
                  onPress={() => navigation.goBack()}
                />
              </Pressable>
            </View>
          </Cover>
          <ThemeText
            style={{
              marginHorizontal: 20,
              fontSize: 28,
              position: "absolute",
              bottom: 0,
            }}
            weight="bold"
          >
            {i18n.t("register_title")}
          </ThemeText>
        </View>

        <View style={styles.container}>
          <EditText
            control={control}
            name="full_name"
            placeholder={i18n.t("full_name_placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
          />

          <EditText
            Component={PhoneInput}
            control={control}
            name="phone_number"
            placeholder={i18n.t("phone_number_placeholder")}
            keyboardType="numeric"
            autoCapitalize="none"
            autoCorrect={false}
          />

          <EditText
            control={control}
            name="password"
            placeholder={i18n.t("password_placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
          />

          {/* <EditText
            control={control}
            name="password_confirmation"
            placeholder={i18n.t("password_confirmation_placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry
          /> */}

          {/* <EditText
            control={control}
            name="referral_code"
            placeholder={i18n.t("referral_code_placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
          /> */}

          <TouchableOpacity
            style={{ marginBottom: 24 }}
            onPress={goToTermsAndCondition}
          >
            <Text style={styles.forgotPassword}>
              {i18n.t("terms_and_conditions_btn")}
            </Text>
          </TouchableOpacity>

          <PrimaryButton
            buttonStyle={{ marginTop: "auto" }}
            disabled={!isValid}
            title={i18n.t("register_btn")}
            onPress={handleSubmit((data) =>
              register({
                ...data,
                phone_number: `09${data.phone_number}`,
              })
            )}
          />

          <AlertDialog
            visible={isSuccess}
            title={i18n.t("success")}
            description={"Register Successfully"}
            actions={[
              {
                title: "OK",
                onPress: () => {
                  navigation.navigate(LOGIN_SCREEN);
                },
              },
            ]}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: primaryBlackColor,
  },
  loginTextContainer: {
    borderRadius: 8,
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: 20,
    alignSelf: "flex-start",
  },
  loginText: {
    color: "white",
    fontWeight: "700",
    fontSize: 28,
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 25,
    paddingBottom: 32,
  },
  forgotPassword: {
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    marginTop: 6,
    marginBottom: 10,
  },
});
