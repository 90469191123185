import { useLogoutMutation } from "@/api/apiSlice";
import CallLogo from "@/components/svgs/profile/CallLogo";
import LuckyDrawLogo from "@/components/svgs/profile/LuckdrawHistoryLogo";
import ProfileBackground from "@/components/svgs/profile/ProfileBackground";
import TranslationLogo from "@/components/svgs/profile/TranslationLogo";
import UserGroup from "@/components/svgs/profile/UserGroup";
import WalletLogo from "@/components/svgs/profile/WalletLogo";
import i18n, { t } from "@/lang/i18n";
import {
  ABOUT_US_SCREEN,
  AVATAR_SCREEN,
  BALANCE_HISTORY,
  BUY_ORDER_HISTORY,
  CHANGE_PASSWORD_SCREEN,
  CONTACT_US_SCREEN,
  PROFILE,
} from "@/routes/Routes";
import UserAvatar from "@/screens/profile/components/UserAvatar";
import {
  clearToken,
  selectIsFeature,
  selectProfile,
} from "@/store/auth/auth_reducer";
import { setFirstLogin } from "@/store/auth/term_and_condition_reducer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackActions, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ComingSoonPopup from "../widget/CommingSoonPopup";
import ModalLoading from "../widget/ModalLoading";
import ReferralItem from "./ReferralItem";
import LanguageSelectionPopup from "./components/LanguageSelectionPopup";
import LogoutMenuItem from "./components/LogoutItem";
import ProfileMenuItem from "./components/ProfileMenuItem";
import ThemeText from "@/components/ThemeText";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import PasswordIcon from "@/components/svgs/PasswordIcon";
import { APP_VERSION } from "@/constants/system";
import { primaryBlackColor } from "@/constants/ColorConstants";

const ProfileScreen: React.FC = () => {
  const navigation =
    useNavigation<IRootStackScreenProps<typeof PROFILE>["navigation"]>();
  const dispatch = useDispatch();

  //api
  const [logout] = useLogoutMutation();

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isLanguagePopupVisible, setIsLanguagePopupVisible] = useState(false);

  const profile = useSelector(selectProfile);
  const isFeature = useSelector(selectIsFeature);

  const handleChangeLanguageItemPress = async (language: string) => {
    // Handle language selection here
    i18n.locale = language;

    AsyncStorage.setItem("selected_lang", language);
    setIsLanguagePopupVisible(false);
  };
  const handleLuckyDrawHistoryItemPress = async () => {
    navigation.dispatch(StackActions.push(BUY_ORDER_HISTORY));
  };
  const handleBalanceHistoryItemPress = async () => {
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(BALANCE_HISTORY);
  };
  const handleContactUsItemPress = async () => {
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(CONTACT_US_SCREEN);
  };
  const handleAboutUsItemPress = async () => {
    // TODO: remove ts-ignore
    // @ts-ignore
    navigation.navigate(ABOUT_US_SCREEN);
  };

  const handleLogoutPress = async () => {
    setIsModalLoading(true);

    // const token = 'abc123'; // Replace with actual token obtained from authentication
    // dispatch(setToken(token));
    try {
      // const result = await logout().unwrap();

      setIsModalLoading(false);

      dispatch(clearToken());
      dispatch(setFirstLogin(true));

      // navigation.navigate(MAIN_ROUTE);
    } catch (err: any) {
      setIsModalLoading(false);
    }
  };

  const handleChooseAvatar = async () => {
    navigation.dispatch(StackActions.push(AVATAR_SCREEN));
  };
  const [isShowComingSoonPopup, setIsShowComingSoonPopup] = useState(false);

  return (
    <ScrollView
      contentContainerStyle={styles.scrollContainer}
      // contentInsetAdjustmentBehavior="always"
      style={styles.scrollView}
    >
      <View style={styles.container}>
        <View style={styles.profileContainer}>
          <ProfileBackground />
          <View style={styles.grayCircle} />

          <View style={styles.imageContainer}>
            <TouchableOpacity onPress={handleChooseAvatar}>
              <UserAvatar profile_image={profile?.profile_image} />
            </TouchableOpacity>
          </View>

          <View style={styles.textContainer}>
            <Text style={styles.welcomeText}>{i18n.t("welcome_back")}</Text>
            <Text style={styles.nameText}>{profile?.full_name ?? ""}</Text>
          </View>
        </View>

        <View style={[styles.itemContainer, { flexGrow: 2 }]}>
          <ProfileMenuItem
            icon={TranslationLogo}
            text={i18n.t("change_language")}
            onPress={() => setIsLanguagePopupVisible(true)}
          />
          <View style={styles.separator} />

          <ProfileMenuItem
            icon={PasswordIcon}
            text={i18n.t("change_password")}
            onPress={() => {
              navigation.navigate(CHANGE_PASSWORD_SCREEN);
            }}
          />
          <View style={styles.separator} />

          {!isFeature && (
            <>
              <ProfileMenuItem
                icon={LuckyDrawLogo}
                text={i18n.t("your_lucky_draw_history")}
                onPress={handleLuckyDrawHistoryItemPress}
              />
              <View style={styles.separator} />

              <ProfileMenuItem
                icon={WalletLogo}
                text={i18n.t("your_balance_history")}
                onPress={handleBalanceHistoryItemPress}
              />
              <View style={styles.separator} />
            </>
          )}

          <ProfileMenuItem
            icon={CallLogo}
            text={i18n.t("contact_us")}
            onPress={handleContactUsItemPress}
          />
          <View style={styles.separator} />

          <ProfileMenuItem
            icon={UserGroup}
            text={i18n.t("about_us")}
            onPress={handleAboutUsItemPress}
          />

          {!isFeature && (
            <>
              {/* <View style={{ marginTop: 20 }}></View> */}
              {/* <View style={styles.separator} /> */}
              {/* <ReferralItem text={profile?.referral_code ?? ""} /> */}
              {/* <View style={styles.separator} /> */}
            </>
          )}
          <View style={{ marginTop: 30 }}>
            <LogoutMenuItem
              icon={UserGroup}
              text={i18n.t("logout")}
              onPress={handleLogoutPress}
            />
          </View>
        </View>

        <View style={styles.versionTextContainer}>
          <ThemeText style={styles.versionText}>
            S4D V{APP_VERSION} All Rights Reserved
          </ThemeText>
        </View>
      </View>

      <LanguageSelectionPopup
        isVisible={isLanguagePopupVisible}
        onClose={() => setIsLanguagePopupVisible(false)}
        onSelectLanguage={handleChangeLanguageItemPress}
      />
      <ComingSoonPopup
        isVisible={isShowComingSoonPopup}
        onCancel={() => setIsShowComingSoonPopup(false)}
      />
      {/* <GeneralErrorPopup
                        isVisible={showErrorPopup}
                        onCancel={() => setShowErrorPopup(false)}
                        errorText="Logout Error"
                    /> */}

      <ModalLoading isLoading={isModalLoading} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    // flex: 1,
    flexGrow: 1,
    backgroundColor: primaryBlackColor,
  },
  container: {
    justifyContent: "flex-start",
    height: "100%",
    flexDirection: "column",
  },
  profileContainer: {
    alignItems: "center",
    marginTop: 45,
  },
  itemContainer: {
    marginTop: 22,
    marginLeft: 20,
    marginRight: 20,
  },
  imageContainer: {
    position: "absolute",
    top: -30,
    zIndex: 1,
  },
  grayCircle: {
    width: 65,
    height: 65,
    borderRadius: 100,
    backgroundColor: "#1D1D1D",
    position: "absolute",
    top: -35,
    zIndex: 0,
  },
  textContainer: {
    position: "absolute",
    top: 23,
    alignItems: "center",
    zIndex: 1,
  },
  welcomeText: {
    marginTop: 10,
    fontSize: 16,
    color: "white",
  },
  nameText: {
    fontSize: 15,
    fontWeight: "bold",
    color: "white",
  },
  versionText: {
    color: "white",
    fontSize: 11,
  },
  versionTextContainer: {
    marginTop: "auto",
    marginBottom: 16,
    alignItems: "center",
  },
  separator: {
    height: 1.5,
    backgroundColor: "#3E3944",
    marginBottom: 10,
    marginTop: 15,
  },
});

export default ProfileScreen;
