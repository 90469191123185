import * as React from "react";
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { useState } from "react";

import i18n, { t } from "@/lang/i18n";
import { selectProfileBalance } from "@/store/auth/auth_reducer";
import { formatDateTime, formatMoney } from "@/utils/format";
import { useFocusEffect } from "@react-navigation/native";
import { useSelector } from "react-redux";
import {
  useGetAllTransactionsQuery,
  useGetBalanceQuery,
} from "../../api/apiSlice";
import {
  activeGameBorder,
  linearColorPurple,
  primaryBlackColor,
  purpleText,
} from "../../constants/ColorConstants";
import TransactionItem from "../home/cash/components/TransactionItem";
import ThemeText from "@/components/ThemeText";

function BalanceHistory() {
  const balance = useSelector(selectProfileBalance);
  const [page, setPage] = useState(1);
  const {
    data: transactions,
    isFetching,
    isError: isTransactionsError,
    isLoading,
  } = useGetAllTransactionsQuery({ page: page });

  const { refetch: refreshBalance } = useGetBalanceQuery();

  // useEffect(() => {
  //   const fetchMeData = async () => {
  //     try {
  //       await fetchMe();
  //     } catch (error) {
  //       console.log("Error fetching me:", error);
  //     }
  //   };

  //   fetchMeData();
  // }, [me]);

  useFocusEffect(
    React.useCallback(() => {
      refreshBalance();
      setPage(1);
    }, [])
  );

  if (isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (isTransactionsError) {
    return <Text>Error occurred while fetching transactions. </Text>;
  }

  const handleLoadMoreTransactions = () => {
    if (page < (transactions?.meta.last_page || 0) && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <View style={styles.container}>
      <View style={[styles.row, { marginTop: 16 }]}>
        <View style={styles.columnBalance}>
          <ThemeText style={styles.textAvailableBalance}>
            {i18n.t("available_balance")}
          </ThemeText>
          {balance ? (
            <ThemeText weight="extra-bold" style={styles.textBalance}>
              {formatMoney(balance) + " Ks"}
            </ThemeText>
          ) : (
            <ThemeText weight="extra-bold" style={styles.textBalance}>
              0 Ks
            </ThemeText>
          )}
        </View>
      </View>
      <View style={styles.row}>
        <View
          style={{
            flex: 1,
            height: 1.5,
            backgroundColor: "#3E3944",
            marginTop: 10,
            marginBottom: 20,
          }}
        ></View>
      </View>

      {transactions ? (
        <FlatList
          data={transactions!.data || []}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index }) => {
            return (
              <TransactionItem
                name={item.name ? item.name : "Transaction"}
                amount={formatMoney(item.amount) + " Ks"}
                color={item.color!}
                date={formatDateTime(item.created_at)}
                status={item.status}
                type={item.type}
              />
            );
          }}
          onEndReached={handleLoadMoreTransactions}
          onEndReachedThreshold={0.5}
          ListFooterComponent={
            isFetching ? <ActivityIndicator size="large" /> : null
          }
        />
      ) : (
        <Text>No Transaction data</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    marginHorizontal: 20,
  },
  box: {
    marginVertical: 10,
  },
  margin: {
    marginLeft: 20,
    marginRight: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  columnSelectedValue: {
    flex: 1,

    justifyContent: "center",
    alignItems: "flex-start",
  },
  columnBalance: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  textBalance: {
    fontSize: 16,
    marginBottom: 5,
    color: purpleText,
  },
  textAvailableBalance: {
    fontSize: 13,
    marginBottom: 5,
    color: "white",
  },
  safeareaContainer: {
    flex: 1,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
  },
  tabContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
    borderColor: activeGameBorder,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  tabButton: {
    flex: 1,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    paddingVertical: 11,
    marginHorizontal: 2,
  },
  itemAdd: {
    paddingHorizontal: 33,
    paddingVertical: 6,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderColor: linearColorPurple,
    borderRadius: 8,
    marginHorizontal: 6,
    marginVertical: 3,
  },
  itemText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
});

export default BalanceHistory;
