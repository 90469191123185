import { tagsStyles } from "@/constants/html";
import { useFocusEffect } from "@react-navigation/native";
import * as React from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import RenderHtml from "react-native-render-html";
import { useAboutUsQuery } from "../../api/apiSlice";
import { primaryBlackColor } from "../../constants/ColorConstants";

const AboutUsScreen: React.FC = () => {
  const { width } = useWindowDimensions();

  const {
    data: aboutUsData,
    error: aboutUsError,
    isLoading: isAboutUsLoading,
    isSuccess: isAboutUsSuccess,
    refetch: fetchAboutUsGame,
  } = useAboutUsQuery();

  useFocusEffect(
    React.useCallback(() => {
      fetchAboutUsGame();
    }, [])
  );

  if (isAboutUsLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <ScrollView
      contentContainerStyle={styles.scrollContainer}
      style={styles.scrollView}
    >
      <View style={styles.container}>
        <RenderHtml
          contentWidth={width - 40}
          source={{ html: aboutUsData?.html! }}
          tagsStyles={tagsStyles}
        />

        <View style={{ padding: 12 }}></View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    marginHorizontal: 20,
  },

  termsText: {
    color: "white",
    fontSize: 16,
  },

  safeareaContainer: {
    flex: 1,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },

  button: {
    backgroundColor: "#8352EB",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
});

export default AboutUsScreen;
