import { useEffect, useState } from "react";
import { Keyboard, Platform } from "react-native";

export default function useKeyboardShow() {
  const [keyboardVisible, setKeyboardVisible] = useState(
    Platform.OS === "web" ? false : Keyboard.isVisible()
  );

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardVisible(true);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardVisible(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return {
    keyboardVisible,
  };
}
