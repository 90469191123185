import { View, Text } from "react-native";
import React from "react";
import ThemeText from "./ThemeText";
import LinearGradient from "react-native-linear-gradient";
import { primaryBlackColor } from "@/constants/ColorConstants";
import { TransactionStatus } from "@/model/response/transaction_model";

export default function StatusTag({ status }: { status: TransactionStatus }) {
  const colors = {
    pending: ["#DFB228", "#B99012"],
    success: ["#2BD832", "#0FB720"],
    rejected: ["#D8322B", "#B7200F"],
  };

  return (
    <LinearGradient
      colors={colors[status]}
      start={{ x: 0.14, y: 0.72 }}
      end={{ x: 0.86, y: 0.17 }}
      useAngle={true}
      angle={98.3}
      style={{
        borderRadius: 9999,
        overflow: "hidden",
        flexShrink: 0,
        padding: 1,
      }}
    >
      <View
        style={{
          backgroundColor: primaryBlackColor,
          // margin: 1,
          borderRadius: 9999,
          // flex: 1,
        }}
      >
        <ThemeText
          style={{
            fontSize: 9,
            textAlign: "center",
            paddingHorizontal: 6,
            paddingVertical: 2,
          }}
        >
          {status.toUpperCase()}
        </ThemeText>
      </View>
    </LinearGradient>
  );
}
