import { useLazyGetDownloadLinkQuery, useLoginMutation } from "@/api/apiSlice";
import PrimaryButton from "@/components/PrimaryButton";
import { primaryBlackColor } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { LOGIN_SCREEN, REGISTER_SCREEN } from "@/routes/Routes";
import { setLoading } from "@/store/loading";
import { AppDispatch } from "@/store/store";
import { openViber } from "@/store/viber";
import React, { useEffect } from "react";

import Cover from "@/components/Cover";
import EditText from "@/components/EditText";
import ThemeText from "@/components/ThemeText";
import LogoSvg from "@/components/svgs/auth/LogoSvg";
import { LoginRequestModel } from "@/model/request/login_request_model";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import { setToken } from "@/store/auth/auth_reducer";
import { setFirstLogin } from "@/store/auth/term_and_condition_reducer";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Image,
  KeyboardAvoidingView,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch } from "react-redux";
import { z } from "zod";
import PhoneInput from "@/components/PhoneInput";
import AlertDialog from "@/components/AlertDialog";
import useDisableGlobalError from "@/hooks/useDisableGlobalError";

const scheme = z.object({
  username: z
    .string({
      required_error: "PhoneNo is required",
      invalid_type_error: "PhoneNo must be a string",
    })
    .trim()
    .min(7, "PhoneNo must be at least 7 characters long"),
  password: z
    .string({
      required_error: "Password is required",
    })
    .trim()
    .min(6, "Password must be at least 6 characters long"),
});

type PageProps = IRootStackScreenProps<typeof LOGIN_SCREEN>;

export default function LoginScreen({ navigation }: PageProps) {
  useDisableGlobalError();
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginRequestModel>({
    mode: "onChange",
    shouldFocusError: true,
    resolver: zodResolver(scheme),
  });

  //api
  const [login, { isLoading, isSuccess, error, reset, data: loginResponse }] =
    useLoginMutation();

  const [getDownloadLink] = useLazyGetDownloadLinkQuery();

  useEffect(() => {
    if (!isSuccess || !loginResponse) return;

    dispatch(setToken(loginResponse.token));
    dispatch(setFirstLogin(true));
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  const handleOpenChat = async () => {
    dispatch(openViber());
  };

  const goToRegister = () => {
    navigation.navigate(REGISTER_SCREEN);
  };

  const onDownload = async () => {
    dispatch(setLoading(true));
    const { data: link } = await getDownloadLink();
    if (link) {
      Linking.openURL(link);
      dispatch(setLoading(false));
      return;
    }
    dispatch(setLoading(false));
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
        // contentInsetAdjustmentBehavior="always"
        style={styles.scrollView}
      >
        <Cover>
          <View style={styles.logoContainer}>
            <LogoSvg width={158.73015873015873} height={100} />
          </View>
        </Cover>
        <View style={styles.container}>
          <View style={styles.loginTextContainer}>
            <ThemeText style={{ marginBottom: 20, fontSize: 28 }} weight="bold">
              {i18n.t("login_title")}
            </ThemeText>
          </View>

          <EditText
            Component={PhoneInput}
            control={control}
            name="username"
            placeholder={i18n.t("phone_number_placeholder")}
            keyboardType="numeric"
            autoCapitalize="none"
            autoCorrect={false}
          />

          <EditText
            control={control}
            name="password"
            placeholder={i18n.t("password_placeholder")}
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry={true}
          />

          {/* <TouchableOpacity
            style={{ marginBottom: 16 }}
            onPress={() => handleOpenChat()}
          >
            <ThemeText style={styles.forgotPassword}>
              {i18n.t("forgot_password")}{" "}
              <ThemeText style={styles.clickHere}>
                {i18n.t("click_here")}
              </ThemeText>
            </ThemeText>
          </TouchableOpacity> */}

          <PrimaryButton
            buttonStyle={{ marginBottom: 24 }}
            disabled={!isValid}
            title={i18n.t("login_btn")}
            onPress={handleSubmit((data) =>
              login({
                ...data,
                username: `09${data.username}`,
              })
            )}
          />

          <View style={{ marginTop: "auto" }}>
            <View
              style={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <TouchableOpacity
                style={styles.createAccount}
                onPress={goToRegister}
              >
                {i18n.locale === "en" ? (
                  <Image
                    style={{
                      width: 82,
                      height: 8.5,
                      resizeMode: "contain",
                    }}
                    source={require("@assets/create_account_en.png")}
                  />
                ) : (
                  <Image
                    style={{
                      width: 85.08,
                      // width: 335 / 3,
                      height: 16,
                      resizeMode: "contain",
                    }}
                    source={require("@assets/create_account_my.png")}
                  />
                )}
              </TouchableOpacity>

              <TouchableOpacity
                // style={{ marginBottom: 16 }}
                style={styles.forgotPasswordButton}
                onPress={() => handleOpenChat()}
              >
                <ThemeText style={styles.forgotPassword}>
                  {i18n.t("forgot_password")}
                </ThemeText>
              </TouchableOpacity>
            </View>

            {Platform.OS === "web" && (
              <TouchableOpacity
                onPress={onDownload}
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                <ThemeText style={[styles.downloadAppButton, { marginTop: 0 }]}>
                  {i18n.t("download_app")}
                </ThemeText>
              </TouchableOpacity>
            )}
          </View>

          {/* <View style={{ margin: 50.5 }} /> */}

          <AlertDialog
            title={i18n.t("error")}
            dialogStyle={{ bottom: "15%", position: "absolute" }}
            description={
              error &&
              typeof error === "object" &&
              "data" in error &&
              typeof error.data === "object" &&
              error.data &&
              "message" in error.data &&
              typeof error.data.message === "string"
                ? error.data.message || "Unknown error"
                : "Unknown error"
            }
            visible={!!error}
            onDismiss={() => reset()}
            actions={[
              {
                title: "Close",
                onPress: () => reset(),
              },
            ]}
          />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    // flex: 1,
    flexGrow: 1,
    backgroundColor: primaryBlackColor,
  },
  logoContainer: {
    paddingTop: 32,
    paddingBottom: 48,
    flexDirection: "row",
    justifyContent: "center",
  },
  loginTextContainer: {
    borderRadius: 8,
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: 20,
    alignSelf: "flex-start",
  },
  loginText: {
    color: "white",
    fontWeight: "700",
    fontSize: 28,
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 32,
  },
  inputContainer: {
    marginBottom: 20,
  },
  passwordInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  forgotPasswordButton: {
    height: 40,
    justifyContent: "center",
  },
  forgotPassword: {
    color: "#fff",
    fontSize: 11,
    textAlign: "center",
    // verticalAlign: "middle",
  },
  clickHere: {
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  downloadAppButton: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // fontWeight: "bold",
    color: "#fff",
  },
  createAccount: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // marginBottom: 16,
    // fontWeight: "bold",
    color: "#fff",
  },
});
