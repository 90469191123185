import { setDisabled } from "@/store/error";
import { useFocusEffect } from "@react-navigation/native";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export default function useDisableGlobalError() {
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      dispatch(setDisabled(true));

      return () => {
        dispatch(setDisabled(false));
      };
    }, [])
  );
}
