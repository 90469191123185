import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { accentColor } from "../constants/ColorConstants";
import RectangleNumber from "./RectangleNumber";

interface WinNumberProps {
  rnPaddingHorizontal: number;
  rnPaddingVertical: number;
  text: string;
  numbers: number[];
}

const WinNumberPrize: React.FC<WinNumberProps> = ({
  rnPaddingHorizontal,
  rnPaddingVertical,
  text,
  numbers,
}) => {
  return (
    <View style={styles.rectContainer}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{text}</Text>
      </View>
      <View style={styles.numberContainer}>
        <View style={styles.row}>
          {numbers.map((singleNumber, index) => {
            return (
              <RectangleNumber
                key={index}
                // paddingVertical={rnPaddingVertical}
                // paddingHorizontal={rnPaddingVertical}
                number={singleNumber}
              />
            );
          })}

          {/* <RectangleNumber
          paddingVertical={8}
          paddingHorizontal={4}
          number={2}
        />
        <RectangleNumber
          paddingVertical={8}
          paddingHorizontal={4}
          number={3}
        />
        <RectangleNumber
          paddingVertical={8}
          paddingHorizontal={4}
          number={4}
        /> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rectContainer: {
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: accentColor,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 9,
  },
  textContainer: {
    flex: 1,
    marginLeft: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: "700",
    color: "white",
  },
  numberContainer: {
    flex: 1,
    marginRight: 4,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default WinNumberPrize;
