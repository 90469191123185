import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const ConfirmProcessSvg = (props: any) => (
  <Svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M68.7814 131.247C68.7814 126.07 64.5839 121.872 59.4062 121.872C54.2286 121.872 50.0312 126.07 50.0312 131.247H18.7812C15.3295 131.247 12.5312 128.449 12.5312 124.997V24.9971C12.5312 21.5453 15.3295 18.7471 18.7812 18.7471H50.0312C50.0312 23.9248 54.2286 28.1221 59.4062 28.1221C64.5839 28.1221 68.7814 23.9248 68.7814 18.7471H131.281C134.733 18.7471 137.531 21.5453 137.531 24.9971V59.3721C128.902 59.3721 121.906 66.3676 121.906 74.997C121.906 83.6264 128.902 90.622 137.531 90.622V124.997C137.531 128.449 134.733 131.247 131.281 131.247H68.7814ZM59.4062 65.622C64.5839 65.622 68.7814 61.4248 68.7814 56.2471C68.7814 51.0694 64.5839 46.8721 59.4062 46.8721C54.2286 46.8721 50.0312 51.0694 50.0312 56.2471C50.0312 61.4248 54.2286 65.622 59.4062 65.622ZM59.4062 103.122C64.5839 103.122 68.7814 98.9245 68.7814 93.747C68.7814 88.5695 64.5839 84.372 59.4062 84.372C54.2286 84.372 50.0312 88.5695 50.0312 93.747C50.0312 98.9245 54.2286 103.122 59.4062 103.122Z"
      fill="url(#paint0_linear_582_1503)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_582_1503"
        x1={75.0313}
        y1={18.7471}
        x2={75.0313}
        y2={131.247}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8652EB" />
        <Stop offset={1} stopColor="#BC52EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default ConfirmProcessSvg;
