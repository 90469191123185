import { View, Text, StyleSheet, Pressable } from "react-native";
import React from "react";
import PrimaryGradient from "@/components/PrimaryGradient";
import ThemeText from "@/components/ThemeText";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";

type PaymentMethodButtonProps = {
  selected: boolean;
  onPress: () => void;
  title: string;
  style?: ViewProps["style"];
};

export default function PaymentMethodButton({
  title,
  selected,
  onPress,
  style,
}: PaymentMethodButtonProps) {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) => [pressed && { opacity: 0.65 }, style]}
    >
      {selected ? (
        <PrimaryGradient style={styles.activeTabButton}>
          <ThemeText weight="bold" style={{ color: "white", fontSize: 16 }}>
            {title}
          </ThemeText>
        </PrimaryGradient>
      ) : (
        <ThemeText weight="bold" style={styles.inactiveTabButton}>
          {title}
        </ThemeText>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  activeTabButton: {
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  inactiveTabButton: {
    color: "white",
    fontSize: 16,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 15,
    textAlign: "center",
  },
});
