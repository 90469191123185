import i18n, { t } from "@/lang/i18n";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import CurrentBetNumber from "./CurrentBetNumber";
import {
  BackNumberPad,
  NumberPad,
  QNumberPad,
  RNumberPad,
  SNumberPad,
} from "./NumberPad";
import ThemeText from "@/components/ThemeText";

export default function BetNumberInput() {
  return (
    <View style={styles.container}>
      <View style={styles.chooseFourNumberContainer}>
        <ThemeText weight="medium" style={styles.chooseFourNumber}>
          {i18n.t("choose_four_numbers")}
        </ThemeText>
      </View>

      <CurrentBetNumber />

      <View style={styles.numpadContainer}>
        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((item) => (
          <NumberPad key={item} number={item} />
        ))}
        <SNumberPad />
        <RNumberPad />
        <QNumberPad />
        <BackNumberPad />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 0,
  },
  numpadContainer: {
    marginTop: 24,
    marginHorizontal: 20,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chooseFourNumber: {
    color: "white",
    fontSize: 13,
  },
  chooseFourNumberContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 24,
    marginTop: 24,
  },
});
