import * as React from "react";
import Svg, { Path, G, Defs } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const FirstWinnerStar = (props : any) => (
  <Svg
    width={48}
    height={41}
    viewBox="0 0 48 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M36.2681 25.4374C35.4855 24.9768 34.5146 24.9768 33.732 25.4374L30.5099 27.3338C29.3994 27.9874 28.0359 27.0288 28.2752 25.7625L29.0518 21.6531C29.2075 20.8288 28.9395 19.9814 28.338 19.3965L25.3344 16.476C24.4324 15.5988 24.946 14.0677 26.1946 13.9121L30.0777 13.428C30.9518 13.319 31.704 12.7581 32.0579 11.9515L33.6264 8.37593C34.1513 7.17938 35.8488 7.17939 36.3737 8.37594L37.9422 11.9515C38.296 12.7581 39.0482 13.319 39.9223 13.428L43.8055 13.9121C45.054 14.0677 45.5677 15.5988 44.6656 16.476L41.662 19.3965C41.0606 19.9814 40.7926 20.8288 40.9483 21.6531L41.7249 25.7625C41.9642 27.0288 40.6007 27.9874 39.4901 27.3337L36.2681 25.4374Z"
      fill="#E54780"
      stroke="white"
    />
    <Path
      d="M14.2681 25.4374C13.4855 24.9768 12.5146 24.9768 11.732 25.4374L8.50995 27.3338C7.39936 27.9874 6.0359 27.0288 6.27519 25.7625L7.05175 21.6531C7.20752 20.8288 6.93948 19.9814 6.33805 19.3965L3.33443 16.476C2.43236 15.5988 2.94601 14.0677 4.19455 13.9121L8.07775 13.428C8.9518 13.319 9.70402 12.7581 10.0579 11.9515L11.6264 8.37593C12.1513 7.17938 13.8488 7.17939 14.3737 8.37594L15.9422 11.9515C16.296 12.7581 17.0482 13.319 17.9223 13.428L21.8055 13.9121C23.054 14.0677 23.5677 15.5988 22.6656 16.476L19.662 19.3965C19.0606 19.9814 18.7926 20.8288 18.9483 21.6531L19.7249 25.7625C19.9642 27.0288 18.6007 27.9874 17.4901 27.3337L14.2681 25.4374Z"
      fill="#E54780"
      stroke="white"
    />
    <G filter="url(#filter0_d_452_443)">
      <Path
        d="M24.0145 28.4133C23.3884 28.0448 22.6117 28.0448 21.9856 28.4133L16.2914 31.7647C14.8106 32.6362 12.9927 31.358 13.3118 29.6697L14.6404 22.6385C14.7651 21.9791 14.5506 21.3011 14.0695 20.8332L8.98586 15.8902C7.7831 14.7207 8.46797 12.6792 10.1327 12.4716L16.8293 11.6368C17.5285 11.5496 18.1303 11.1009 18.4134 10.4556L21.1685 4.17507C21.8684 2.57967 24.1317 2.57968 24.8316 4.17508L27.5867 10.4556C27.8698 11.1009 28.4715 11.5496 29.1708 11.6368L35.8673 12.4716C37.5321 12.6792 38.2169 14.7206 37.0142 15.8902L31.9306 20.8332C31.4495 21.3011 31.2351 21.9791 31.3597 22.6385L32.6883 29.6697C33.0074 31.358 31.1895 32.6362 29.7087 31.7646L24.0145 28.4133Z"
        fill="#E54780"
      />
      <Path
        d="M24.2682 27.9824C23.4855 27.5218 22.5146 27.5218 21.732 27.9824L16.0378 31.3338C14.9272 31.9874 13.5638 31.0288 13.8031 29.7625L15.1318 22.7314C15.2875 21.9071 15.0195 21.0596 14.418 20.4748L9.33443 15.5317C8.43236 14.6546 8.94601 13.1234 10.1946 12.9678L16.8911 12.1329C17.7652 12.024 18.5174 11.4631 18.8712 10.6565L21.6264 4.37594C22.1513 3.17939 23.8488 3.17939 24.3737 4.37594L27.1288 10.6565C27.4827 11.4631 28.2349 12.024 29.1089 12.1329L35.8055 12.9678C37.054 13.1235 37.5677 14.6546 36.6656 15.5317L31.5821 20.4748C30.9806 21.0596 30.7126 21.9071 30.8684 22.7313L32.197 29.7625C32.4363 31.0288 31.0729 31.9874 29.9623 31.3337L24.2682 27.9824Z"
        stroke="white"
      />
    </G>
    <Path
      d="M19.9471 24V22.304H22.4911V15.056L20.5231 16.24L19.7231 14.736L23.0831 12.72H24.5391V22.304H26.9231V24H19.9471Z"
      fill="white"
    />
    <Defs></Defs>
  </Svg>
);
export default FirstWinnerStar;
