import ThemeText from "@/components/ThemeText";
import i18n, { t } from "@/lang/i18n";
import {
  dismissViberError,
  selectViberPhone,
  selectViberStatus,
} from "@/store/viber";
import React from "react";
import {
  ActivityIndicator,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

export default function ViberDialog() {
  const dispatch = useDispatch();
  const status = useSelector(selectViberStatus);
  const phoneNumber = useSelector(selectViberPhone);

  const visible = status == "pending" || status == "rejected";
  return (
    <Modal visible={visible} transparent>
      <View style={styles.centeredView}>
        {status == "pending" && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" />
          </View>
        )}

        {status == "rejected" && (
          <View style={styles.dialog}>
            <ThemeText style={styles.title}>
              {i18n.t("viber_dialog_title")}
            </ThemeText>
            <ThemeText style={styles.description}>
              {i18n.t("viber_dialog_description", {
                phone_number: phoneNumber ?? "",
              })}
            </ThemeText>

            <View style={styles.actionContainer}>
              <TouchableOpacity
                style={[styles.actionButton]}
                onPress={() => {
                  dispatch(dismissViberError());
                }}
              >
                <ThemeText style={[styles.buttonText]}>
                  {i18n.t("close")}
                </ThemeText>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(14, 8, 21, 0.5)",
  },
  loadingContainer: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  dialog: {
    backgroundColor: "black",
    borderRadius: 16,
    width: "70%",
    minWidth: 300,
    marginHorizontal: 16,
    alignItems: "center",
    // shadowColor: "rgba(98, 95, 95, 0.5)",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    // elevation: 5,
  },
  title: {
    paddingHorizontal: 16,
    paddingTop: 16,
    fontSize: 18,
    // fontWeight: "bold",
    color: "white",
  },
  description: {
    marginTop: 6,
    paddingHorizontal: 16,
    fontSize: 14,
    color: "white",
    textAlign: "center",
    paddingBottom: 16,
  },
  actionContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  actionButton: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 6,
  },
  buttonText: {
    color: "#0A84FF",
    textAlign: "center",
    fontSize: 16,
  },
});
