import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg";
const ConfirmSuccessSvg = (props : any) => (
  <Svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M72.5125 85.9996L81.3375 94.8246L134.247 41.9146L143.086 50.7534L81.3375 112.502L41.5625 72.7271L50.4013 63.8884L63.6812 77.1684L72.5125 85.9996ZM72.5231 68.3265L103.477 37.3721L112.291 46.1863L81.3375 77.1403L72.5231 68.3265ZM54.8561 103.671L46.0247 112.502L6.25 72.7271L15.0888 63.8884L23.9202 72.7203L23.9127 72.7271L54.8561 103.671Z"
      fill="url(#paint0_linear_582_3489)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_582_3489"
        x1={74.6681}
        y1={37.3721}
        x2={74.6681}
        y2={112.502}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#8652EB" />
        <Stop offset={1} stopColor="#BC52EB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default ConfirmSuccessSvg;
