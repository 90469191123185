import ThemeText from "@/components/ThemeText";
import AnnouncementLogo from "@/components/svgs/profile/AnnouncementLogo";
import LuckyDrawLogo from "@/components/svgs/profile/LuckdrawHistoryLogo";
import Trophy from "@/components/svgs/profile/Trophy";
import WalletLogo from "@/components/svgs/profile/WalletLogo";
import { INotification } from "@/model/response/notification_model";
import { formatDateTime } from "@/utils/format";
import React, { memo } from "react";
import { Pressable, StyleSheet, View } from "react-native";

interface NotificationItemProps {
  notification: INotification;
  onPress: (notification: INotification) => void;
}

const NotificationItem = memo(
  ({ notification, onPress }: NotificationItemProps) => {
    const getCategoryIcon = (category: string) => {
      switch (category) {
        case "announcement":
          return <AnnouncementLogo />; // Replace with actual SVG component
        case "top_up":
          return <WalletLogo />; // Replace with actual SVG component
        case "withdraw":
          return <WalletLogo />; // Replace with actual SVG component
        case "game_result":
          return <LuckyDrawLogo />; // Replace with actual SVG component
        case "win":
          return <Trophy />; // Replace with actual SVG component
        default:
          return null;
      }
    };

    const formattedDate = formatDateTime(notification.created_at);

    return (
      <Pressable
        style={({ pressed }) => [
          styles.container,
          pressed && { opacity: 0.65 },
        ]}
        onPress={() => onPress(notification)}
      >
        <View style={styles.itemContainer}>
          <View style={styles.iconContainer}>
            {getCategoryIcon(notification.notification_category)}
          </View>
          <View style={styles.textContainer}>
            <ThemeText style={styles.title}>{notification.title}</ThemeText>
            <ThemeText style={styles.date} weight="medium">
              {formattedDate}
            </ThemeText>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            height: 1.5,
            backgroundColor: "#3E3944",
            marginTop: 10,
            marginBottom: 20,
          }}
        ></View>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingHorizontal: 20,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginVertical: 8,
    marginRight: 20,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    flexWrap: "wrap",
    fontSize: 14,
  },
  date: {
    fontSize: 11,
    color: "#9F9CA1",
  },
  iconContainer: {
    marginRight: 8,
  },
});

export default NotificationItem;
