import React from "react";
import { ActivityIndicator, Modal, StyleSheet, View } from "react-native";

interface ModalLoadingProps {
  isLoading: boolean;
}

const ModalLoading: React.FC<ModalLoadingProps> = ({ isLoading }) => {
  return (
    <Modal animationType="slide" transparent={true} visible={isLoading}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <ActivityIndicator size="large" />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(14, 8, 21, 0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default ModalLoading;
