import { RootState } from "@/store/store";
import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BetConfirmRequestModel } from "../model/request/bet_confirm_request_model";
import { DeviceInfo } from "../model/request/device_info_model";
import { GameRequestModel } from "../model/request/game_request_model";
import { LoginRequestModel } from "../model/request/login_request_model";
import {
  PaginationParams,
  ProfileImageParam,
  SimplePaginationParams,
  WeekDateParam,
  WeekIdParam,
} from "../model/request/parameter_model";
import { RegisterRequestModel } from "../model/request/rigister_request_model";
import { TopUpRequestModel } from "../model/request/topup_request_model";
import { WithdrawRequestModel } from "../model/request/withdraw_request_model";
import { AvatarLevelModel } from "../model/response/avatar_model";
import { BannerModel } from "../model/response/banner_model";
import { ApiResponse, PaginatedApiResponse } from "../model/response/base";
import { BetConfirmResponseModel } from "../model/response/bet_confirm_response_model";
import { BetSaveResponseModel } from "../model/response/bet_save_response_model";
import { BetSettingGameApiData } from "../model/response/bet_setting_game_model";
import { FileUploadUrlResponse } from "../model/response/file_upload_response_model";
import { GameModel } from "../model/response/game_model";
import { InformationApiData } from "../model/response/information_model";
import { LimitationModel } from "../model/response/limitation_model";
import { LoginResponseModel } from "../model/response/login_response_model";
import { MeModel } from "../model/response/me_model";
import { NextTimeLotteryModel } from "../model/response/next_time_lottery_model";
import { NotificationResponse } from "../model/response/notification_model";
import { Ticket } from "../model/response/order_model";
import { ResultWeekModel } from "../model/response/result_week_model";
import { WeekResultWinners } from "../model/response/result_winner_model";
import {
  GameResultsModel,
  GameWeekModel,
  ResultDataModel,
} from "../model/response/results_model";
import SuccessResponseModel from "../model/response/success_response_model";
import {
  Transaction,
  TransactionsResponse,
} from "../model/response/transaction_model";
import { ViberData } from "../model/response/viber_model";
import { AllWinnerModel } from "../model/response/winner_model";
import i18n from "@/lang/i18n";
import { IChangePasswordRequest } from "@/model/request/change_password_request_model";

export const API_URL =
  process.env.EXPO_PUBLIC_API_URL || "https://api.salone4d.com/api/v1/";
// const API_URL = "http://192.168.100.70:8000/api/v1/";

// console.log(API_URL);
export const createRtkQueryErrorLogger = () => {
  const rtkQueryErrorLogger: Middleware =
    (storeAPI: MiddlewareAPI) => (next) => (action) => {
      return next(action);
    };

  return rtkQueryErrorLogger;
};

// {"error":  "meta": {"RTK_autoBatch": true, "aborted": false, "arg": {"endpointName": "login", "fixedCacheKey": undefined, "originalArgs": [Object], "track": true, "type": "mutation"}, "baseQueryMeta": {"request": [Request], "response": [Response]}, "condition": false, "rejectedWithValue": true, "requestId": "AVMuMdLPn4FsAzKj7kQxS", "requestStatus": "rejected"}, "payload": {"data": {"errors": [Object], "message": "Error!", "status": 422, "success": false}, "status": 422}, "type": "api/executeMutation/rejected"}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    cache: "no-cache",
    keepalive: false,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      if (state.network.isConnected == false) {
        throw new Error("NoInternet");
      }

      const token = state.auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json");
      headers.set("Accept-Language", i18n.locale);
      return headers;
    },
  }),
  tagTypes: [
    "Login",
    "Logout",
    "Register",
    "ActiveGames",
    "BetSave",
    "BetConfirm",
    "BuyOrderHistory",
    "GenerateFileUrl",
    "UploadFileURl",
    "Notification",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseModel, LoginRequestModel>({
      query: (body) => ({ url: "login", method: "POST", body }),
      // transformErrorResponse(baseQueryReturnValue, meta, arg) {
      //   return baseQueryReturnValue.data as BaseApiErrorResponse;
      // },
      // invalidatesTags: ["Login"],
    }),
    logout: builder.mutation<void, void>({
      query: (body) => ({ url: "logout", method: "POST", body }),
      invalidatesTags: ["Logout"],
    }),
    register: builder.mutation<LoginResponseModel, RegisterRequestModel>({
      query: (body) => ({ url: "register", method: "POST", body }),
      // transformErrorResponse(baseQueryReturnValue, meta, arg) {
      //   return baseQueryReturnValue.data as BaseApiErrorResponse;
      // },
    }),
    generateFileUrl: builder.mutation<FileUploadUrlResponse, void>({
      query: () => ({ url: "signed-storage-url", method: "POST", body: {} }),
      invalidatesTags: ["GenerateFileUrl"],
    }),

    topUp: builder.mutation<SuccessResponseModel, TopUpRequestModel>({
      query: (body) => ({ url: "top-ups", method: "POST", body }),
    }),

    topUpInfo: builder.query<
      ApiResponse<{
        kpay: {
          phone_number: string;
          description: string;
          image: string | null;
        };
        wave_pay: {
          phone_number: string;
          description: string;
          image: string | null;
        };
      }>,
      void
    >({
      query: () => ({ url: "top-ups/info", method: "GET" }),
    }),
    withdraw: builder.mutation<SuccessResponseModel, WithdrawRequestModel>({
      query: (body) => ({ url: "withdraws", method: "POST", body }),
    }),
    registerDeviceToken: builder.mutation<SuccessResponseModel, DeviceInfo>({
      query: (body) => ({ url: "device-token", method: "POST", body }),
    }),

    activeGames: builder.query<GameModel[], void>({
      query: () => "four-d/game/active",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    termsAndConditions: builder.query<InformationApiData, void>({
      query: () => "term",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    aboutUs: builder.query<InformationApiData, void>({
      query: () => "about-us",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    contactUs: builder.query<InformationApiData, void>({
      query: () => "contact-us",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    betSave: builder.mutation<BetSaveResponseModel, GameRequestModel>({
      query: (betData) => ({
        url: "four-d/bet/save",
        method: "POST",
        body: betData,
      }),

      invalidatesTags: ["BetSave"],
    }),
    betConfirm: builder.mutation<
      BetConfirmResponseModel,
      BetConfirmRequestModel
    >({
      query: (body) => ({ url: "four-d/bet/confirm", method: "POST", body }),
      invalidatesTags: ["BetConfirm"],
    }),
    buyOrderHistory: builder.query<
      PaginatedApiResponse<Ticket[]>,
      { page: number }
    >({
      query: ({ page }) => ({
        url: "four-d/slips",
        params: {
          page: page,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return {
          endpointName,
        };
      },
      merge(currentCache, newItems, otherArgs) {
        if (otherArgs.arg.page == 1) {
          return newItems;
        }
        if (currentCache.data) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
    getTransactions: builder.query<
      PaginatedApiResponse<Transaction[]>,
      PaginationParams
    >({
      query: (params) => `transactions?page=${params.page}&type=${params.type}`,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return {
          endpointName,
          type: queryArgs.type,
        };
      },
      merge(currentCache, newItems, otherArgs) {
        if (otherArgs.arg.page == 1) {
          return newItems;
        }
        if (currentCache.data) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getAllTransactions: builder.query<
      TransactionsResponse,
      SimplePaginationParams
    >({
      query: (params) => `transactions?page=${params.page}`,
      serializeQueryArgs: ({ endpointName }) => {
        return {
          endpointName,
        };
      },
      merge(currentCache, newItems, otherArgs) {
        if (otherArgs.arg.page == 1) {
          return newItems;
        }
        if (currentCache.data) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
    getBanners: builder.query<BannerModel[], void>({
      query: () => "banners",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getNextTimeLottery: builder.query<NextTimeLotteryModel, void>({
      query: () => "four-d/game/next",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getLatestResult: builder.query<ResultDataModel, void>({
      query: () => "four-d/game/latest-result",

      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    getTopWinners: builder.query<AllWinnerModel, void>({
      query: () => "four-d/game/top-winners",
    }),
    getGameWeekRange: builder.query<GameWeekModel[], void>({
      query: () => "four-d/game/weeks",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    getGameResultsByWeek: builder.query<GameResultsModel, WeekDateParam>({
      query: (dateParam) =>
        `four-d/game/result?from_date=${dateParam.from_date}&to_date=${dateParam.to_date}`,
    }),
    getAvatars: builder.query<AvatarLevelModel[], void>({
      query: () => "avatar",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    getMe: builder.query<MeModel, string | void>({
      query: (token?: string) => ({
        url: "me",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getWeekResultWinners: builder.query<WeekResultWinners[], WeekIdParam>({
      query: (WeekIdParam) => `four-d/game/${WeekIdParam.gameId}/winners`,
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    getBetSettingByGame: builder.query<BetSettingGameApiData, WeekIdParam>({
      query: (WeekIdParam) => `four-d/bet-setting/${WeekIdParam.gameId}`,
      transformResponse: (response: any) => {
        return response.data;
      },
      forceRefetch(params) {
        return true;
      },
    }),

    getLatestResultWeek: builder.query<ResultWeekModel[], void>({
      query: () => "four-d/game/latest-game-dates",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    getLimitations: builder.query<LimitationModel, void>({
      query: () => "user/bet-setting",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),

    getViberData: builder.query<ViberData, void>({
      query: () => "viber",
    }),

    getNotifications: builder.query<
      NotificationResponse,
      SimplePaginationParams
    >({
      providesTags: (result, error, arg) => [{ type: "Notification" }],
      query: (params) => ({
        url: `/notification?page=${params.page}`,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return {
          endpointName,
        };
      },
      merge(currentCache, newItems, otherArgs) {
        if (otherArgs.arg.page == 1) {
          return newItems;
        }
        if (currentCache.data) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
    updateAvatar: builder.mutation<
      ApiResponse<{ profile_image: string }>,
      ProfileImageParam
    >({
      query: (body) => ({ url: "update-avatar", method: "POST", body }),
    }),
    getBalance: builder.query<number, void>({
      query: () => ({ url: "user/balance", method: "GET" }),
      transformResponse: (response: ApiResponse<{ balance: number }>) => {
        return response.data.balance;
      },
    }),
    getDownloadLink: builder.query<string, void>({
      query: () => ({ url: "download-url", method: "GET" }),
      transformResponse: (response: ApiResponse<{ download_url: string }>) => {
        return response.data.download_url;
      },
    }),
    sendLog: builder.mutation<void, any>({
      query: (body) => ({ url: "log/send", method: "POST", body }),
    }),
    changePassword: builder.mutation<void, IChangePasswordRequest>({
      query: (body) => ({ url: "change-password", method: "POST", body }),
    }),
    getAppVersion: builder.query<{ data: { build_number: number } }, void>({
      query: () => ({url: 'app-version/check'}),
      keepUnusedDataFor: 0,
    })
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useActiveGamesQuery,
  useBetSaveMutation,
  useBetConfirmMutation,
  useBuyOrderHistoryQuery,
  useGetTransactionsQuery,
  useGetAllTransactionsQuery,
  useGetBannersQuery,
  useGetNextTimeLotteryQuery,
  useGetLatestResultQuery,
  useGetTopWinnersQuery,
  useGetGameWeekRangeQuery,
  useGetGameResultsByWeekQuery,
  useGenerateFileUrlMutation,
  useTopUpMutation,
  useWithdrawMutation,
  useGetAvatarsQuery,
  useGetMeQuery,
  useGetWeekResultWinnersQuery,
  useGetLatestResultWeekQuery,
  useGetLimitationsQuery,
  useTermsAndConditionsQuery,
  useAboutUsQuery,
  useContactUsQuery,
  useGetBetSettingByGameQuery,
  useGetNotificationsQuery,
  useRegisterDeviceTokenMutation,
  useUpdateAvatarMutation,
  useGetViberDataQuery,
  useGetBalanceQuery,
  useLazyGetMeQuery,
  useTopUpInfoQuery,
  useLazyGetDownloadLinkQuery,
  useChangePasswordMutation,
} = apiSlice;

export default apiSlice;
