import React from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  TextProps,
  View,
} from "react-native";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";
import ThemeText from "./ThemeText";

export type AlertDialogProps = {
  visible: boolean;
  title: string;
  dialogStyle?: ViewProps["style"];
  titleStyle?: TextProps["style"];
  description: string;
  descriptionStyle?: TextProps["style"];
  actions: {
    title: string;
    onPress: () => void;
    titleStyle?: TextProps["style"];
    buttonStyle?: ViewProps["style"];
  }[];
  onDismiss?: () => void;
};

export default function AlertDialog({
  visible,
  title,
  dialogStyle,
  titleStyle,
  description,
  descriptionStyle,
  actions,
  onDismiss,
}: AlertDialogProps) {
  return (
    <Modal visible={visible} transparent onRequestClose={onDismiss}>
      <View style={styles.backdrop}>
        <View style={[styles.dialog, dialogStyle]}>
          <ThemeText style={styles.title}>{title}</ThemeText>
          <ThemeText style={styles.description}>{description}</ThemeText>
          <View style={styles.actionContainer}>
            {actions.map((action) => (
              <Pressable
                key={action.title}
                style={({ pressed }) => [
                  styles.actionButton,
                  pressed && styles.actionButtonPressed,
                  action.buttonStyle,
                ]}
                onPress={action.onPress}
              >
                <ThemeText style={[styles.buttonText, action.titleStyle]}>
                  {action.title}
                </ThemeText>
              </Pressable>
            ))}

            {/* <TouchableOpacity style={styles.actionButton} onPress={onClose}>
              <Text style={[styles.buttonText, { color: purpleText }]}>OK</Text>
            </TouchableOpacity> */}
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  backdrop: {
    flex: 1,
    backgroundColor: "rgba(14, 8, 21, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  dialog: {
    backgroundColor: "black",
    borderRadius: 16,
    width: "70%",
    minWidth: 300,
    marginHorizontal: 16,
    alignItems: "center",
  },
  title: {
    paddingHorizontal: 16,
    paddingTop: 16,
    fontSize: 18,
    // fontWeight: "bold",
    color: "white",
  },
  description: {
    marginTop: 6,
    paddingHorizontal: 16,
    fontSize: 14,
    color: "white",
    textAlign: "center",
    paddingBottom: 16,
  },
  actionContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  actionButton: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 6,
  },
  actionButtonPressed: {
    opacity: 0.65,
  },
  buttonText: {
    color: "#0A84FF",
    textAlign: "center",
    fontSize: 16,
  },
});
