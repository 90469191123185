import { useGetBalanceQuery, useGetTransactionsQuery } from "@/api/apiSlice";
import PrimaryButton from "@/components/PrimaryButton";
import PrimaryGradient from "@/components/PrimaryGradient";
import { activeGameBorder, purpleText } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { Transaction } from "@/model/response/transaction_model";
import { TOP_UP_SCREEN, WITHDRAW_SCREEN } from "@/routes/Routes";
import { selectProfileBalance } from "@/store/auth/auth_reducer";
import { formatDateTime } from "@/utils/format";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import * as React from "react";
import { useCallback, useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import TransactionItem from "./components/TransactionItem";
import ThemeText from "@/components/ThemeText";

const moneyFormatter = new Intl.NumberFormat("en-US", {
  // style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const renderItem = ({ item }: { item: Transaction }) => {
  const formattedDate = formatDateTime(item.created_at);

  return (
    <TransactionItem
      name={item.name! ? item.name! : "Cash In"}
      amount={moneyFormatter.format(item.amount) + " Ks"}
      color={item.color!}
      date={formattedDate}
      status={item.status}
      type={item.type}
    />
  );
};

function CashScreen() {
  const balance = useSelector(selectProfileBalance);
  const [transactionType, setTransactionType] = useState("deposit");
  const [page, setPage] = useState(1);
  const navigation = useNavigation();

  const {
    data: transactions,
    isFetching: isTransactionFetching,
    refetch: refreshTransactions,
  } = useGetTransactionsQuery({
    page: page,
    type: transactionType,
  });

  const { refetch: refetchBalance } = useGetBalanceQuery();

  useFocusEffect(
    useCallback(() => {
      if (page == 1) {
        refreshTransactions();
      } else {
        setPage(1);
      }
      refetchBalance();
      // transactionRefetch();
    }, [])
  );

  function changeTransactionType(type: "withdraw" | "deposit") {
    if (type !== transactionType) {
      setTransactionType(type);
      setPage(1);
    }
  }

  function handleTopUpPress() {
    // @ts-ignore
    navigation.navigate(TOP_UP_SCREEN);
  }

  function handleWithdrawPress() {
    // @ts-ignore
    navigation.navigate(WITHDRAW_SCREEN);
  }

  // if (isCashInError) {
  //   return <Text>Error occurred while fetching transactions. </Text>;
  // }

  // if (isCashOutError) {
  //   return <Text>Error occurred while fetching transactions. </Text>;
  // }

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.columnBalance}>
          <ThemeText style={styles.textAvailableBalance}>
            {i18n.t("available_balance")}:
          </ThemeText>
          {balance ? (
            <ThemeText weight="extra-bold" style={styles.textBalance}>
              {moneyFormatter.format(balance) + " Ks"}
            </ThemeText>
          ) : (
            <ThemeText weight="extra-bold" style={styles.textBalance}>
              0 Ks
            </ThemeText>
          )}
        </View>
        <View style={styles.tabContainer}>
          <TouchableOpacity
            style={{
              flex: 1,
              // alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() => {
              changeTransactionType("deposit");
            }}
          >
            {transactionType === "deposit" ? (
              <PrimaryGradient style={styles.tabButton}>
                <ThemeText weight="bold" style={styles.tabButtonText}>
                  {i18n.t("cash_in")}
                </ThemeText>
              </PrimaryGradient>
            ) : (
              <ThemeText weight="bold" style={styles.inActiveTabButtonText}>
                {i18n.t("cash_in")}
              </ThemeText>
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={() => {
              changeTransactionType("withdraw");
            }}
          >
            {/* <TouchableOpacity onPress={() => {}}> */}
            {transactionType === "withdraw" ? (
              <PrimaryGradient style={styles.tabButton}>
                <ThemeText weight="bold" style={styles.tabButtonText}>
                  {i18n.t("cash_out")}
                </ThemeText>
              </PrimaryGradient>
            ) : (
              <ThemeText weight="bold" style={styles.inActiveTabButtonText}>
                {i18n.t("cash_out")}
              </ThemeText>
            )}
          </TouchableOpacity>
          {/* </TouchableOpacity> */}
        </View>
      </View>
      <View style={styles.row}>
        <View
          style={{
            flex: 1,
            height: 1.5,
            backgroundColor: "#3E3944",
            marginTop: 10,
            marginBottom: 20,
          }}
        ></View>
      </View>

      <View style={[styles.row, { marginBottom: 24 }]}>
        <PrimaryButton
          title={i18n.t("top_up")}
          buttonStyle={{ flex: 1, marginRight: 8, height: 38 }}
          titleStyle={{ fontWeight: "400", fontSize: 11 }}
          onPress={handleTopUpPress}
          disabled={isTransactionFetching}
        />

        <PrimaryButton
          title={i18n.t("withdraw")}
          buttonStyle={{ flex: 1, marginRight: 8, height: 38 }}
          titleStyle={{ fontWeight: "400", fontSize: 11 }}
          onPress={handleWithdrawPress}
          disabled={isTransactionFetching}
        />
      </View>

      {transactions && (
        <FlashList
          data={transactions.data}
          keyExtractor={(item) => item.id.toString()}
          // style={{ marginTop: 24 }}
          contentContainerStyle={{
            paddingBottom: 24,
          }}
          estimatedItemSize={58}
          // getItemLayout={(data, index) => ({
          //   length: 58,
          //   offset: 58 * index,
          //   index,
          // })}
          renderItem={renderItem}
          onEndReached={() => {
            if (page < transactions.meta.last_page && !isTransactionFetching) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
          onEndReachedThreshold={0.1}
          ListFooterComponent={
            isTransactionFetching ? <ActivityIndicator size="large" /> : null
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: "red",
    // alignItems: "center",
    // justifyContent: "center",
    marginHorizontal: 20,
  },
  box: {
    marginVertical: 10,
  },
  margin: {
    marginLeft: 20,
    marginRight: 20,
  },
  row: {
    flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "center",
    height: "auto",
  },
  columnSelectedValue: {
    flex: 1,

    justifyContent: "center",
    alignItems: "flex-start",
  },
  columnBalance: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  textBalance: {
    fontSize: 16,
    marginBottom: 5,
    color: purpleText,
  },
  textAvailableBalance: {
    fontSize: 13,
    marginBottom: 5,
    color: "white",
  },
  safeareaContainer: {
    flex: 1,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
    // flex: 1,
  },
  tabContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    // alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
    borderColor: activeGameBorder,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 4,
    paddingVertical: 4,
    height: "auto",
  },
  tabButton: {
    // flex: 1,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
    paddingVertical: 11,
    // height: 38,
    marginHorizontal: 2,
  },
  inActiveTabButtonText: {
    color: "white",
    fontSize: 11,
    paddingHorizontal: 4,
    // flex: 1,
    borderRadius: 8,
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
    marginHorizontal: 2,
  },
  tabButtonText: {
    color: "white",
    fontSize: 11,
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  button: {
    flex: 1,
    backgroundColor: "#8352EB",
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  loadingIndicator: {
    marginVertical: 10, // Adjust this value to control the spacing
  },
});

export default CashScreen;
// function useGetTransactionsQuery(arg0: { page: number; type: string }): {
//   data: any;
//   isFetching: any;
//   isSuccess: any;
//   isError: any;
//   error: any;
//   refetch: any;
// } {
//   throw new Error("Function not implemented.");
// }
