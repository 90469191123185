import {
  linearColorPink,
  linearColorPurple,
  primaryBlackColor,
} from "@/constants/ColorConstants";
import React from "react";
import type { TextProps, ViewProps } from "react-native";
import { Pressable, Text, TouchableOpacity, View } from "react-native";
import LinearGradient from "react-native-linear-gradient";

type PrimaryButtonProps = {
  buttonStyle?: ViewProps["style"];
  title: string;
  titleStyle?: TextProps["style"];
} & Omit<TouchableOpacity["props"], "style">;

export default function GradientOutlineButton({
  title,
  titleStyle,
  buttonStyle,
  disabled,
  ...props
}: PrimaryButtonProps) {
  return (
    <Pressable
      style={({ pressed }) => [
        {
          height: 52,
          flexDirection: "column",
        },
        buttonStyle,
      ]}
      disabled={disabled}
      {...props}
    >
      {({ pressed }) => (
        <>
          <View
            style={[
              {
                backgroundColor: primaryBlackColor,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
                margin: 2,
                zIndex: 10,
                flex: 1,
                //   height: "100%",
                paddingHorizontal: 8,
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 16,
                  fontWeight: "bold",
                  textAlign: "center",
                  color: disabled ? "rgba(255, 255, 255, 0.6)" : "white",
                },
                titleStyle,
                pressed && { opacity: 0.65 },
              ]}
            >
              {title}
            </Text>
          </View>
          <LinearGradient
            colors={[
              disabled ? "#4F4A56" : linearColorPurple,
              disabled ? "#4F4A56" : linearColorPink,
            ]}
            start={{ x: 0, y: 0.5 }}
            end={{ x: 1, y: 0.5 }}
            style={[
              {
                position: "absolute",
                borderRadius: 8,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 5,
                // paddingHorizontal: 8,
                // margin: 2,
              },
              pressed && { opacity: 0.65 },
            ]}
          ></LinearGradient>
        </>
      )}
    </Pressable>
  );
}
