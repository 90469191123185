import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import AppRouter from "./src/routes/AppRoute";
import { store } from "./src/store/store";

import AppLoading from "@/components/AppLoading";
import GlobalErrorDialog from "@/components/GlobalErrorDialog";
import ViberDialog from "@/components/ViberDialog";
import useAppSetup from "@/hooks/useAppSetup";
import useGetProfile from "@/hooks/useGetProfile";
import useRegisterNotification from "@/hooks/useRegisterNotification";
import * as Notifications from "expo-notifications";
import * as Sentry from "sentry-expo";
import ErrorBoundary from "@/components/ErrorBoundary";
import { selectIsUpdating } from "@/store/update";
import AppUpdate from "@/components/AppUpdate";

Sentry.init({
  dsn: "https://9f48d21873a25f409a8bdf20aa1a9e26@o4505652341243904.ingest.sentry.io/4506028779569152",
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

function AppContent() {
  const { appIsReady } = useAppSetup();
  useGetProfile();
  useRegisterNotification();

  if (!appIsReady) return <AppUpdate />;

  return (
    <>
      <AppRouter />
      <ViberDialog />
      <AppLoading />
      <GlobalErrorDialog />
    </>
  );
}

const App: React.FC = () => {
  return (
    <Provider store={store}>
      {/* <ErrorBoundary> */}
      <AppContent />
      {/* </ErrorBoundary> */}
    </Provider>
  );
};

export default App;
