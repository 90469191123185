import { BannerModel } from "@/model/response/banner_model";
import React, { useEffect, useRef } from "react";
import {
  Animated,
  Image,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";

interface Props {
  data: BannerModel[];
}

const CarouselSlider: React.FC<Props> = ({ data }) => {
  const { width } = useWindowDimensions();
  const sliderWidth = width - 48;
  const page = useRef(0);
  const slider = useRef<ScrollView>(null);
  const scrollX = new Animated.Value(0, {
    useNativeDriver: false,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (slider.current) {
        const nextSlide = (page.current + 1) % data.length;
        slider.current.scrollTo({
          x: nextSlide * width,
          animated: true,
        });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [data]);

  const position = Animated.divide(scrollX, width - 48);

  // internal to change the slide

  return (
    <View style={styles.container}>
      <View style={{ width: sliderWidth, aspectRatio: 16 / 9 }}>
        <ScrollView
          ref={slider}
          horizontal={true}
          pagingEnabled={true}
          showsHorizontalScrollIndicator={false}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    x: scrollX,
                  },
                },
              },
            ],
            {
              useNativeDriver: false,
              listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
                const slide = Math.ceil(
                  event.nativeEvent.contentOffset.x /
                    event.nativeEvent.layoutMeasurement.width
                );
                if (
                  slide !== page.current &&
                  sliderWidth * slide == event.nativeEvent.contentOffset.x
                ) {
                  page.current = slide;
                }
              },
            }
          )}
          scrollEventThrottle={16}
        >
          {data.map((source, i) => {
            return (
              <Image
                key={i}
                style={{ width: sliderWidth, aspectRatio: 16 / 9 }}
                source={{ uri: source.banner_image_url }}
              />
            );
          })}
        </ScrollView>
      </View>
      <View
        style={{ flexDirection: "row", marginTop: 16, marginHorizontal: 24 }}
      >
        {data.map((_, i) => {
          let opacity = position.interpolate({
            inputRange: [i - 1, i, i + 1],
            outputRange: [0.3, 1, 0.3],
            extrapolate: "clamp",
          });
          return (
            <Animated.View
              key={i}
              style={{
                opacity,
                height: 8,
                width: 8,
                backgroundColor: "white",
                margin: 6,
                borderRadius: 4,
              }}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  slide: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: 200,

    resizeMode: "cover",
  },
  paginationContainer: {
    paddingVertical: 8,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 8,
    backgroundColor: "rgba(255, 130, 130, 0.92)",
  },
  inactiveDot: {
    backgroundColor: "rgb(255, 255, 255)",
  },
});

export default CarouselSlider;
