import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { numberBgPurple, numberBorderPurple } from '../../../constants/ColorConstants';


type Props = {
  paddingVertical: number;
  paddingHorizontal: number;
  number: number;
};

const WinRectangleNumber: React.FC<Props> = ({
  paddingVertical,
  paddingHorizontal,
  number,
}) => {
  return (
    <View style={[styles.container,{paddingHorizontal: paddingHorizontal,paddingVertical:paddingVertical}]}>
      <Text style={styles.text}>{number}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: numberBgPurple,
    borderColor: numberBorderPurple,
    borderRadius: 4,
    margin: 1
 
  },
  text: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 14,
    color: 'white'
  },
});

export default WinRectangleNumber;
