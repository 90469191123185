import React from "react";
import { StyleSheet, Text, View, ViewProps } from "react-native";
import DateRec from "../screens/home/dashboard/components/DateRec";

interface Props {
  text: string;
  width?: number;
  style?: ViewProps["style"];
}

const BlackLabel: React.FC<Props> = ({ text, width, style }) => {
  return (
    <View style={[styles.container, style, width ? { width } : null]}>
      <DateRec width={width || 201} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  dateRec: {
    position: "relative",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: "white",
    fontWeight: "700",
    textAlign: "center",
    fontSize: 13,
    position: "absolute",
  },
});

export default BlackLabel;
