import GradientOutlineButton from "@/components/GradientOutlineButton";
import i18n, { t } from "@/lang/i18n";
import { SaveBetModel } from "@/model/request/game_request_model";
import {
  addBets,
  selectCanAddBet,
  selectCurrentBetAmount,
  selectCurrentInputNumber,
} from "@/store/bet";
import { getBetNumbers } from "@/utils/number";
import React from "react";
import uuid from "react-native-uuid";
import { useDispatch, useSelector } from "react-redux";

export default function BetAddButton() {
  const dispatch = useDispatch();

  const currentInputNumber = useSelector(selectCurrentInputNumber);
  const currentBetAmount = useSelector(selectCurrentBetAmount);

  const canAddBet = useSelector(selectCanAddBet);

  function onAddBet() {
    const items: SaveBetModel[] = [];
    const numbers = getBetNumbers(currentInputNumber);
    const uniqueKey = uuid.v4().toString();
    for (let number of numbers) {
      items.push({
        bet_number: number.toString(),
        bet_amount: parseInt(currentBetAmount),
        input_number: currentInputNumber,
        unique_key: uniqueKey,
      });
    }

    dispatch(addBets(items));
  }

  return (
    <GradientOutlineButton
      disabled={!canAddBet}
      onPress={onAddBet}
      buttonStyle={{
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 120,
        maxWidth: "40%",
        marginLeft: 8,
        height: 56,
      }}
      title={i18n.t("bet_add")}
    />
  );
}
