import { useLazyGetMeQuery } from "@/api/apiSlice";
import { selectAuthToken } from "@/store/auth/auth_reducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function useGetProfile() {
  const authToken = useSelector(selectAuthToken);

  const [trigger] = useLazyGetMeQuery();

  useEffect(() => {
    if (!!authToken) {
      trigger();
    }
  }, [authToken]);
}
