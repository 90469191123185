import * as React from "react";
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg";
const ViberSvg = (props : any) => (
  <Svg
    width={32}
    height={34}
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_455_571)">
      <Path
        d="M28.3878 3.31609C27.5494 2.53653 24.1609 0.0580748 16.6135 0.0244029C16.6135 0.0244029 7.71309 -0.516389 3.37429 3.49363C0.959064 5.92771 0.10943 9.48928 0.0198084 13.9049C-0.0698132 18.3205 -0.185765 26.5957 7.73081 28.8394H7.73841L7.73335 32.2633C7.73335 32.2633 7.68271 33.6494 8.58855 33.9321C9.68426 34.2749 10.3273 33.2214 11.3734 32.0857C11.9476 31.4623 12.7405 30.5465 13.338 29.8465C18.7523 30.3057 22.9164 29.2562 23.3893 29.1011C24.4825 28.744 30.6684 27.9456 31.6745 19.671C32.713 11.1423 31.1722 5.74761 28.3878 3.31609ZM29.3053 19.0593C28.4562 25.9681 23.4389 26.4023 22.5138 26.7013C22.1204 26.8288 18.4631 27.7456 13.8641 27.4431C13.8641 27.4431 10.4372 31.6087 9.36679 32.6918C9.1997 32.8612 9.00324 32.9295 8.8721 32.8959C8.68779 32.8505 8.63716 32.6306 8.63918 32.3092C8.64222 31.85 8.66855 26.6196 8.66855 26.6196C1.97174 24.7462 2.36212 17.7032 2.43807 14.0151C2.51402 10.327 3.20163 7.30571 5.24419 5.27366C8.91412 1.92432 16.4742 2.4248 16.4742 2.4248C22.8586 2.45286 25.9179 4.39002 26.6273 5.03897C28.9828 7.07102 30.1828 11.9336 29.3053 19.0572V19.0593Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7428 11.0317C16.7551 10.7945 16.9559 10.6122 17.1913 10.6246C18.0721 10.6709 18.8049 10.9396 19.3268 11.4833C19.8467 12.0249 20.1015 12.7816 20.1463 13.6903C20.158 13.9276 19.9766 14.1294 19.7411 14.1412C19.5057 14.153 19.3053 13.9702 19.2936 13.733C19.2554 12.9551 19.0435 12.4255 18.7131 12.0813C18.3847 11.7391 17.8836 11.5223 17.1468 11.4836C16.9114 11.4712 16.7305 11.2689 16.7428 11.0317Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0959 8.71512C16.1131 8.47822 16.3176 8.30024 16.5527 8.31757C18.3452 8.44975 19.788 9.06686 20.8389 10.2227L20.8391 10.2229C21.8786 11.3674 22.3726 12.7912 22.3368 14.4612C22.3317 14.6986 22.1365 14.887 21.9008 14.8818C21.6651 14.8767 21.4782 14.68 21.4833 14.4426C21.5149 12.9698 21.0851 11.7679 20.2093 10.8036C19.3362 9.84338 18.1156 9.29529 16.4904 9.17544C16.2553 9.15811 16.0787 8.95201 16.0959 8.71512Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3428 6.51205C15.3444 6.27453 15.5369 6.08332 15.7726 6.08497C18.2497 6.10236 20.3435 6.93327 22.0246 8.58452C23.7198 10.2496 24.5581 12.5164 24.5794 15.3318C24.5812 15.5693 24.3915 15.7633 24.1558 15.7651C23.92 15.7669 23.7275 15.5758 23.7257 15.3383C23.7058 12.7002 22.9269 10.672 21.4287 9.20045C19.9165 7.71511 18.039 6.96106 15.7666 6.94512C15.5309 6.94346 15.3411 6.74957 15.3428 6.51205Z"
        fill="white"
      />
      <Path
        d="M17.2539 19.4728C17.2539 19.4728 17.854 19.5238 18.177 19.1228L18.8069 18.3243C19.1107 17.9284 19.8439 17.6759 20.5618 18.0789C21.0999 18.3881 21.6222 18.7243 22.1269 19.086C22.6034 19.4391 23.5791 20.2595 23.5821 20.2595C24.047 20.6549 24.1543 21.2354 23.8378 21.8477C23.8378 21.8512 23.8353 21.8574 23.8353 21.8604C23.4868 22.4692 23.0443 23.0182 22.5244 23.4869C22.5183 23.4899 22.5183 23.493 22.5128 23.4961C22.0611 23.8763 21.6174 24.0925 21.1816 24.1445C21.1174 24.1558 21.0522 24.1599 20.9872 24.1567C20.795 24.1586 20.6038 24.129 20.4211 24.069L20.4069 24.0481C19.7355 23.8573 18.6145 23.3797 16.7476 22.342C15.6673 21.7486 14.6388 21.064 13.6731 20.2957C13.1891 19.9108 12.7274 19.4982 12.2903 19.06L12.2437 19.0131L12.1972 18.9662L12.1506 18.9187C12.1349 18.9034 12.1197 18.8876 12.104 18.8718C11.6691 18.4314 11.2596 17.9662 10.8776 17.4785C10.1152 16.5056 9.4358 15.4695 8.84672 14.3812C7.81683 12.4996 7.3429 11.3711 7.15353 10.6936L7.13277 10.6793C7.07341 10.4951 7.04417 10.3025 7.04619 10.1089C7.04264 10.0434 7.04655 9.97763 7.05783 9.91299C7.11218 9.47492 7.32704 9.02766 7.7024 8.57122C7.70544 8.5656 7.70848 8.5656 7.71151 8.55948C8.17648 8.03568 8.7214 7.58997 9.32572 7.23913C9.32875 7.23913 9.33483 7.23607 9.33838 7.23607C9.94598 6.91721 10.5222 7.02537 10.9141 7.49116C10.9171 7.49422 11.7298 8.47734 12.0787 8.95742C12.4378 9.46645 12.7714 9.99327 13.0782 10.5359C13.4782 11.2588 13.2276 11.9991 12.8346 12.3042L12.0422 12.9389C11.6422 13.2644 11.6949 13.8689 11.6949 13.8689C11.6949 13.8689 12.8686 18.3448 17.2539 19.4728Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_455_571">
        <Rect width={32} height={34} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default ViberSvg;
