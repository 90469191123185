import RectangleNumber from "@/components/RectangleNumber";
import {
  accentColor,
  linearColorPurple,
  numberBorderRed,
} from "@/constants/ColorConstants";
import {
  removeBetItem,
  selectBetErrorById,
  selectMaxAmountPerBet,
  selectMinAmountPerBet,
  updateBetItemBetAmount,
} from "@/store/bet";
import { RootState } from "@/store/store";
import { Ionicons } from "@expo/vector-icons";
import { useDebounce } from "@uidotdev/usehooks";
import React, { memo, useState } from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

const BetListItem = memo(({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const bet = useSelector(
    (state: RootState) => state.bet.bets.find((b) => b.id === id)!
  );
  const error = useSelector((state: RootState) =>
    selectBetErrorById(state, id)
  );
  const minAmount = useSelector(selectMinAmountPerBet);
  const maxAmount = useSelector(selectMaxAmountPerBet);

  const [amount, setAmount] = useState(bet.bet_amount.toString());
  const debouncedAmount = useDebounce(amount, 300);

  React.useEffect(() => {
    setAmount(bet.bet_amount.toString());
  }, [bet]);

  React.useEffect(() => {
    const newAmount = parseInt(debouncedAmount);

    if (isNaN(newAmount)) {
      return;
    }
    dispatch(updateBetItemBetAmount({ id, amount: parseInt(debouncedAmount) }));
  }, [debouncedAmount]);

  function onAmountChange(text: string) {
    const newAmount = parseInt(text);

    if (isNaN(newAmount)) {
      setAmount("");
      // dispatch(setCurrentBetAmount(""));
      return;
    }
    if (newAmount <= maxAmount) {
      setAmount(newAmount.toString());
    }
  }

  function onAmountBlur(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    const newAmount = parseInt(amount);

    if (isNaN(newAmount) || newAmount < minAmount) {
      setAmount(minAmount.toString());
      return;
    }
  }

  const onButtonPress = (number: number) => {
    const newAmount = parseInt(amount) + number * minAmount;

    if (newAmount >= minAmount && newAmount <= maxAmount) {
      setAmount(newAmount.toString());
      // dispatch(setCurrentBetAmount(newAmount.toString()));
    }
  };

  function onRemove() {
    dispatch(removeBetItem(id));
  }

  const hasBetNumberError = !!(error && error.bet_number.length > 0);
  const hasBetAmountError = !!(error && error.bet_amount.length > 0);

  const errorMessage = hasBetNumberError
    ? error.bet_number.join("\n")
    : hasBetAmountError
    ? error.bet_amount.join("\n")
    : null;

  return (
    <View
      style={{
        marginBottom: 8,
      }}
    >
      <View
        style={{
          // marginBottom: 5,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginRight: 8,
            // marginVertical: 3,
          }}
        >
          <TouchableOpacity onPress={onRemove}>
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRadius: 4,
                backgroundColor: "white",
              }}
            >
              <Ionicons name="close-outline" size={16} color="black" />
            </View>
          </TouchableOpacity>
          <View style={{ marginRight: 4 }}>
            <View
              style={{
                marginLeft: 8,
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              {bet.bet_number.split("").map((singleNumber, index) => {
                return (
                  <RectangleNumber
                    key={index}
                    error={hasBetNumberError}
                    number={parseInt(singleNumber)}
                  />
                );
              })}
            </View>
          </View>
        </View>

        <View
          style={{
            justifyContent: "center",
            borderRadius: 8,
            // marginVertical: 3,
            flexDirection: "column",
            flexShrink: 1,
            flexGrow: 1,
            maxWidth: 230,
          }}
        >
          <View
            style={[
              {
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                borderWidth: 2,
                backgroundColor: accentColor,
                borderRadius: 8,
                flexDirection: "row",
              },
              hasBetAmountError && { borderColor: numberBorderRed },
            ]}
          >
            <TouchableOpacity
              onPress={() => onButtonPress(-1)}
              style={{
                paddingLeft: 10,
                paddingRight: 5,
                paddingVertical: 9,
                flexShrink: 0,
                flexGrow: 0,
              }}
            >
              <Ionicons
                name="remove-outline"
                size={24}
                color={linearColorPurple}
              />
            </TouchableOpacity>
            <TextInput
              style={styles.input}
              keyboardType="numeric"
              value={amount}
              onChangeText={onAmountChange}
              onBlur={onAmountBlur}
            />
            <TouchableOpacity
              onPress={() => onButtonPress(1)}
              style={{
                paddingRight: 10,
                paddingLeft: 5,
                paddingVertical: 9,
                flexShrink: 0,
                flexGrow: 0,
              }}
            >
              <Ionicons
                name="add-outline"
                size={24}
                color={linearColorPurple}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {errorMessage && (
        <Text
          style={[
            styles.errorText,
            !hasBetNumberError && hasBetAmountError
              ? {
                  textAlign: "right",
                }
              : null,
          ]}
        >
          {errorMessage}
        </Text>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  input: {
    minWidth: 80,
    height: 40,
    // flex: 1,
    flexShrink: 1,
    flexGrow: 1,
    fontSize: 16,
    color: "white",
    backgroundColor: "transparent",
    marginLeft: 8,
    marginRight: 8,
    textAlign: "center",
  },
  errorText: {
    color: "#963333",
    fontSize: 12,
    marginTop: 4,
    marginLeft: 33.5,
  },
});

export default BetListItem;
