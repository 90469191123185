import PrimaryButton from "@/components/PrimaryButton";
import { tagsStyles } from "@/constants/html";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import * as React from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";
import RenderHtml from "react-native-render-html";
import { useDispatch, useSelector } from "react-redux";
import { useTermsAndConditionsQuery } from "../../api/apiSlice";
import { primaryBlackColor } from "../../constants/ColorConstants";
import { FAKE_SCREEN, MAIN_SCREEN } from "../../routes/Routes";
import { setFirstLogin } from "../../store/auth/term_and_condition_reducer";
import i18n, { t } from "@/lang/i18n";
import {
  selectAuthToken,
  selectIsFeature,
  selectIsLogin,
} from "@/store/auth/auth_reducer";

const CONTAINER_MARGIN = 20;

const TermsAndConditionsScreen: React.FC = () => {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  const isLogin = useSelector(selectIsLogin);
  const isFeature = useSelector(selectIsFeature);

  const navigation = useNavigation();

  const {
    data: term,
    error: termsError,
    isLoading: isTermsLoading,
    isSuccess: isTermsSuccess,
    refetch: fetchTerms,
  } = useTermsAndConditionsQuery();

  useFocusEffect(
    React.useCallback(() => {
      fetchTerms();
    }, [])
  );

  const handleAccept = async () => {
    // navigation.goBack();
    dispatch(setFirstLogin(false));

    // navigation.navigate(MAIN_ROUTE);
    // TODO: remove ts-ignore
    if (isFeature) {
      // @ts-ignore
      return navigation.replace(FAKE_SCREEN);
    }
    // @ts-ignore
    return navigation.replace(MAIN_SCREEN);
    // navigation.dispatch(
    //     CommonActions.reset({
    //       index: 0, // Navigate to the first screen in the stack
    //       routes: [{ name: MAIN_ROUTE }], // Replace the stack with MAIN_ROUTE
    //     })
    //   );
  };

  if (isTermsLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <ScrollView
      contentContainerStyle={styles.scrollContainer}
      style={styles.scrollView}
    >
      <View style={styles.container}>
        {term?.html && (
          <RenderHtml
            contentWidth={width - CONTAINER_MARGIN * 2}
            source={{ html: term.html }}
            tagsStyles={tagsStyles}
          />
        )}

        <View style={{ padding: 12 }}></View>

        {isLogin && (
          <PrimaryButton title={i18n.t("accept_btn")} onPress={handleAccept} />
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    marginHorizontal: CONTAINER_MARGIN,
  },
  termsText: {
    color: "white",
    fontSize: 16,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
  },
});

export default TermsAndConditionsScreen;
