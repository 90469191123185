import { useGetNotificationsQuery } from "@/api/apiSlice";
import { INotification } from "@/model/response/notification_model";
import NotificationDetailModel from "@/screens/home/noti/components/NotificationDetailModel";
import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { ActivityIndicator, FlatList, StyleSheet, Text } from "react-native";
import NotificationItem from "./components/NotificationiItem";

const NotificationScreen: React.FC = () => {
  const [page, setPage] = useState(1);

  const [selectedNotification, setSelectedNotification] =
    useState<INotification | null>(null);

  const {
    data: notifications,
    isFetching: isFetching,
    refetch,
  } = useGetNotificationsQuery({
    page: page,
  });

  useFocusEffect(
    useCallback(() => {
      setPage(1);
    }, [])
  );

  const handleLoadMore = () => {
    if (!notifications) return;
    if (page < notifications.meta.last_page && !isFetching) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      {notifications ? (
        <FlatList
          data={notifications?.data}
          keyExtractor={(item, index) => item.id.toString()}
          contentContainerStyle={{ paddingTop: 8, paddingBottom: 24 }}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.1}
          ListFooterComponent={() =>
            isFetching ? (
              <ActivityIndicator
                style={styles.loadingIndicator}
                size="large"
                color="gray"
              />
            ) : null
          }
          renderItem={({ item }) => {
            return (
              // <TouchableOpacity onPress={() => handleNotificationPress(item)}>
              <NotificationItem
                notification={item}
                onPress={setSelectedNotification}
              />
              // </TouchableOpacity>
            );
          }}
        />
      ) : (
        <Text>No Data</Text>
      )}
      <NotificationDetailModel
        notification={selectedNotification}
        onDismiss={() => setSelectedNotification(null)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  loadingIndicator: {
    alignItems: "center",
    marginVertical: 10, // Adjust this value to control the spacing
  },
});

export default NotificationScreen;
