import {
  useGetGameResultsByWeekQuery,
  useGetGameWeekRangeQuery,
} from "@/api/apiSlice";
import Label from "@/components/Label";
import PrimaryGradient from "@/components/PrimaryGradient";
import RectangleNumber from "@/components/RectangleNumber";
import ThemeText from "@/components/ThemeText";
import WinNumberPrize from "@/components/WinNumberPrize";
import {
  accentColor,
  activeGameBorder,
  numberBgPurple,
  primaryBlackColor,
  viewAllBtnBorderColor,
} from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

const ResultsScreen: React.FC = () => {
  const navigation = useNavigation();

  const [selectedActiveGameItemIndex, setSelectedActiveGameItemIndex] =
    useState<number | null>(null);
  const [selectedGameDate, setSelectedGameText] = useState<string | null>(null);
  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [selectedToDate, setSelectedToDate] = useState<string>("");
  const [formattedDate, setFormattedDate] = useState("");

  //api
  const {
    data: activeGamesWeeks,
    error: activeGamesWeeksError,
    isLoading: isActiveGameWeeksLoading,
    isSuccess: isActiveGameWeeksSuccess,
    refetch: refetchGameWeek,
  } = useGetGameWeekRangeQuery();

  const {
    data: gameResults,
    error: gameResultsError,
    isLoading: isGameResultsLoading,
    isSuccess: isGameResultSuccess,
    refetch: fetchGameResultByWeek,
  } = useGetGameResultsByWeekQuery(
    {
      from_date: selectedFromDate,
      to_date: selectedToDate,
    },
    { skip: !selectedFromDate && !selectedToDate }
  );

  useFocusEffect(
    React.useCallback(() => {
      refetchGameWeek();
    }, [])
  );

  useEffect(() => {
    const game = activeGamesWeeks?.at(0);
    if (!game) return;
    setSelectedGameText(game.name);
    setSelectedFromDate(game.from_date);
    setSelectedToDate(game.to_date);
  }, [activeGamesWeeks]);

  if (isGameResultsLoading || isActiveGameWeeksLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  const handleActiveGameItemPress = async (index: number) => {
    setSelectedGameText(activeGamesWeeks?.[index]?.name ?? null);
    setSelectedFromDate(activeGamesWeeks![index].from_date);
    setSelectedToDate(activeGamesWeeks![index].to_date);
    await fetchGameResultByWeek();
  };

  return (
    <ScrollView
      contentContainerStyle={styles.scrollContainer}
      style={styles.scrollView}
    >
      <View style={styles.activeGameContainer}>
        {activeGamesWeeks && isActiveGameWeeksSuccess && (
          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={activeGamesWeeks}
            keyExtractor={(item) => item.name}
            renderItem={({ item, index }) => {
              if (selectedGameDate === activeGamesWeeks[index]?.name) {
                return (
                  <PrimaryGradient style={styles.itemSelectedActiveGame}>
                    <ThemeText
                      weight="medium"
                      style={styles.itemActiveGameText}
                    >
                      {activeGamesWeeks[index]?.name}
                    </ThemeText>
                  </PrimaryGradient>
                );
              } else {
                return (
                  <TouchableOpacity
                    onPress={() => handleActiveGameItemPress(index)}
                    style={[
                      styles.itemActiveGame,
                      selectedActiveGameItemIndex === index &&
                        styles.itemActiveGame,
                    ]}
                  >
                    <ThemeText
                      weight="medium"
                      style={styles.itemActiveGameText}
                    >
                      {activeGamesWeeks[index]?.name}
                    </ThemeText>
                  </TouchableOpacity>
                );
              }
            }}
          />
        )}
      </View>
      {isGameResultSuccess && gameResults && gameResults.data && (
        <>
          {Object.keys(gameResults.data).map((date: string, index) => {
            const resultData = gameResults.data[date];
            const result = gameResults.data[date];
            return (
              <View key={`${index}-${date}`}>
                <View
                  style={{
                    marginTop: 24,
                    marginBottom: 8,
                    marginHorizontal: 20,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Label
                    text={moment(result.result_date).format("MMM DD (ddd)")}
                  />
                  {result.draw_number && (
                    <Label
                      text={`No. ${gameResults!.data[date].draw_number}`}
                    />
                  )}
                </View>

                {result.result_number_1 != null ? (
                  <>
                    <WinNumberPrize
                      rnPaddingHorizontal={4}
                      rnPaddingVertical={8}
                      text={i18n.t("first_prize")}
                      numbers={result.result_number_1.split("").map(Number)}
                    />

                    <WinNumberPrize
                      rnPaddingHorizontal={4}
                      rnPaddingVertical={8}
                      text={i18n.t("second_prize")}
                      numbers={gameResults!.data[date]!.result_number_2!.split(
                        ""
                      ).map(Number)}
                    />
                    <WinNumberPrize
                      rnPaddingHorizontal={4}
                      rnPaddingVertical={8}
                      text={i18n.t("third_prize")}
                      numbers={result.result_number_3!.split("").map(Number)}
                    />
                  </>
                ) : (
                  <View>
                    <Text
                      style={{
                        color: viewAllBtnBorderColor,
                        alignSelf: "center",
                        fontSize: 12,
                      }}
                    >
                      {i18n.t("no_result")}
                    </Text>
                  </View>
                )}

                {result.consolation_prizes && result.starter_prizes && (
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 20,
                      marginRight: 20,
                      gap: 5,
                    }}
                  >
                    <View style={styles.prizeCard}>
                      <Text style={styles.title}>
                        STARTER PRIZES{"\n"}(<Text>{i18n.t("x100")}</Text>)
                      </Text>
                      <View style={styles.prizeContainer}>
                        {result.starter_prizes.map((prize, index) => {
                          const isEven = index % 2 === 0;
                          return (
                            <View
                              key={index}
                              style={{
                                width: "50%",
                                paddingRight: isEven ? 6 : 0,
                                paddingLeft: isEven ? 0 : 6,
                                alignItems: isEven ? "flex-end" : "flex-start",
                              }}
                            >
                              <Text style={styles.prizeText}>{prize}</Text>
                            </View>
                          );
                        })}
                      </View>
                    </View>

                    <View style={styles.prizeCard}>
                      <Text style={styles.title}>
                        CONSOLATION PRIZES{"\n"}(<Text>{i18n.t("x30")}</Text>)
                      </Text>
                      <View style={styles.prizeContainer}>
                        {result.consolation_prizes.map((prize, index) => {
                          const isEven = index % 2 === 0;
                          return (
                            <View
                              key={index}
                              style={{
                                width: "50%",
                                paddingRight: isEven ? 6 : 0,
                                paddingLeft: isEven ? 0 : 6,
                                alignItems: isEven ? "flex-end" : "flex-start",
                              }}
                            >
                              <Text style={styles.prizeText}>{prize}</Text>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            );
          })}
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  activeGameContainer: {
    flex: 1,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
  },
  itemActiveGame: {
    borderWidth: 1,
    borderColor: activeGameBorder,
    borderRadius: 6,
    padding: 7,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  itemSelectedActiveGame: {
    borderRadius: 6,
    padding: 7,
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  itemActiveGameText: {
    textAlign: "center",
    color: "white",
    fontSize: 11,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
    paddingBottom: 32,
  },
  rectContainer: {
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: accentColor,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 9,
  },
  title: {
    // flex: 1,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    color: "white",
    // textAlignVertical: "bottom",
    // textAlignVertical: "bottom",
  },
  prizeCard: {
    flex: 1,
    borderRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: accentColor,
    paddingHorizontal: 8,
    paddingTop: 9,
    paddingBottom: 12,
  },
  prizeContainer: {
    flex: 1,
    // gap: 8,
    marginTop: 12,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    rowGap: 8,
  },
  prizeText: {
    color: "white",
    backgroundColor: numberBgPurple,
    // paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: 3,
    width: 60,
  },
});

export default ResultsScreen;
