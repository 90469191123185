import ThemeText from "@/components/ThemeText";
import { dateText } from "@/constants/ColorConstants";
import { Ticket } from "@/model/response/order_model";
import OrderItem from "@/screens/home/buy-history/components/OrderItem";
import moment from "moment";
import React, { memo } from "react";
import { StyleSheet, Text, View } from "react-native";

const SlipItem = memo(({ slip }: { slip: Ticket }) => {
  const formattedDateTime = moment(slip.created_at).format(
    "ddd, MMMM D YYYY, h:mm A"
  );

  return (
    <View>
      <View style={styles.dateContainer}>
        <ThemeText weight="bold" style={styles.dateText}>
          {formattedDateTime}
        </ThemeText>
        <ThemeText weight="bold" style={{ ...styles.dateText, marginLeft: 10 }}>
          {slip.game.result_date}
        </ThemeText>
      </View>

      {slip.bets.map((bet, index) => (
        <OrderItem
          key={index}
          rnPaddingHorizontal={4}
          rnPaddingVertical={8}
          text="YOUR BET"
          status={bet.status}
          amount={bet.bet_amount}
          numbers={bet.bet_number.split("").map((char) => parseInt(char))}
        />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  dateContainer: {
    flexDirection: "row",
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 12,
    marginTop: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  dateText: {
    fontSize: 12,
    color: dateText,
    textAlign: "center",
  },
});

export default SlipItem;
