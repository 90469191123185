import {
  tabIconSelectColor,
  tabIconUnselectColor,
} from "@/constants/ColorConstants";
import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import ThemeText from "./ThemeText";

export default function BottomTabItem({
  icon,
  label,
  isFocused,
  onPress,
  onLongPress,
}: {
  icon: React.ReactNode;
  label: string;
  isFocused: boolean;
  onPress: () => void;
  onLongPress: () => void;
}) {
  return (
    <Pressable
      onPress={onPress}
      onLongPress={onLongPress}
      style={({ pressed }) => [styles.container, pressed && { opacity: 0.65 }]}
    >
      {({ pressed }) => (
        <>
          {icon}
          <ThemeText
            weight="bold"
            style={[styles.label, isFocused && styles.active]}
          >
            {label}
          </ThemeText>
        </>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    marginTop: 2,
    color: tabIconUnselectColor,
    fontSize: 12,
  },
  active: {
    color: tabIconSelectColor,
  },
});
