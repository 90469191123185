import React from 'react';
import { View, Text, TouchableOpacity, Modal, StyleSheet } from 'react-native';

interface BetConfirmPopupProps {
  isVisible: boolean;
  onCancel: () => void;
 
}

const ComingSoonPopup: React.FC<BetConfirmPopupProps> = ({
  isVisible,
  onCancel,
  
}) => {
  return (
    <Modal visible={isVisible} transparent>
      <View style={styles.modalContainer}>
        <View style={styles.popupContainer}>
          <Text style={styles.title}>Coming Soon</Text>
          <Text style={styles.description}>
           We are coding.
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
              <Text style={[styles.buttonText, { color: 'blue' }]}>Close</Text>
            </TouchableOpacity>
          
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(58, 57, 57, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  popupContainer: {
    backgroundColor: 'black',
    borderRadius: 16,
    padding: 26,
    marginHorizontal: 20,
    alignItems: 'center',
    shadowColor: 'rgba(98, 95, 95, 0.5)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 10,
  },
  description: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cancelButton: {
    // backgroundColor: 'gray',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
  confirmButton: {
    // backgroundColor: 'purple',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
  buttonText: {
    color: '#0A84FF',
    fontWeight: 'bold',
  },
});

export default ComingSoonPopup;
