import * as React from "react";
import Svg, { Path, Polygon } from "react-native-svg";

const AnnouncementLogo = (props: any) => (
  <Svg width={24} height={24} viewBox="0 0 64 64" {...props}>
    <Polygon
      fill="#f59558"
      points="32.06,59.76 21.23,51.95 17.48,55.69 29,64 "
    />

    <Path
      fill="#f07a67"
      d="m 11.79,38.51 -7.75,7.76 c -0.42,0.42 -0.65,0.97 -0.65,1.55 0,0.59 0.23,1.14 0.65,1.56 l 7.2,7.2 c 0.41,0.41 0.97,0.64 1.55,0.64 0.59,0 1.14,-0.23 1.55,-0.64 l 7.75,-7.76 z m 9.86,-22.61 -0.14,-0.01 -1.37,3.34 0.22,0.06 c 8.75,2.41 18.55,12.21 20.97,20.96 l 0.06,0.22 3.34,-1.37 -0.01,-0.14 C 44.12,29.42 31.18,16.49 21.65,15.9 Z"
    />

    <Path
      fill="#f59558"
      d="M 19.69,20.76 19.52,20.72 12.73,37.19 23.41,47.87 39.88,41.08 39.84,40.91 C 37.68,32.54 28.07,22.94 19.69,20.76 Z m 20.82,0.54 c 0.32,0 0.62,-0.12 0.85,-0.35 l 7.62,-7.62 c 0.47,-0.47 0.47,-1.23 0,-1.7 -0.23,-0.23 -0.53,-0.35 -0.85,-0.35 -0.32,0 -0.62,0.12 -0.85,0.35 l -7.62,7.62 c -0.23,0.23 -0.35,0.53 -0.35,0.85 0,0.32 0.12,0.62 0.35,0.85 0.23,0.22 0.53,0.35 0.85,0.35 z m 20.06,2.16 c -0.14,-0.53 -0.62,-0.89 -1.16,-0.89 -0.1,0 -0.2,0.01 -0.3,0.04 l -12.07,3.17 c -0.64,0.17 -1.02,0.83 -0.85,1.46 0.14,0.53 0.61,0.89 1.16,0.89 0.1,0 0.21,-0.01 0.31,-0.04 l 12.07,-3.17 c 0.63,-0.17 1.01,-0.82 0.84,-1.46 z m -27.2,-9.04 c 0.1,0.03 0.2,0.04 0.31,0.04 0.54,0 1.02,-0.37 1.16,-0.89 L 38.01,1.5 C 38.18,0.86 37.8,0.2 37.16,0.04 37.05,0.01 36.95,0 36.85,0 36.3,0 35.83,0.37 35.69,0.89 l -3.17,12.07 c -0.17,0.64 0.21,1.29 0.85,1.46 z"
    />
  </Svg>
);
export default AnnouncementLogo;
