import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import TimeBgWithProgress from "../../../../components/svgs/home/TimeBgWithProgress";
import { winnerCardBorderColor } from "../../../../constants/ColorConstants";

type Props = {
  time: string;
  name: string;
};

type NextTimeProps = {
  targetedDate: string;
};

const TimeText: React.FC<Props> = ({ time, name }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.time}>{time}</Text>
      <Text style={styles.name}>{name}</Text>
    </View>
  );
};

const NextLotteryTime: React.FC<NextTimeProps> = ({ targetedDate }) => {
  const targetDate = new Date(targetedDate);

  const calculateRemainingTime = () => {
    const currentDate = new Date();
    const timeDiff = targetDate.getTime() - currentDate.getTime();

    const remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const remainingMinutes = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );
    const remainingSeconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return [remainingDays, remainingHours, remainingMinutes, remainingSeconds];
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: -2,
        zIndex: -1,
      }}
    >
      <TimeBgWithProgress />
      <View style={styles.timeContainer}>
        <View style={styles.row}>
          <TimeText time={remainingTime[0].toString()} name="Days" />
          {/* <View style={styles.line} /> */}
          <Text style={{ color: "white", fontSize: 20 }}>:</Text>
          <TimeText time={remainingTime[1].toString()} name="Hours" />
          {/* <View style={styles.line} /> */}
          <Text style={{ color: "white", fontSize: 20 }}>:</Text>
          <TimeText time={remainingTime[2].toString()} name="Minutes" />
          {/* <View style={styles.line} /> */}
          <Text style={{ color: "white", fontSize: 20 }}>:</Text>
          <TimeText time={remainingTime[3].toString()} name="Seconds" />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  timeContainer: {
    position: "absolute",
    flex: 1,
    width: "100%",
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // borderWidth: 1,
    // borderColor: winnerCardBorderColor,
    // backgroundColor: winnerCardBackgroundColor,
    height: 75,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 8,
    marginTop: -5,
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  line: {
    width: 1,
    height: "60%",
    backgroundColor: winnerCardBorderColor,
  },
  time: {
    fontSize: 18,
    fontWeight: "700",
    color: "white",
  },
  name: {
    fontSize: 12,
    fontWeight: "500",
    color: "white",
  },
});

export default NextLotteryTime;
