import errorSlice from "@/store/error";
import loadingSlice from "@/store/loading";
import networkSlice from "@/store/network";
import viberSlice from "@/store/viber";
import { configureStore } from "@reduxjs/toolkit";
import { apiSlice, createRtkQueryErrorLogger } from "../api/apiSlice";
import authReducer from "./auth/auth_reducer";
import termAndConditionReducer from "./auth/term_and_condition_reducer";
import betSlice from "./bet";
import { updateSlice } from "./update";

// let store: ReturnType<typeof configureStore>;

// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//   },
// });

// export default store;

// export const getStore = () => {
//   if (!store) {
//     store = configureStore({
//       reducer: {
//         [apiSlice.reducerPath]: apiSlice.reducer,
//         auth: authReducer,
//       },
//       middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware().concat(apiSlice.middleware),
//     });

//     setupListeners(store.dispatch);
//   }

//   return store;
// };

const rtkQueryErrorLogger = createRtkQueryErrorLogger();
export const store = configureStore({
  reducer: {
    api: apiSlice.reducer,
    viber: viberSlice.reducer,
    bet: betSlice.reducer,
    network: networkSlice.reducer,
    loading: loadingSlice.reducer,
    auth: authReducer,
    error: errorSlice.reducer,
    terms: termAndConditionReducer,
    update: updateSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
