import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SVGComponent = (props :any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <Path
      d="M13.0039 16.941V19.0029H18.0039V21.0029H6.00391V19.0029H11.0039V16.941C7.0576 16.4489 4.00391 13.0825 4.00391 9.00293V3.00293H20.0039V9.00293C20.0039 13.0825 16.9502 16.4489 13.0039 16.941ZM1.00391 5.00293H3.00391V9.00293H1.00391V5.00293ZM21.0039 5.00293H23.0039V9.00293H21.0039V5.00293Z"
      fill="#8852EB"
    />
  </Svg>
);
export default SVGComponent;
