import { AppDispatch } from "@/store/store";
import { openViber } from "@/store/viber";
import React from "react";
import { useDispatch } from "react-redux";
import DraggableFloatingButton from "../../../../components/DraggableFloatingButton";

export default function ViberButton() {
  const dispatch = useDispatch<AppDispatch>();

  const onViberOpen = async () => {
    dispatch(openViber());
  };

  return <DraggableFloatingButton onPress={() => onViberOpen()} text={""} />;
}
