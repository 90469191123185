import { useGetAvatarsQuery } from "@/api/apiSlice";
import { primaryBlackColor } from "@/constants/ColorConstants";
import { AvatarModel } from "@/model/response/avatar_model";
import AvatarCard from "@/screens/avatar/component/AvatarCard";
import { selectProfile } from "@/store/auth/auth_reducer";
import React from "react";
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

const AvatarScreen: React.FC = () => {
  const { data: avatars } = useGetAvatarsQuery();

  const profile = useSelector(selectProfile);

  if (!profile || !avatars) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: primaryBlackColor,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }
  const level = parseInt(profile.level);
  return (
    // <View style={styles.container}>
    <FlatList
      contentContainerStyle={{
        // alignItems: "center",
        padding: 20,
      }}
      data={avatars.reduce<AvatarModel[]>(
        (acc, cur) => [...acc, ...cur.avatars],
        []
      )}
      numColumns={3}
      keyExtractor={(item) => item.id.toString()}
      renderItem={({ item, index }) => {
        return <AvatarCard avatar={item} level={level} />;
      }}
    />
    // </View>
  );
};

const { width } = Dimensions.get("window");
const itemWidth = width / 5;
const styles = StyleSheet.create({
  container: {
    padding: 16,
    alignItems: "center",
  },
  item: {
    width: itemWidth,
    marginBottom: 16,
    alignItems: "center",
  },
  image: {
    width: itemWidth - 16, // Adjust the image width as needed
    height: itemWidth - 16, // Adjust the image height as needed
    resizeMode: "cover",
    borderRadius: 8,
  },
  avatarListContainer: {
    paddingHorizontal: 10,
    paddingTop: 10,
  },
});

export default AvatarScreen;
