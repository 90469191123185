const en = {
  salone_four_d: "Salone 4D",

  profile: "Profile",
  avatar: "Avatar",
  buy_ticket: "Buy Ticket",

  error: "Error",
  success: "Success",

  login_title: "Login",
  login_btn: "Login",
  forgot_password: "Forgot Password?",
  click_here: "Click Here",
  create_account_btn: "Create Account",

  download_app: "Download App",

  register_title: "Create An Account",
  full_name_placeholder: "Name",
  phone_number_placeholder: "Phone Number",
  password_placeholder: "Password",
  password_confirmation_placeholder: "Confirm Password",
  referral_code_placeholder: "Referral Code (If not, leave it)",
  terms_and_conditions_btn: "Terms and Conditions",
  register_btn: "Register",

  home_tab_label: "Home",
  results_tab_label: "Results",
  buy_tab_label: "Buy",
  notifications_tab_label: "Noti",
  cash_tab_label: "Cash",

  this_week_winner: "THIS WEEK WINNER",
  view_all_winner: "View All Winner",
  first_prize: "1ST PRIZE",
  second_prize: "2ND PRIZE",
  third_prize: "3RD PRIZE",
  view_all_result: "View All Result",
  next_lottery_time: "NEXT LOTTERY TIME",
  cash_in: "CASH IN",
  cash_out: "CASH OUT",
  top_up: "Top Up",
  withdraw: "Withdraw",
  ks: "Ks",
  available_balance: "Available Balance",

  welcome_back: "Welcome Back!",
  change_language: "Change Language",
  change_password: "Change Password",
  your_lucky_draw_history: "Your Luckydraw History",
  your_balance_history: "Your Balance History",
  contact_us: "Contact Us",
  about_us: "About Us",
  referral_code: "Referral Code",
  logout: "Logout",

  english: "English",
  burmese: "Burmese",
  cancel: "Cancel",
  next: "Next",
  back: "Back",
  bet_add: "add-outline",
  bet_add_more_number: "Add Number",
  choose_four_numbers: "Choose 4 Numbers",
  selected_numbers: "Selected Number(s)",
  buy: "Buy",
  clear_all: "Clear All",
  total_cost: "Total Cost",
  close: "Close",
  confirm: "Confirm",
  bet_confirm_title: "Confirmation",
  bet_confirm_message:
    "Do you want to buy this number and are you really confirm those numbers?",
  bet_success_all_done: "All Done!",
  bet_success_check_order_history: "Check Order History",

  viber_dialog_title: "Can't open Viber!",
  viber_dialog_description: "Please contact us at %{phone_number}.",

  minimum_top_up_amount_help_text: "Please top up at least 1000",
  top_up_form_button: "Top Up",
  top_up_success_message: "Top up successfully",

  withdraw_title: "Withdraw",
  withdraw_amount_placeholder: "Enter Amount",
  withdraw_account_name_placeholder: "Enter Account No",
  withdraw_payment_info_placeholder: "Enter Name",
  withdraw_form_button: "Withdraw",
  withdraw_success_message: "Withdraw successfully",

  top_up_title: "Top Up",
  top_up_amount_placeholder: "Enter Amount",
  top_up_phone_number_placeholder: "Enter kbzpay/wave no",
  select_screenshot_btn: "Select Screenshot",

  terms_and_conditions_page_title: "TERMS AND CONDITIONS",
  accept_btn: "Accept",

  change_password_form_button: "Change",
  change_password_success_message: "Password has been changed successfully",
  x100: "x100",
  x30: "x30",
  top_up_amount_required: "Please enter amount",
  top_up_amount_invalid: "Please enter valid amount",
  top_up_phone_number_required: "Please enter phone number",
  top_up_screenshot_key_required: "Please select screenshot",
  withdraw_amount_required: "Please enter amount",
  withdraw_amount_invalid: "Please enter valid amount",
  withdraw_account_name_required: "Please enter account no.",
  withdraw_payment_info_required: "Please enter name.",

  no_result: "The lottery result is not out yet",
};

export default en;
