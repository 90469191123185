import PrimaryGradient from "@/components/PrimaryGradient";
import BuyTicketSvg from "@/components/svgs/navigation/BuyTicketSvg";
import i18n, { t } from "@/lang/i18n";
import { BUY_FLOW_SCREEN } from "@/routes/Routes";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import ThemeText from "./ThemeText";

export default function BuyTabItem() {
  const navigation = useNavigation();
  return (
    <Pressable
      style={({ pressed }) => [styles.container, pressed && { opacity: 0.65 }]}
      onPress={function (e) {
        // TODO: remove ts-ignore
        // @ts-ignore
        navigation.navigate(BUY_FLOW_SCREEN);
        // e.stopPropagation();
      }}
    >
      <PrimaryGradient style={styles.gradient}>
        <View style={styles.itemContainer}>
          <BuyTicketSvg />
          <ThemeText
            weight="bold"
            style={{
              color: "white",
              fontSize: 12,
              marginTop: 4,
            }}
          >
            {i18n.t("buy_tab_label")}
          </ThemeText>
        </View>
      </PrimaryGradient>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: -20,
    width: 75, // Adjust the width and height to determine the size of the gradient circle
    height: 75,
    borderRadius: 100, // Make sure the borderRadius is half the width/height value for a perfect circle
    overflow: "hidden",
    shadowColor: "#000000", // Shadow color
    shadowOffset: { width: 0, height: 2 }, // Shadow offset
    shadowOpacity: 0.5, // Shadow opacity
    shadowRadius: 4, // Shadow radius
    elevation: 5, // Elevation (for Android)
  },
  itemContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  gradient: {
    flex: 1,
  },
});
