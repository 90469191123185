import React, { useEffect } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { useChangePasswordMutation, useWithdrawMutation } from "@/api/apiSlice";
import AlertDialog from "@/components/AlertDialog";
import EditText from "@/components/EditText";
import PrimaryButton from "@/components/PrimaryButton";
import ThemeText from "@/components/ThemeText";
import { accentColor, primaryBlackColor } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { WithdrawRequestModel } from "@/model/request/withdraw_request_model";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import { WITHDRAW_SCREEN } from "@/routes/Routes";
import { setLoading } from "@/store/loading";
import { AppDispatch } from "@/store/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { Picker } from "@react-native-picker/picker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { Nunito_600SemiBold } from "@/constants/fonts";
import { IChangePasswordRequest } from "@/model/request/change_password_request_model";

type PageProps = IRootStackScreenProps<typeof WITHDRAW_SCREEN>;

const scheme = z.object({
  old: z
    .string({
      required_error: "Please enter old password",
    })
    .trim(),
  new: z
    .string({
      required_error: "Please enter new password",
    })
    .trim()
    .min(6, "Password must be at least 6 characters long"),
  // confirm: z
  //   .string({
  //     required_error: "Please enter confirm password",
  //   })
  //   .trim(),
});

export default function ChangePasswordScreen({ navigation }: PageProps) {
  const dispatch = useDispatch<AppDispatch>();

  const { control, handleSubmit } = useForm<IChangePasswordRequest>({
    shouldFocusError: true,
    mode: "onChange",
    resolver: zodResolver(scheme),
  });

  //api
  const [changePassword, { isLoading, isError, error, isSuccess, reset }] =
    useChangePasswordMutation();

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
      >
        <EditText
          control={control}
          name="old"
          placeholder="Old Password"
          // keyboardType="numeric"
          autoCapitalize="none"
          secureTextEntry
          autoCorrect={false}
        />

        <EditText
          control={control}
          name="new"
          placeholder="New Password"
          autoCapitalize="none"
          secureTextEntry
          autoCorrect={false}
        />

        {/* <EditText
          control={control}
          name="confirm"
          placeholder="Confirm Password"
          autoCapitalize="none"
          secureTextEntry
          autoCorrect={false}
        /> */}

        <View style={{ marginTop: "auto", paddingBottom: 32 }}>
          <PrimaryButton
            onPress={handleSubmit((data) => {
              changePassword(data);
            })}
            title={i18n.t("change_password_form_button")}
          />
        </View>

        <AlertDialog
          visible={isSuccess}
          title={i18n.t("success")}
          description={i18n.t("change_password_success_message")}
          actions={[
            {
              title: "OK",
              onPress: () => {
                navigation.goBack();
                reset();
              },
            },
          ]}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    paddingHorizontal: 20,
    marginTop: 24,
    // paddingBottom: 32,
  },
  scrollView: {
    width: "100%",
    height: "100%",
    backgroundColor: primaryBlackColor,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingTop: 24,
    paddingHorizontal: 20,
    backgroundColor: primaryBlackColor,
  },
  inputContainer: {
    marginBottom: 16,
  },
  input: {
    height: 58,
    paddingHorizontal: 16,
    // paddingVertical: 15,
    alignItems: "center",
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
    color: "white",
    fontSize: 16,
  },
  error: {
    color: "#ff0000",
    fontSize: 14,
    marginTop: 5,
  },
});
