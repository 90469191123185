import React from "react";
import { Image, StyleSheet } from "react-native";

const CustomHeaderImage = () => {
  return (
    <Image
      style={styles.image}
      source={require("@assets/header.png")} // Replace with your image path
    />
  );
};

const styles = StyleSheet.create({
  image: {
    width: 113,
    height: 18,
  },
  // container: {
  //     flex: 1,
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     backgroundColor: 'black', // Set the background color to black
  //   },
});

export default CustomHeaderImage;
