import BlackLabel from "@/components/BlackLabel";
import WinNumberPrize from "@/components/WinNumberPrize";
import * as React from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import NextLotteryTime from "./components/NextLotteryTime";
import WinnerCard from "./components/WinnerCard";
import WinnerLabel from "./components/WinnerLabel";

import {
  useGetBannersQuery,
  useGetLatestResultQuery,
  useGetNextTimeLotteryQuery,
  useGetTopWinnersQuery,
} from "@/api/apiSlice";
import PrimaryButton from "@/components/PrimaryButton";
import i18n, { t } from "@/lang/i18n";
import { RESULT_ROUTE, TERMS_AND_CONDITIONS } from "@/routes/Routes";
import CarouselSlider from "@/screens/home/dashboard/components/CarouselSlider";
import ViberButton from "@/screens/home/dashboard/components/ViberButton";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import moment from "moment";
import { useEffect } from "react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import NextLotteryLabel from "./components/NextLotteryTimeLabel";

function DashboardScreen() {
  const navigation = useNavigation();

  const {
    data: banners,
    error: bannersError,
    isLoading: isBannerLoading,
    refetch: refetchBanners,
  } = useGetBannersQuery();

  const {
    data: nextTimeLotteryData,
    isLoading: isNextTimeLoading,
    isSuccess: isNextTimeSuccess,
    refetch: refetchNextTime,
  } = useGetNextTimeLotteryQuery();

  const {
    data: latestGameResult,
    isLoading: isLatestGameResultLoading,
    isSuccess: isLatestGameResultSuccess,
    refetch: refreshLatestResult,
  } = useGetLatestResultQuery();

  const { data: topWinners, isSuccess: isTopWinnerSuccess } =
    useGetTopWinnersQuery();

  const isFirstLoginTerms = useSelector((state: any) => state.terms.firstLogin);

  const { width, height } = Dimensions.get("window");

  useEffect(() => {
    if (isFirstLoginTerms) {
      // @ts-ignore
      navigation.replace(TERMS_AND_CONDITIONS);
    }
  }, [isFirstLoginTerms]);

  useFocusEffect(
    React.useCallback(() => {
      refetchBanners();
      refetchNextTime();
      refreshLatestResult();
    }, [])
  );

  const handleViewAllResultPress = () => {
    // @ts-ignore
    navigation.navigate(RESULT_ROUTE);
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ViberButton />
      <ScrollView
        contentContainerStyle={styles.scrollContainer}
        style={styles.scrollView}
        // simultaneousHandlers={[panGestureRef]}
      >
        <View
          style={{
            marginTop: 20,
            justifyContent: "center",
          }}
        >
          {isBannerLoading ? (
            <View></View>
          ) : banners?.length! > 0 ? (
            <CarouselSlider data={banners!} />
          ) : (
            // <CarouselSlider data={banners!} />
            // <></>
            <></>
            // <CarouselSlider data={banners!} />
            // <DummyCoverSvg />
          )}
        </View>
        <View style={{ margin: 15.5 }} />

        {topWinners && topWinners.data.length > 0 ? (
          <>
            <WinnerLabel text={i18n.t("this_week_winner")} />
            <WinnerCard topWinners={topWinners} />
          </>
        ) : (
          <></>
        )}

        <View style={{ margin: 14.5 }} />

        {latestGameResult && (
          <>
            <View
              style={{
                flexDirection: "row",
                marginHorizontal: 42,
                justifyContent: "space-between",
              }}
            >
              <BlackLabel
                width={100}
                text={moment(latestGameResult.result_date).format(
                  "MMM D (ddd)"
                )}
              />

              {latestGameResult.draw_number && (
                <BlackLabel
                  width={80}
                  text={"No. " + latestGameResult.draw_number}
                />
              )}
            </View>

            <WinNumberPrize
              rnPaddingHorizontal={4}
              rnPaddingVertical={8}
              text={i18n.t("first_prize")}
              numbers={latestGameResult!.result_number_1.split("").map(Number)}
            />
            <WinNumberPrize
              rnPaddingHorizontal={4}
              rnPaddingVertical={8}
              text={i18n.t("second_prize")}
              numbers={latestGameResult!.result_number_2.split("").map(Number)}
            />
            <WinNumberPrize
              rnPaddingHorizontal={4}
              rnPaddingVertical={8}
              text={i18n.t("third_prize")}
              numbers={latestGameResult!.result_number_3.split("").map(Number)}
            />
          </>
        )}

        <View style={{ margin: 8.5 }} />
        <PrimaryButton
          buttonStyle={{ marginHorizontal: 20 }}
          onPress={handleViewAllResultPress}
          title={i18n.t("view_all_result")}
        />
        <View style={{ margin: 20.5 }} />
        {nextTimeLotteryData ? (
          <>
            <NextLotteryLabel text={i18n.t("next_lottery_time")} />
            <NextLotteryTime targetedDate={nextTimeLotteryData.result_date!} />
          </>
        ) : (
          <></>
        )}
        <View style={{ margin: 33 }} />
      </ScrollView>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  safeAreaContainer: {
    flex: 1,
  },
  scrollView: {
    width: "100%",
    height: "100%",
  },
  scrollContainer: {
    justifyContent: "flex-start",
  },
  bannerLoadingContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default DashboardScreen;
