import BottomTabItem from "@/components/BottomTabItem";
import BuyTabItem from "@/components/BuyTabItem";
import { TAB_BAR_HEIGHT } from "@/constants/size";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import React from "react";
import { View } from "react-native";

export default function AppBottomTabBar({
  navigation,
  state,
  descriptors,
}: BottomTabBarProps) {
  const start = state.routes.filter((route, index) => index < 2);
  const end = state.routes.filter((route, index) => index > 1);

  const focusedRouteKey = state.routes[state.index].key;

  function renderRoute(
    route: BottomTabBarProps["state"]["routes"][number],
    index: number
  ) {
    const { options } = descriptors[route.key];

    const isFocused = focusedRouteKey === route.key;

    const onPress = () => {
      const event = navigation.emit({
        type: "tabPress",
        target: route.key,
        canPreventDefault: true,
      });

      if (!isFocused && !event.defaultPrevented) {
        // The `merge: true` option makes sure that the params inside the tab screen are preserved
        // @ts-ignore
        navigation.navigate({ name: route.name, merge: true });
      }
    };

    const onLongPress = () => {
      navigation.emit({
        type: "tabLongPress",
        target: route.key,
      });
    };

    return (
      <BottomTabItem
        key={route.key}
        onPress={onPress}
        onLongPress={onLongPress}
        icon={options.tabBarIcon!({
          focused: isFocused,
          color: "#fff",
          size: 24,
        })}
        label={
          typeof options.tabBarLabel === "string" ? options.tabBarLabel : ""
        }
        isFocused={isFocused}
      />
    );
  }

  return (
    <View
      style={{
        height: TAB_BAR_HEIGHT,
        flexDirection: "row",
        backgroundColor: "#423A4D",
        position: "relative",
      }}
    >
      {start.map(renderRoute)}
      <BuyTabItem />
      {end.map(renderRoute)}
    </View>
  );
}
