import { useGetBalanceQuery } from "@/api/apiSlice";
import PrimaryButton from "@/components/PrimaryButton";
import ThemeText from "@/components/ThemeText";
import ConfirmSuccessSvg from "@/components/svgs/buyflow/ConfirmSucccessSvg";
import { purpleText } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import { BET_CONFIRM_SUCCESS_SCREEN, BUY_ORDER_HISTORY } from "@/routes/Routes";
import { formatMoney } from "@/utils/format";
import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Circle } from "react-native-progress";

type PageProps = IRootStackScreenProps<typeof BET_CONFIRM_SUCCESS_SCREEN>;

export default function BuyConfirmSuccessScreen({
  navigation,
  route,
}: PageProps) {
  const { total_amount: totalAmount, total_ticket: totalTicket } = route.params;
  const {
    data: balance,
    isFetching,
    refetch: refreshBalance,
  } = useGetBalanceQuery();

  useFocusEffect(
    useCallback(() => {
      refreshBalance();
    }, [])
  );

  if (isFetching || !balance)
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Circle size={30} indeterminate={true} />
      </View>
    );

  const handleBackPress = async () => {
    navigation.goBack();
  };

  const handleCheckOrderPress = async () => {
    navigation.replace(BUY_ORDER_HISTORY);
  };

  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <ConfirmSuccessSvg />
        <View style={styles.textContainer}>
          <ThemeText weight="extra-bold" style={styles.titleText}>
            {i18n.t("bet_success_all_done")}
          </ThemeText>
        </View>
        {i18n.locale === "en" ? (
          <ThemeText style={styles.briefText}>
            You just bought {totalTicket} tickets{" "}
            <ThemeText style={styles.dynamicText}>
              {formatMoney(totalAmount)} Ks
            </ThemeText>{" "}
            and your remaining balance is{" "}
            <Text style={styles.dynamicText}>34,000 Ks</Text>.
          </ThemeText>
        ) : (
          <ThemeText style={styles.briefText}>
            {"လက်မှတ်များကို ဝယ်ယူပြီး "}
            <ThemeText style={styles.dynamicText}>
              {formatMoney(totalAmount) + " " + i18n.t("ks") + " "}
            </ThemeText>
            {"လက်ကျန်မှာ "}
            <ThemeText style={styles.dynamicText}>
              {formatMoney(balance) + " " + i18n.t("ks") + " "}
            </ThemeText>
            {"ဖြစ်သည်။"}
          </ThemeText>
        )}
      </View>

      <View style={{ marginHorizontal: 20 }}>
        <PrimaryButton
          onPress={handleCheckOrderPress}
          title={i18n.t("bet_success_check_order_history")}
        />

        <TouchableOpacity
          style={styles.nextButtonEnableContainer}
          onPress={handleBackPress}
        >
          <Text style={styles.nextButtonText}>{i18n.t("back")}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  upperContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 20,
  },
  dynamicText: {
    fontWeight: "bold",
    color: purpleText,
  },

  container: {
    flex: 1,
    marginBottom: 40,
    // alignItems: "center",
  },
  textContainer: {
    margin: 5,
  },
  titleText: {
    color: "white",
    fontSize: 20,
  },
  briefText: {
    marginHorizontal: 10,
    color: "white",
    fontSize: 14,
    textAlign: "center",
  },
  nextButtonEnableContainer: {
    borderRadius: 8,
    paddingTop: 15,
    paddingBottom: 5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  nextButtonText: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    // color: numberArray.length === 0 ? 'grey' : 'white',
  },
});
