import apiSlice, { API_URL } from "@/api/apiSlice";
import useNetworkListener from "@/hooks/useNetworkListener";
import i18n from "@/lang/i18n";
import { setToken } from "@/store/auth/auth_reducer";
import { store } from "@/store/store";
import {
  getAppLatestUpdatedTime,
  getAuthToken, saveAppLatestUpdatedTime,
} from "@/utils/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import CodePush from "react-native-code-push";
import * as SplashScreen from "expo-splash-screen";
import { Alert, Platform } from "react-native";
import { UNKNOWN_ERROR, setError } from "@/store/error";
import captureException from "@/utils/sentry";
import * as Updates from 'expo-updates';
import { cacheFonts } from "@/utils/cache";
import fonts from "@/constants/fonts";
import delay from "@/utils/delay";
import { setProgress } from "@/store/update";
import { BUILD_NUMBER } from "@/constants/system";

async function loadAssets() {
  const fontAssets = cacheFonts(
    Object.keys(fonts).map((font) => {
      return {
        [font]: fonts[font as keyof typeof fonts],
      };
    })
  );

  return Promise.all(fontAssets);
}

async function loadLanguage() {
  const language = await AsyncStorage.getItem("selected_lang");
  if (language) {
    i18n.locale = language!;
  } else {
    i18n.locale = "my";
  }
}

async function checkBuildNumber() {
  if (Platform.OS === "web") return false;

  const response = await fetch(API_URL + "app-version/check");
  // const data = await response.json();
  // const response = await store.dispatch(
  //   apiSlice.endpoints.getAppVersion.initiate()
  // );

  if (response.ok) {
    const { data: {build_number} } = (await response.json()) as { data: {build_number: number} };
    if (build_number > BUILD_NUMBER) {
      return true;
    }
  }

  return false;
}

async function getAndCheckToken() {
  const token = await getAuthToken();
  if (!token) return null;
  
  const response = await store.dispatch(
    apiSlice.endpoints.getMe.initiate(token)
  );

  if (response.isSuccess) return token;

  return null;
}

async function checkUpdate() {

  if (__DEV__ || Platform.OS === "web") {
    return;
  }

  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      store.dispatch(setProgress(0));
      await Updates.fetchUpdateAsync();
      await saveAppLatestUpdatedTime(Date.now());
      await Updates.reloadAsync();
    }
  } catch (error) {
    // You can also add an alert() to see the error message in case of an error when fetching updates.
    // alert(`Error fetching latest Expo update: ${error}`);
  }
}

export default function useAppSetup() {
  useNetworkListener();

  const dispatch = useDispatch();

  const [appIsReady, setAppIsReady] = useState(false);

  const appIsReadyRef = useRef(appIsReady);

  appIsReadyRef.current = appIsReady;

  useEffect(() => {
    async function setup() {
      await delay(500);
      try {
        const [latestUpdatedTime] = await Promise.all([
          getAppLatestUpdatedTime(),
          SplashScreen.hideAsync(),
          loadAssets(),
        ]);
       
        const now = Date.now();
        const diff = now - latestUpdatedTime;
        const minutes = Math.floor(diff / (1000 * 60));

        if (latestUpdatedTime === 0 || (latestUpdatedTime > 0 && minutes > 3)) {
          await checkUpdate();
        }
      } catch (error) {
        dispatch(
          setError({
            type: UNKNOWN_ERROR,
            message: "Something went wrong.\nPlease contact us",
          })
        );
        captureException(error);
      } finally {
        const [token, _] = await Promise.all([
          getAndCheckToken(),
          loadLanguage(),
        ]);
        if (token) {
          dispatch(setToken(token));
        }
        await saveAppLatestUpdatedTime(0);
        setAppIsReady(true);
      }
    }
    setup();
  }, []);

  return {
    appIsReady: appIsReady,
  };
}
