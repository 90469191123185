import { useGetBalanceQuery } from "@/api/apiSlice";
import ThemeText from "@/components/ThemeText";
import { purpleText } from "@/constants/ColorConstants";
import i18n, { t } from "@/lang/i18n";
import { selectProfileBalance } from "@/store/auth/auth_reducer";
import { selectSelectedGame, selectTotalBetItems } from "@/store/bet";
import { formatDate, formatMoney } from "@/utils/format";
import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

export default function CartHeader() {
  const selectedGame = useSelector(selectSelectedGame);
  const totalBetItems = useSelector(selectTotalBetItems);
  const balance = useSelector(selectProfileBalance);

  const { refetch } = useGetBalanceQuery();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [])
  );

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <ThemeText style={{ fontSize: 13, opacity: 0.6 }}>
            {i18n.t("selected_numbers")}{" "}
          </ThemeText>
          <ThemeText
            style={{ fontSize: 16, color: purpleText }}
            weight="semi-bold"
          >
            {totalBetItems}
          </ThemeText>
        </View>

        <ThemeText style={{ fontSize: 13, opacity: 0.8 }}>
          {i18n.t("available_balance")}
        </ThemeText>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        {selectedGame && (
          <ThemeText style={{ fontSize: 13 }} weight="bold">
            {formatDate(selectedGame.result_date)}
          </ThemeText>
        )}

        {balance !== undefined && (
          <ThemeText style={{ color: purpleText }} weight="bold">
            {formatMoney(balance) + " " + t("ks")}
          </ThemeText>
        )}
      </View>
    </View>
  );
}
