import React from "react";
import {
  Defs,
  LinearGradient,
  Path,
  Stop,
  Svg,
  SvgProps,
} from "react-native-svg";

export default function LogoSvg({ ...props }: SvgProps & { width?: number }) {
  let { width } = props;

  const ratio = 1.587301587301587;

  const height = (width ? width : 100) * ratio;

  return (
    <Svg width={width} height={height} {...props} viewBox="0 0 100 63">
      <Path
        d="M25.5111 63V53.0984H0V37.2883L25.9177 0.0999514H42.7359V0H67.2715C76.8626 0 85.0556 3.01789 90.9617 8.72801C96.8678 14.4381 99.9936 22.3101 99.9936 31.5008C99.9936 36.0309 99.2157 40.2869 97.6794 44.1527C96.1846 47.9577 93.8945 51.3972 90.9633 54.2397C85.0427 59.9708 76.8482 63 67.265 63H25.5111ZM66.4133 43.3563C70.341 43.3563 73.3527 42.3488 75.3648 40.3594C77.3769 38.37 78.3894 35.3941 78.3894 31.5008C78.3894 23.6417 74.2479 19.4873 66.4133 19.4873H64.1119V43.3563H66.4133Z"
        fill="white"
      />
      <Path
        d="M67.2713 4.03027H46.7535V37.7961H42.5124V4.13022H28.0115L4.01758 38.557V49.068H29.5286V58.9697H42.5124V49.068H46.7535V58.9697H67.2713C84.7485 58.9697 95.9821 47.7783 95.9821 31.5008C95.9821 15.1442 84.7485 4.03027 67.2713 4.03027ZM30.4399 37.7961H17.6779L30.4334 18.4507L30.4399 37.7961ZM66.4131 47.3866H60.094V15.457H66.4131C76.4784 15.457 82.407 21.4041 82.407 31.5008C82.407 41.5975 76.4784 47.3866 66.4131 47.3866Z"
        fill="url(#paint0_linear_1306_1577)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_1306_1577"
          x1="4.01758"
          y1="31.5007"
          x2="95.988"
          y2="31.5007"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#8352EB" />
          <Stop offset="1" stopColor="#C352EB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
