import { useBetConfirmMutation } from "@/api/apiSlice";
import ThemeText from "@/components/ThemeText";
import i18n, { t } from "@/lang/i18n";
import { IRootStackScreenProps } from "@/routes/AppRoute";
import {
  BET_CONFIRM_SUCCESS_SCREEN,
  BUY_FLOW_SCREEN,
  BUY_SAVE_SCREEN,
} from "@/routes/Routes";
import {
  clearBetData,
  clearSaveBetResult,
  setBetResultVisible,
} from "@/store/bet";
import { setLoading } from "@/store/loading";
import { RootState } from "@/store/store";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Modal, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

export default function BetSaveResultModel() {
  // useSkipGlobalError([UNPROCESSABLE_CONTENT]);
  const dispatch = useDispatch();
  const navigation =
    useNavigation<
      IRootStackScreenProps<typeof BUY_SAVE_SCREEN>["navigation"]
    >();
  const result = useSelector((state: RootState) => state.bet.betSaveResult);

  const [betConfirm, { isLoading, isSuccess, data: betConfirmResult }] =
    useBetConfirmMutation();

  // const { refetch } = useActiveGamesQuery()

  function onClose() {
    if (
      result?.type == "error" &&
      (!!result.errors?.game_id || result.confirm)
    ) {
      dispatch(clearBetData());
      // @ts-ignore
      navigation.navigate(BUY_FLOW_SCREEN);
    }
    dispatch(setBetResultVisible(false));
  }

  function onCancel() {
    dispatch(clearSaveBetResult());
  }

  function onConfirm() {
    if (result?.type == "success") {
      dispatch(setBetResultVisible(false));
      betConfirm({
        id: result.id,
      });
    }
  }

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess && betConfirmResult) {
      dispatch(clearBetData());
      navigation.replace(BET_CONFIRM_SUCCESS_SCREEN, {
        total_amount: betConfirmResult.data.total_bet_amount,
        total_ticket: betConfirmResult.data.bets.length,
      });
    }
  }, [isSuccess]);

  return (
    <Modal
      visible={result?.visible == true && result.type == "success"}
      transparent
      onRequestClose={onClose}
    >
      <View style={styles.backdrop}>
        <View style={styles.dialog}>
          <ThemeText style={styles.title}>
            {i18n.t("bet_confirm_title")}
          </ThemeText>
          <ThemeText style={styles.description}>
            {i18n.t("bet_confirm_message")}
          </ThemeText>
          <View style={styles.actionContainer}>
            <>
              <TouchableOpacity
                style={[styles.actionButton]}
                onPress={onCancel}
              >
                <ThemeText style={[styles.buttonText]}>
                  {i18n.t("cancel")}
                </ThemeText>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.actionButton]}
                onPress={onConfirm}
              >
                <ThemeText style={[styles.buttonText]}>
                  {i18n.t("confirm")}
                </ThemeText>
              </TouchableOpacity>
            </>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  backdrop: {
    flex: 1,
    backgroundColor: "rgba(14, 8, 21, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    backgroundColor: "black",
    borderRadius: 16,
    width: "70%",
    minWidth: 300,
    marginHorizontal: 16,
    alignItems: "center",
    // shadowColor: "rgba(98, 95, 95, 0.5)",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    // elevation: 5,
  },
  title: {
    paddingHorizontal: 16,
    paddingTop: 16,
    fontSize: 18,
    // fontWeight: "bold",
    color: "white",
  },
  description: {
    marginTop: 6,
    paddingHorizontal: 16,
    fontSize: 14,
    color: "white",
    textAlign: "center",
    paddingBottom: 16,
  },
  actionContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  actionButton: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 12,
    marginHorizontal: 6,
  },
  buttonText: {
    color: "#0A84FF",
    textAlign: "center",
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cancelButton: {
    // backgroundColor: 'gray',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
  confirmButton: {
    // backgroundColor: 'purple',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
});
