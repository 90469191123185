export const Nunito_200ExtraLight = "Nunito_200ExtraLight";
export const Nunito_300Light = "Nunito_300Light";
export const Nunito_400Regular = "Nunito_400Regular";
export const Nunito_500Medium = "Nunito_500Medium";
export const Nunito_600SemiBold = "Nunito_600SemiBold";
export const Nunito_700Bold = "Nunito_700Bold";
export const Nunito_800ExtraBold = "Nunito_800ExtraBold";
export const Nunito_900Black = "Nunito_900Black";

// const fonts = {
//   [Nunito_200ExtraLight]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM9jo7eTWk.ttf",
//   [Nunito_300Light]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhRTM9jo7eTWk.ttf",
//   [Nunito_400Regular]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM9jo7eTWk.ttf",
//   [Nunito_500Medium]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDIkhRTM9jo7eTWk.ttf",
//   [Nunito_600SemiBold]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM9jo7eTWk.ttf",
//   [Nunito_700Bold]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM9jo7eTWk.ttf",
//   [Nunito_800ExtraBold]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM9jo7eTWk.ttf",
//   [Nunito_900Black]:
//     "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDBImRTM9jo7eTWk.ttf",
// } as const;

const fonts = {
  [Nunito_200ExtraLight]: require("@assets/fonts/Nunito_200ExtraLight.ttf"),
  [Nunito_300Light]: require("@assets/fonts/Nunito_300Light.ttf"),
  [Nunito_400Regular]: require("@assets/fonts/Nunito_400Regular.ttf"),
  [Nunito_500Medium]: require("@assets/fonts/Nunito_500Medium.ttf"),
  [Nunito_600SemiBold]: require("@assets/fonts/Nunito_600SemiBold.ttf"),
  [Nunito_700Bold]: require("@assets/fonts/Nunito_700Bold.ttf"),
  [Nunito_800ExtraBold]: require("@assets/fonts/Nunito_800ExtraBold.ttf"),
  [Nunito_900Black]: require("@assets/fonts/Nunito_900Black.ttf"),
} as const;
export default fonts;
