import { accentColor } from "@/constants/ColorConstants";
import { RootState } from "@/store/store";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

export default function CurrentBetNumber() {
  const currentBetNumber = useSelector(
    (state: RootState) => state.bet.currentInputNumber
  );
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {[...Array(4)].map((_, index) => (
        <View key={index} style={styles.numberContainer}>
          <Text style={styles.itemText}>
            {currentBetNumber.charAt(index) || ""}
          </Text>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  numberContainer: {
    width: 48,
    height: 64,
    backgroundColor: accentColor,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 6,
  },
  itemText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
});
