/* eslint no-use-before-define: 0 */

import { accentColor, tabIconSelectColor } from "@/constants/ColorConstants";
import { Ionicons } from "@expo/vector-icons";
import {
  BottomTabScreenProps,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import {
  CompositeScreenProps,
  DefaultTheme,
  NavigationContainer,
  useNavigation,
} from "@react-navigation/native";
import {
  NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import React from "react";
import { StyleSheet } from "react-native";

import { iconColor, primaryBlackColor } from "@/constants/ColorConstants";
import CashScreen from "@/screens/home/cash/CashScreen";
import DashboardScreen from "@/screens/home/dashboard/DashboardScreen";
import NotificationScreen from "@/screens/home/noti/NotificationScreen";
import ResultScreen from "@/screens/home/results/ResultsScreen";
import LoginScreen from "@/screens/login/LoginScreen";
import { useDispatch, useSelector } from "react-redux";

import { tabIconUnselectColor } from "@/constants/ColorConstants";
import {
  ABOUT_US_SCREEN,
  AVATAR_SCREEN,
  BALANCE_HISTORY,
  BET_CONFIRM_SUCCESS_SCREEN,
  BUY_FLOW_SCREEN,
  BUY_ORDER_HISTORY,
  BUY_SAVE_SCREEN,
  BUY_SCREEN,
  CASH_SCREEN,
  CHANGE_PASSWORD_SCREEN,
  CONFIRM_PROCESSING_SCREEN,
  CONTACT_US_SCREEN,
  DASHBOARD_SCREEN,
  FAKE_NOTIFICATION_SCREEN,
  FAKE_SCREEN,
  LOGIN_SCREEN,
  MAINTENANCE_SCREEN,
  MAIN_SCREEN,
  NOTIFICATION_SCREEN,
  NO_INTERNET_CONNECTION,
  PROFILE,
  REGISTER_SCREEN,
  RESULT_ROUTE,
  TERMS_AND_CONDITIONS,
  TEST_DETAIL_ROUTE,
  TOP_UP_SCREEN,
  WINNER_SCREEN,
  WITHDRAW_SCREEN,
} from "./Routes";

import BuyConfirmProcessingScreen from "@/screens/home/buy/BuyConfirmProcessingScreen";
import BuyConfirmSuccessScreen from "@/screens/home/buy/BuyConfirmSuccessScreen";
import BuyFlowScreen from "@/screens/home/buy/BuyFlowScreen";
import ProfileScreen from "@/screens/profile/ProfileScreen";

import AppBottomTabBar from "@/components/AppBottomTabBar";
import AppHeaderProfileImage from "@/components/AppHeaderProfileImage";
import CustomHeaderImage from "@/components/svgs/nav/CustomHeaderImage";
import CashInActive from "@/components/svgs/navigation/CashInActive";
import CashSvg from "@/components/svgs/navigation/CashSvg";
import HomeInActiveSvg from "@/components/svgs/navigation/HomeInActiveSvg";
import HomeSvg from "@/components/svgs/navigation/HomeSvg";
import NotiInactiveSvg from "@/components/svgs/navigation/NotiInactiveSvg";
import NotiSvg from "@/components/svgs/navigation/NotiSvg";
import ResultInactiveSvg from "@/components/svgs/navigation/ResultInActiveSvg";
import ResultSvg from "@/components/svgs/navigation/ResultSvg";
import useOnErrorDismiss from "@/hooks/useOnErrorDismiss";
import i18n, { t } from "@/lang/i18n";
import BuySaveScreen from "@/screens/home/buy/BuySaveScreen";
import MaintenanceScreen from "@/screens/maintenance/MaintenanceScreen";
import RegisterScreen from "@/screens/register/RegisterScreen";
import TopUpScreen from "@/screens/topup/TopUpScreen";
import WinnerScreen from "@/screens/winners/WinnerScreen";
import WithdrawScreen from "@/screens/withdraw/WithdrawScreen";
import { clearToken, selectIsFeature } from "@/store/auth/auth_reducer";
import { UNAUTHENTICATED_ERROR, selectIsMaintenance } from "@/store/error";
import FakeScreen from "@/screens/FakeScreen";
import pageTitle from "@/utils/pageTitle";
import { Nunito_400Regular } from "@/constants/fonts";

type TabParamList = {
  [DASHBOARD_SCREEN]: undefined;

  [CASH_SCREEN]: undefined;
  [NOTIFICATION_SCREEN]: undefined;
  [RESULT_ROUTE]: undefined;
  [BUY_SCREEN]: undefined;
};

type RootStackParamList = {
  [FAKE_SCREEN]: undefined;
  [FAKE_NOTIFICATION_SCREEN]: undefined;
  [RESULT_ROUTE]: undefined;
  [MAIN_SCREEN]: TabParamList;
  [TEST_DETAIL_ROUTE]: undefined;
  [LOGIN_SCREEN]: undefined;
  [BUY_FLOW_SCREEN]: undefined;
  [BUY_SAVE_SCREEN]: undefined;
  [CONFIRM_PROCESSING_SCREEN]: undefined;
  [BET_CONFIRM_SUCCESS_SCREEN]: {
    total_ticket: number;
    total_amount: number;
  };
  [BUY_ORDER_HISTORY]: undefined;
  [PROFILE]: undefined;
  [BALANCE_HISTORY]: undefined;
  [MAINTENANCE_SCREEN]: undefined;
  [NO_INTERNET_CONNECTION]: undefined;
  [REGISTER_SCREEN]: undefined;
  [TERMS_AND_CONDITIONS]: undefined;
  [ABOUT_US_SCREEN]: undefined;
  [CONTACT_US_SCREEN]: undefined;
  [TOP_UP_SCREEN]: undefined;
  [WITHDRAW_SCREEN]: undefined;
  [AVATAR_SCREEN]: undefined;
  [WINNER_SCREEN]: undefined;
  [CHANGE_PASSWORD_SCREEN]: undefined;
};

export type IRootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>;

export type ITabScreenProps<T extends keyof TabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<TabParamList, T>,
    IRootStackScreenProps<keyof RootStackParamList>
  >;

const Tab = createBottomTabNavigator<TabParamList>();
const Stack = createNativeStackNavigator<RootStackParamList>();

// const CustomTabBarButton = ({ onPress }: { onPress: () => void }) => (
//     <TouchableOpacity
//       style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
//       onPress={onPress}
//     >
//       {/* Render your custom button content here */}
//       <View style={{ width: 30, height: 30, backgroundColor: 'red' }} />
//     </TouchableOpacity>
//   );

// const handleBuyTabPress = (navigation: any) => {
//     console.log("BUYCLICK");
//     // navigation.navigate(TEST_DETAIL_ROUTE, { akak: 'akak' });
//   };

function MainStack() {
  const navigation = useNavigation();

  return (
    <Tab.Navigator
      tabBar={AppBottomTabBar}
      screenOptions={({ route, navigation }) => ({
        headerShown: true,
        headerShadowVisible: false,
        headerTitleAlign: "left",
        headerTintColor: "white",
        headerTitleStyle: {
          fontFamily: Nunito_400Regular,
        },
        tabBarLabelStyle: {
          marginTop: 8,
        },
        tabBarInactiveTintColor: tabIconUnselectColor,
        tabBarActiveTintColor: tabIconSelectColor,
        tabBarShowLabel: false,
        headerStyle: {
          backgroundColor: primaryBlackColor,
        },
        headerRight: () => <AppHeaderProfileImage navigation={navigation} />,
        // tabBarStyle: {
        //   // position: "relative",
        //   backgroundColor: "#423A4D",
        //   height: TAB_BAR_HEIGHT,
        //   // paddingBottom: 25,
        //   // alignItems: "center",
        //   justifyContent: "center",
        // },
      })}
    >
      <Tab.Screen
        name={DASHBOARD_SCREEN}
        component={DashboardScreen}
        options={{
          headerTitleAlign: "center",
          headerTitle: () => <CustomHeaderImage />,
          title: pageTitle("Dashboard"),
          tabBarLabel: i18n.t("home_tab_label"),
          tabBarIcon: ({ focused, color }) =>
            !focused ? <HomeInActiveSvg /> : <HomeSvg />,
        }}
      />
      <Tab.Screen
        name={RESULT_ROUTE}
        component={ResultScreen}
        options={{
          headerTitle: "Results",
          title: pageTitle("Results"),
          tabBarLabel: i18n.t("results_tab_label"),
          tabBarIcon: ({ focused, color }) =>
            !focused ? <ResultInactiveSvg /> : <ResultSvg />,
        }}
      />
      <Tab.Screen
        name={NOTIFICATION_SCREEN}
        component={NotificationScreen}
        options={{
          title: pageTitle("Notification"),
          headerTitle: "Notification",
          tabBarLabel: i18n.t("notifications_tab_label"),
          tabBarIcon: ({ focused, color }) =>
            !focused ? <NotiInactiveSvg /> : <NotiSvg />,
        }}
      />
      <Tab.Screen
        name={CASH_SCREEN}
        component={CashScreen}
        options={{
          headerTitle: "Cash Activity",
          title: pageTitle("Cash Activity"),
          unmountOnBlur: true,
          tabBarLabel: i18n.t("cash_tab_label"),
          tabBarIcon: ({ focused, color }) =>
            !focused ? <CashInActive /> : <CashSvg />,
        }}
      />
    </Tab.Navigator>
  );
}

function AppRouter() {
  const isMaintenanceMode = useSelector(selectIsMaintenance);
  const authToken = useSelector((state: any) => state.auth.token);
  const isFeature = useSelector(selectIsFeature);

  const dispatch = useDispatch();

  useOnErrorDismiss((error) => {
    if (error && error.type === UNAUTHENTICATED_ERROR) {
      dispatch(clearToken());
    }
  });

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: primaryBlackColor,
        },
      }}
    >
      <Stack.Navigator
        screenOptions={({ navigation }) => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: primaryBlackColor,
          },
          headerTitleStyle: {
            color: "white",
            fontFamily: Nunito_400Regular,
          },
          headerShadowVisible: false,
          headerTitleAlign: "center",
          headerLeft: () =>
            navigation.canGoBack() ? (
              <Ionicons
                name="arrow-back"
                size={24}
                color="white"
                style={{ marginLeft: 10 }}
                onPress={() => navigation.goBack()}
              />
            ) : null,
        })}
      >
        {isMaintenanceMode ? (
          <Stack.Screen
            options={{
              headerShown: false,
              title: pageTitle("Maintenance"),
            }}
            name={MAINTENANCE_SCREEN}
            component={MaintenanceScreen}
          />
        ) : (
          <>
            {authToken == null ? (
              <Stack.Group>
                <Stack.Screen
                  name={LOGIN_SCREEN}
                  component={LoginScreen}
                  options={{ headerShown: false, title: pageTitle("Login") }}
                />
                <Stack.Screen
                  name={REGISTER_SCREEN}
                  component={RegisterScreen}
                  options={({ navigation }) => ({
                    headerShown: false,
                    title: pageTitle("Register"),
                  })}
                />
              </Stack.Group>
            ) : isFeature ? (
              <Stack.Group>
                <Stack.Screen
                  name={FAKE_SCREEN}
                  component={FakeScreen}
                  options={({ navigation }) => ({
                    title: pageTitle(""),
                    headerTitleAlign: "center",
                    headerTitle: () => <CustomHeaderImage />,
                    headerRight: () => (
                      <AppHeaderProfileImage navigation={navigation} />
                    ),
                  })}
                  // options={{ headerShown: false }}
                />
                <Stack.Screen
                  name={RESULT_ROUTE}
                  component={ResultScreen}
                  options={{
                    title: pageTitle("Results"),
                    headerTitle: "Results",
                  }}
                />
                <Stack.Screen
                  name={PROFILE}
                  component={ProfileScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Profile"),
                    headerTitle: "Profile",
                  })}
                />
                <Stack.Screen
                  name={AVATAR_SCREEN}
                  getComponent={() =>
                    require("@/screens/avatar/AvatarScreen").default
                  }
                  options={({ navigation }) => ({
                    title: pageTitle("Avatars"),
                    headerTitle: "Avatars",
                  })}
                />
              </Stack.Group>
            ) : (
              <Stack.Group>
                <Stack.Screen
                  name={MAIN_SCREEN}
                  component={MainStack}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name={BUY_FLOW_SCREEN}
                  component={BuyFlowScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Buy Ticket"),
                    headerTitle: "Buy Ticket",
                    // headerStyle: {
                    //   backgroundColor: primaryBlackColor,
                    // },
                  })}
                />
                <Stack.Screen
                  name={BUY_SAVE_SCREEN}
                  component={BuySaveScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Buy Ticket Complete"),
                    headerTitle: "Buy Ticket",
                  })}
                />
                <Stack.Screen
                  name={PROFILE}
                  component={ProfileScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Profile"),
                    headerTitle: "Profile",
                  })}
                />
                <Stack.Screen
                  name={CHANGE_PASSWORD_SCREEN}
                  getComponent={() =>
                    require("@/screens/ChangePasswordScreen").default
                  }
                  options={({ navigation }) => ({
                    title: pageTitle("Change Password"),
                    headerTitle: "Change Password",
                  })}
                />
                <Stack.Screen
                  name={CONFIRM_PROCESSING_SCREEN}
                  component={BuyConfirmProcessingScreen}
                  options={{
                    title: pageTitle("Buy Ticket Confirm"),
                  }}
                />
                <Stack.Screen
                  name={TOP_UP_SCREEN}
                  component={TopUpScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Top Up"),
                    headerTitle: i18n.t("top_up_title"),
                  })}
                />
                <Stack.Screen
                  name={WITHDRAW_SCREEN}
                  component={WithdrawScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Withdraw"),
                    headerTitle: i18n.t("withdraw_title"),
                  })}
                />
                <Stack.Screen
                  name={BET_CONFIRM_SUCCESS_SCREEN}
                  component={BuyConfirmSuccessScreen}
                  options={{ headerShown: false }}
                />
                <Stack.Screen
                  name={BUY_ORDER_HISTORY}
                  getComponent={() =>
                    require("@/screens/home/buy-history/BuyOrderHistory")
                      .default
                  }
                  options={({ navigation }) => ({
                    title: pageTitle("Buy History"),
                    headerTitle: "YOUR BET HISTORY",
                  })}
                />
                <Stack.Screen
                  name={BALANCE_HISTORY}
                  getComponent={() =>
                    require("@/screens/balance-history/BalanceHistory").default
                  }
                  options={({ navigation }) => ({
                    title: pageTitle("Balance History"),
                    headerTitle: "Balance History",
                  })}
                />
                <Stack.Screen
                  name={AVATAR_SCREEN}
                  getComponent={() =>
                    require("@/screens/avatar/AvatarScreen").default
                  }
                  options={({ navigation }) => ({
                    title: pageTitle("Avatars"),
                    headerTitle: "Avatars",
                  })}
                />
                <Stack.Screen
                  name={WINNER_SCREEN}
                  component={WinnerScreen}
                  options={({ navigation }) => ({
                    title: pageTitle("Winner"),
                    headerTitle: "Winner List",
                  })}
                />
              </Stack.Group>
            )}
            <Stack.Screen
              navigationKey={authToken ? "user" : "guest"}
              name={TERMS_AND_CONDITIONS}
              getComponent={() =>
                require("@/screens/terms-and-conditions/TermsAndConditions")
                  .default
              }
              options={({ navigation }) => ({
                title: pageTitle("Terms & Conditions"),
                headerTitle: i18n.t("terms_and_conditions_page_title"),
              })}
            />
            <Stack.Screen
              name={ABOUT_US_SCREEN}
              getComponent={() =>
                require("@/screens/about-us/AboutUsScreen").default
              }
              options={({ navigation }) => ({
                title: pageTitle("About Us"),
                headerTitle: "About Us",
              })}
            />
            <Stack.Screen
              name={CONTACT_US_SCREEN}
              getComponent={() =>
                require("@/screens/contact-us/ContactUsScreen").default
              }
              options={({ navigation }) => ({
                title: pageTitle("Contact Us"),
                headerTitle: "Contact Us",
              })}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  tabContainer: {
    flex: 1,
  },
  image: {
    height: "20%",
    width: "100%",
  },
  avatarImage: {
    width: 30,
    height: 30,
    borderRadius: 100,
  },
  headerContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    height: 75,
  },
  userIconContainer: {
    flex: 1,
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  userIconBackground: {
    backgroundColor: accentColor,
    borderRadius: 25,
    width: 36,
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  userIcon: {
    fontSize: 20,
    color: iconColor,
  },
  itemContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  containerBuy: {
    marginBottom: 48,
    width: 75, // Adjust the width and height to determine the size of the gradient circle
    height: 75,
    borderRadius: 100, // Make sure the borderRadius is half the width/height value for a perfect circle
    overflow: "hidden",
    shadowColor: "#000000", // Shadow color
    shadowOffset: { width: 0, height: 2 }, // Shadow offset
    shadowOpacity: 0.5, // Shadow opacity
    shadowRadius: 4, // Shadow radius
    elevation: 5, // Elevation (for Android)
  },
  gradient: {
    flex: 1,
  },
  buyButton: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AppRouter;
