import { useBetSaveMutation } from "@/api/apiSlice";
import PrimaryButton from "@/components/PrimaryButton";
import i18n, { t } from "@/lang/i18n";
import BetSaveResultModel from "@/screens/home/buy/components/BetSaveResultModel";
import { selectSelectedGame } from "@/store/bet";
import { setLoading } from "@/store/loading";
import { RootState } from "@/store/store";
import React, { useEffect } from "react";
import type { TouchableOpacityProps } from "react-native";
import { useDispatch, useSelector } from "react-redux";

type BuyButtonProps = {
  style?: TouchableOpacityProps["style"];
};

export default function BuyButton({ style }: BuyButtonProps) {
  const dispatch = useDispatch();
  const selectedGame = useSelector(selectSelectedGame);
  const bets = useSelector((state: RootState) => state.bet.bets);
  const [betSave, { isLoading }] = useBetSaveMutation();

  function handleBuy() {
    if (!selectedGame) {
      return;
    }

    betSave({
      game_id: selectedGame.id,
      bets: bets.map((bet) => ({
        bet_number: bet.bet_number,
        bet_amount: bet.bet_amount,
        unique_key: bet.unique_key,
        input_number: bet.input_number,
      })),
    });
  }

  useEffect(() => {
    dispatch(setLoading(isLoading));

    return () => {
      dispatch(setLoading(false));
    };
  }, [isLoading]);

  return (
    <>
      <PrimaryButton
        buttonStyle={style}
        disabled={bets.length === 0 || !selectedGame}
        title={i18n.t("buy")}
        onPress={handleBuy}
      />
      <BetSaveResultModel />
    </>
  );
}
