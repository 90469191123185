import apiSlice from "@/api/apiSlice";
import { RootState } from "@/store/store";
import delay from "@/utils/delay";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Linking } from "react-native";

export const openViber = createAsyncThunk(
  "viber/openChat",
  async (_: void, { rejectWithValue, dispatch }) => {
    const response = await dispatch(apiSlice.endpoints.getViberData.initiate());
    if (response.status !== "fulfilled" || !response.data) {
      return rejectWithValue(response.error);
    }
    const url = `viber://chat?number=${encodeURIComponent(
      response.data.data.phone_number
    )}`;
    try {
      await Linking.openURL(url);
      await delay(1500);
      return;
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

type RequestState = "pending" | "fulfilled" | "rejected";

type ViberState = {
  phone_number: string | null;
  status: RequestState | null;
};

const initialState: ViberState = {
  phone_number: null,
  status: null,
};

export const viberSlice = createSlice({
  name: "viber",
  initialState,
  reducers: {
    dismissViberError: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openViber.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(openViber.fulfilled, (state, action) => {
      state.status = "fulfilled";
    });
    builder.addCase(openViber.rejected, (state, action) => {
      state.status = "rejected";
    });
    builder.addMatcher(
      apiSlice.endpoints.getViberData.matchFulfilled,
      (state, action) => {
        state.phone_number = action.payload.data.phone_number;
      }
    );
  },
});

export const selectViberStatus = (state: RootState) => state.viber.status;
export const selectViberPhone = (state: RootState) => state.viber.phone_number;

export const { dismissViberError } = viberSlice.actions;

export default viberSlice;
