import StatusTag from "@/components/StatusTag";
import ThemeText from "@/components/ThemeText";
import TransactionSvg from "@/components/svgs/cash/TransactionSvg";
import { TransactionStatus } from "@/model/response/transaction_model";
import React, { memo } from "react";
import { StyleSheet, Text, View } from "react-native";

interface TransactionItemProps {
  name: string;
  amount: string;
  color: string;
  date: string;
  status: TransactionStatus;
  type: string;
}

const TransactionItem = memo(
  ({ name, amount, color, date, status, type }: TransactionItemProps) => {
    return (
      <View style={styles.container}>
        <View style={styles.transactionItemsContainer}>
          <View style={styles.transactionItem}>
            <TransactionSvg />
            <View style={styles.itemTextContainer}>
              <View style={styles.transactionNameContainer}>
                <ThemeText style={styles.itemHeaderText}>{name}</ThemeText>
                <StatusTag status={status} />
              </View>
              <ThemeText style={styles.itemSubHeaderText}>{date}</ThemeText>
            </View>
          </View>
          <View style={styles.transactionValueContainer}>
            <ThemeText
              style={[
                styles.transactionValueText,
                { color: type == "deposit" ? "#24E365" : "#F20057" },
              ]}
            >
              {amount}
            </ThemeText>

            {/* <Text
              style={[
                styles.itemSubHeaderText,
                { textAlign: "right" },
                status === "pending" && styles.pending,
                status === "success" && styles.success,
                status === "rejected" && styles.rejected,
              ]}
            >
              {status}
            </Text> */}
          </View>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    width: "100%",

    paddingVertical: 10,
  },
  transactionItemsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  transactionItem: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  itemTextContainer: {
    marginLeft: 10,
    flexShrink: 1,
  },
  itemHeaderText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
    flexWrap: "wrap",
  },
  itemSubHeaderText: {
    fontSize: 12,
    color: "gray",
  },
  transactionValueContainer: {
    marginLeft: 10,
    alignItems: "flex-end",
    gap: 4,
  },
  transactionValueText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  transactionNameContainer: {
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    flexWrap: "wrap",
  },
});

export default TransactionItem;
