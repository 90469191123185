import React from "react";
import { View, Text, TouchableOpacity, Modal, StyleSheet } from "react-native";
import { err } from "react-native-svg/lib/typescript/xml";
import i18n, { t } from "@/lang/i18n";
import { purpleText } from "@/constants/ColorConstants";

interface LanguageSelectionProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectLanguage: (language: string) => void;
}

const LanguageSelectionPopup: React.FC<LanguageSelectionProps> = ({
  isVisible,
  onClose,
  onSelectLanguage,
}) => {
  return (
    <Modal visible={isVisible} transparent>
      <View style={styles.modalContainer}>
        <View style={styles.popupContainer}>
          <View>
            <TouchableOpacity onPress={() => onSelectLanguage("en")}>
              <Text style={styles.description}>{i18n.t("english")}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onSelectLanguage("my")}>
              <Text style={styles.description}>{i18n.t("burmese")}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.cancelButton} onPress={onClose}>
              <Text style={[styles.buttonText, { color: purpleText }]}>
                {i18n.t("cancel")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(58, 57, 57, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  popupContainer: {
    backgroundColor: "black",
    borderRadius: 16,
    width: "60%",
    padding: 26,
    marginHorizontal: 20,
    alignItems: "center",
    shadowColor: "rgba(98, 95, 95, 0.5)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
    marginBottom: 10,
  },
  description: {
    fontSize: 16,
    color: "white",
    textAlign: "center",
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  cancelButton: {
    // backgroundColor: 'gray',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
  confirmButton: {
    // backgroundColor: 'purple',
    borderRadius: 8,
    padding: 10,
    marginHorizontal: 5,
  },
  buttonText: {
    color: "violet",
    fontWeight: "bold",
  },
});

export default LanguageSelectionPopup;
