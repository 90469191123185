import { primaryBlackColor } from "@/constants/ColorConstants";
import * as React from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { Polygon, Svg } from "react-native-svg";

const TRIANGLE_HEIGHT = 40;

type CoverProps = {} & React.PropsWithChildren;

export default function Cover({ children }: CoverProps) {
  const { width } = useWindowDimensions();
  return (
    <LinearGradient
      colors={["#A852EB", "#5261EB"]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      useAngle={true}
      locations={[-0.7034, 1.9812]}
      angle={69.21}
      style={styles.container}
    >
      {children}
      {/* <View
        style={{
          paddingTop: 32,
          paddingBottom: 48,
        }}
      >
        <LogoSvg width={158.73015873015873} height={100} />
      </View> */}
      <Svg width={width} height={TRIANGLE_HEIGHT} style={styles.svg}>
        <Polygon
          points={`0,${TRIANGLE_HEIGHT} ${width},${TRIANGLE_HEIGHT} ${width},0`}
          fill={primaryBlackColor}
          strokeWidth={0}
        />
      </Svg>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: TRIANGLE_HEIGHT,
    paddingBottom: TRIANGLE_HEIGHT,
  },
  svg: { position: "absolute", bottom: 0, left: 0 },
});
