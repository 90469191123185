export const tagsStyles = {
  html: {
    color: "white",
  },
  body: {
    color: "white",
  },
  h1: {
    color: "white",
  },
  h2: {
    color: "white",
  },
  h3: {
    color: "white",
    marginBottom: 8,
  },
  p: {
    color: "white", // Change this to the color you desire
    marginTop: 4,
    marginBottom: 4,
  },
  span: {
    color: "white",
  },
};
