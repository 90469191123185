import { BaseApiErrorResponse } from "@/model/response/base";
import {
  AnyAction,
  AsyncThunk,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected");
}

export type ErrorState = {
  error: {
    type: string;
    message: string;
  } | null;
  disabled: boolean;
  skip: string[];
};

export const NO_INTERNET_ERROR = "NO_INTERNET";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const UNAUTHENTICATED_ERROR = "UNAUTHENTICATED";
export const UNPROCESSABLE_CONTENT_ERROR = "UNPROCESSABLE_CONTENT";
export const MAINTENANCE_ERROR = "MAINTENANCE";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";

const initialState: ErrorState = {
  error: null,
  disabled: false,
  skip: [],
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorState["error"]>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
    setSkip: (state, action: PayloadAction<ErrorState["skip"]>) => {
      state.skip = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isRejectedAction, (state, action) => {
      if (!action.payload || action.type == "viber/openChat/rejected") {
        return;
      }
      console.log(action);

      let type = UNKNOWN_ERROR;
      let message = "Something went wrong. Please try again later.";

      if (
        action.payload instanceof Error &&
        action.payload.message === NO_INTERNET_ERROR
      ) {
        type = NO_INTERNET_ERROR;
        message = "No internet connection. Please try again later.";
      }

      if (
        typeof action.payload == "object" &&
        action.payload != null &&
        "status" in action.payload
      ) {
        if (action.payload.status == "FETCH_ERROR") {
          type = NETWORK_ERROR;
          message = "Network error. Please try again later.";
        }

        if (action.payload.status == 401) {
          type = UNAUTHENTICATED_ERROR;
          message = "Your login session is expired.";
        }

        if (action.payload.status == 422 && "data" in action.payload) {
          type = UNPROCESSABLE_CONTENT_ERROR;
          message = (action.payload.data as BaseApiErrorResponse).message;
        }

        if (action.payload.status == 500) {
          type = SERVER_ERROR;
          message = "Server error. Please try again later.";
        }

        if (action.payload.status == 503) {
          type = MAINTENANCE_ERROR;
          message = "Server is under maintenance. Please try again later.";
        }
      }

      if (state.disabled && type != MAINTENANCE_ERROR) {
        return;
      }

      if (type != MAINTENANCE_ERROR && state.skip.includes(type)) {
        return;
      }

      state.error = {
        type: type,
        message: message,
      };
    });
  },
});

export const selectIsMaintenance = (state: { error: ErrorState }) =>
  state.error.error?.type === MAINTENANCE_ERROR;

export const { setError, clearError, setDisabled, setSkip } =
  errorSlice.actions;

export default errorSlice;
