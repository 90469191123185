import React from "react";
import { StyleSheet, Text, View } from "react-native";
import RectangleNumber from "../../../../components/RectangleNumber";
import { accentColor, purpleText } from "../../../../constants/ColorConstants";
import { BetStatus } from "@/model/response/order_model";

interface OrderItemProps {
  rnPaddingHorizontal: number;
  rnPaddingVertical: number;
  text: string;
  amount: string;
  numbers: number[];
  status: BetStatus;
}

const OrderItem: React.FC<OrderItemProps> = ({
  rnPaddingHorizontal,
  rnPaddingVertical,
  text,
  amount,
  numbers,
  status,
}) => {
  return (
    <View style={styles.rectContainer}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          {text} <Text style={styles.textAmount}>{amount} Ks</Text>
        </Text>
      </View>
      <View style={styles.numberContainer}>
        <View style={styles.row}>
          {numbers.map((singleNumber, index) => {
            return (
              <RectangleNumber
                success={status == "win"}
                key={index}
                paddingVertical={rnPaddingVertical}
                paddingHorizontal={rnPaddingVertical}
                number={singleNumber}
              />
            );
          })}
        </View>
        {status == "lose" && (
          <View
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
              justifyContent: "center",
            }}
          >
            <View
              style={{ height: 8, backgroundColor: "rgba(150, 51, 51, 0.8)" }}
            ></View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rectContainer: {
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: accentColor,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 9,
  },
  textContainer: {
    flex: 1,
    marginLeft: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: "700",
    color: "white",
  },
  textAmount: {
    fontSize: 14,
    fontWeight: "700",
    color: purpleText,
  },
  numberContainer: {
    // flex: 1,
    marginRight: 4,
    paddingHorizontal: 2,
    position: "relative",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default OrderItem;
