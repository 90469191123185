import React, { memo } from "react";
import { Image, StyleSheet } from "react-native";

const UserAvatar = memo(
  ({ profile_image }: { profile_image: string | undefined | null }) => {
    if (!profile_image) {
      return (
        <Image
          source={require("@assets/third_winner_avatar.png")}
          style={styles.image}
        />
      );
    }

    return <Image source={{ uri: profile_image }} style={styles.image} />;
  }
);

const styles = StyleSheet.create({
  image: {
    width: 55,
    aspectRatio: 1,
    borderRadius: 100,
  },
});

export default UserAvatar;
