import { accentColor } from "@/constants/ColorConstants";
import { Nunito_600SemiBold } from "@/constants/fonts";
import React, { forwardRef } from "react";
import { StyleSheet, TextInput, TextInputProps, View } from "react-native";
import ThemeText from "./ThemeText";

export type PhoneInputProps = {} & TextInputProps;

const PhoneInput = forwardRef<TextInput, PhoneInputProps>(
  ({ style, ...props }, ref) => {
    return (
      <View>
        <TextInput
          style={[styles.input, style]}
          ref={ref}
          {...props}
          placeholderTextColor="rgba(255, 255, 255, 0.5)"
        />
        <View
          style={{
            position: "absolute",
            left: 16,
            bottom: 0,
            top: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ThemeText>09</ThemeText>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  input: {
    borderRadius: 8,
    backgroundColor: accentColor,
    paddingRight: 16,
    paddingLeft: 45,
    paddingVertical: 15,
    fontFamily: Nunito_600SemiBold,
    fontWeight: "600",
    color: "white",
    fontSize: 16,
  },
});

export default PhoneInput;
