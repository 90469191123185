import AlertDialog from "@/components/AlertDialog";
import i18n, { t } from "@/lang/i18n";
import { MAINTENANCE_ERROR, setError } from "@/store/error";
import { AppDispatch, RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function GlobalErrorDialog() {
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: RootState) => state.error.error);

  function reset() {
    dispatch(setError(null));
  }

  return (
    <AlertDialog
      title={i18n.t("error")}
      description={error?.message || ""}
      visible={!!error && error.type != MAINTENANCE_ERROR}
      onDismiss={() => reset()}
      actions={[
        {
          title: "Close",
          onPress: () => reset(),
        },
      ]}
    />
  );
}
