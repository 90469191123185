import ThemeText from "@/components/ThemeText";
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

interface ProfileMenuItemProps {
  icon: React.ComponentType;
  text: string;
  onPress?: () => void;
}

const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({
  icon: Icon,
  text,
  onPress,
}) => {
  const handlePress = () => {
    if (onPress) {
      onPress();
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={handlePress}>
      <View style={styles.iconContainer}>
        <Icon />
      </View>
      <ThemeText style={styles.text}>{text}</ThemeText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    fontSize: 14,
    color: "white",
  },
  iconContainer: {
    marginRight: 8,
  },
});

export default ProfileMenuItem;
