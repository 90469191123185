export const FAKE_SCREEN = "FAKE";
export const LOGIN_SCREEN = "login";
export const REGISTER_SCREEN = "register";
export const FORGOT_PASSWORD_SCREEN = "FORGOT_PASSWORD";
export const MAIN_SCREEN = "MAIN";
export const BUY_SCREEN = "buy";
export const CASH_SCREEN = "cash";
export const DASHBOARD_SCREEN = "home";
export const NOTIFICATION_SCREEN = "NOTIFICATION";
export const FAKE_NOTIFICATION_SCREEN = "FAKE_NOTIFICATION";
export const RESULT_ROUTE = "results";
export const TEST_DETAIL_ROUTE = "TESTDETAIL";
export const BUY_FLOW_SCREEN = "ticket/buy";
export const BUY_SAVE_SCREEN = "ticket/cart";
export const CONFIRM_PROCESSING_SCREEN = "CONFIRM_PROCESSING";
export const BET_CONFIRM_SUCCESS_SCREEN = "ticket/buy/success";
export const BUY_ORDER_HISTORY = "ticket/purchase-history";
export const PROFILE = "profile";
export const BALANCE_HISTORY = "balance/history";
export const NO_INTERNET_CONNECTION = "NO_INTERNET_CONNECTION";
export const MAINTENANCE_SCREEN = "MAINTENANCE_MODE";
export const TERMS_AND_CONDITIONS = "terms";
export const ABOUT_US_SCREEN = "about-us";
export const CONTACT_US_SCREEN = "contact-us";
export const TOP_UP_SCREEN = "balance/top-up";
export const WITHDRAW_SCREEN = "balance/withdraw";
export const AVATAR_SCREEN = "profile/avatar";
export const WINNER_SCREEN = "winner";
export const CHANGE_PASSWORD_SCREEN = "profile/change-password";
